import importerStore from '@/shared/importer/importer-store';
import { CompanyService } from '@/modules/company/company-service';
import companyImporterFields from '@/modules/company/company-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  CompanyService.import,
  companyImporterFields,
  i18n('entities.company.importer.fileName'),
  i18n('entities.company.importer.hint'),
);
