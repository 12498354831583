import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=04dde55b&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=js&"
export * from "./layout.vue?vue&type=script&lang=js&"
import style0 from "./layout.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./layout.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./layout.vue?vue&type=style&index=2&id=04dde55b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04dde55b",
  null
  
)

export default component.exports
import {QLayout,QHeader,QToolbar,QToolbarTitle,QDrawer,QAvatar,QImg,QSeparator,QPageContainer,QPage} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QHeader,QToolbar,QToolbarTitle,QDrawer,QAvatar,QImg,QSeparator,QPageContainer,QPage})
