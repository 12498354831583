const en = {
  common: {
    "at": " at ",
    or: 'or',
    cancel: 'Cancel',
    reset: 'Cancel',
    save: 'Save',
    saveChanges: 'Save Changes',
    search: 'Search',
    edit: 'EDit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure to delete?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    submit: 'Submit',
    doYouWantToDeleteThisPlace: 'Do you want to delete this place?',
    doYouWantToDeleteThisPlan: 'Do you want to delete this plan?',
    doYouWantToRemoveThisPlace: 'Do you want to remove this place?',
    pleaseSetTimeToThisPlace: 'Please set time by mintues to this place',
    yesDelete: 'Yes, Delete',
    noThanks: 'No, Thanks',
    searchByRegion: 'Search by region name',
    FilterByRegion: 'Filter by region name',
    searchByCity: 'Search by city name',
    FilterByCity: 'Filter by city name',
    noResults: 'No results',
    next: 'Next',
    back: 'Back',
    daysNumber: 'Days number',
    viewMore: 'View more',
    delete: 'Delete',
    setTime: 'Set Time',
    selectImage: 'Select Image',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    createdAtRange: 'Created At',
    all: 'All',
    noImgInPlace: 'There are no photos for this place',
    accept: 'Accept',
    ignore: 'Ignore',
    reject: 'Reject',
    available: 'Available',
    unavailable: 'Unavailable',
    action: 'Action',
    actions: 'Actions',
    arabic: 'Arabic',
    english: 'English',
    "title": "Title",
    "description":"Description",
    "uploadPhoto":"Upload photo",
    "uploadPicture":"Upload Picture",
    "uploadNewPicture":"Upload new picture",

    "createNewAccount":"Create New Account",
    "publish":"Publish",
    "name":"Name",
    "email":"Email",
    "phone":"Phone",
    "message":"Message",
    "address":"Address",
    "emailAddress":"Email Address",
    "phoneNumber":"Phone number",
    "spaceArea":"Space Area",
    "firstName":"First Name",
    "lastName":"Last Name",
    "fullName":"Full Name",
    "joined":"Joined",
    "monthAgo":"month ago",
    "monthsAgo":"months ago",
    "joinedThisMonth":"Joined this month",
    "status":"Status",
    "postStatus":"Post Status",
    "views":"Views",
    "searchInDrugs":"Search in drugs",
    "uploadDrugFile":"Upload new file",
    "id":"ID",
    "viewDetails":"View Details",
    "post":"Post",
    "profile":"Profile",
    "logout":"Logout",
    "deleteThisUser":"Delete this user?",
    "disabled":"Disabled",
    "enabled":"Enabled",
    "deleteThisAdmin":"Delete this admin",
    "role":"Role",
    "profilePicture":"Profile picture",
    "time":"Time",
    "signature":"signature",
    "undo":"Undo",
    "enterThePinCode":"Please enter the pin code",
    "pinCodePlaceHolder":"Type the pin code here",
    "close":"Close",
    "attachFile":"Attach File",
    "approve":"Approve",
    "apply":"Apply",
    "continue":"Continue",
    "previous":"Previous",
    "comingSoon":"COMING SOON!",
    "underConstruction":"This website still under construction",
    "today":"Today",
    "earlier":"Earlier",
    "day":"Day",
    "date":"Date",
    "print":"Print",
    "milady":"Milady",
    "hijri":"Hijri",
    "add":"Add",
    "send":"Send",
    "ex":"Ex.",
    "rePublish":"Re-Publish",
    "mins":"mins",
    "loading":" Loading...",
    "searching":" Searching...",
    "to":"to",
    "congratulations":"Congratulations!",
    "attachedFile":"Attached File",
    "addQualification":"Add Qualification",
    "addCourse":"Add Course",
    "other":"Other",
    "chooseDate":"Choose a Date",
    "employee":" Employee ",
    "employees":" Employees ",
    "addNote":"Add Note",
    "successfully":"Successfully",
    "updatedMaximumNumber":"Updated maximum number",
    "noteAdded":"Your Note has been added",
    "submitForm":"Submit Form",
    "submitRequest":"Submit Request",
    "history":"History",
    "create":"Create",
    "update":"Update",
    "from":"From",
    "subject":"Subject",
    "reply":"Reply",
    "ticketComplete":"Ticket complete",
    "showDetails":"Show details",
    "stop":"Stop",
    "readMore":"Read More",
    "readLess":"Read Less",
    "forward":"Forward",
    "done":"Done",
    "features":"Features",
    "deleteThisItem":"Do you want to delete this item?",
    "page":"Page",
    "preview":"Preview",
    "total":"Total",
    "exportationItems":"Exportation Items",
    "items":"Items",
    "createMainData":"Create main data",
    "addDetails":"Add details",
    "addParts":"Add product parts",
    "partsSelected":"Parts that have been selected",
    "chooseYourParts":"Choose your parts",
    "year":"Year",
    "qrCode":"QR Code",
    "url":"Url",
    "finish":"Finish",
    "error":"Error",
    "tryAgain":"Try again",
    "nextItem":"Next Item",
    "startExport":"Start Export",
    "scan":"Scan",
    "scanItems":"Scan Items",
    "waitingSellerCheck":"Waiting for seller check",
    "distributorName":" Distributor Name ",
    "bills":"Bills",
    "barcode":"Barcode",
    "printBarcode":"Print Barcode",
    "chooseOperation":"Choose The Operation",
    "generatePin":"Generate Pin Code",
    "addDistributor":"Add Distributor",
    "updateBrowser":"Please update your browser to the latest version.",
    "notifyUpdateBrowser":"Your web browser ({0}) is out of date. Update your browser for more security, speed and the best experience on this site.",
    "generate":"Generate"
  },
  empty: {
    title: 'There is no data to display',
    damin: 'Admins is empty ',
    client: 'Clients is empty ',
    company: 'Companies is empty ',
    guide: 'Tour guides is empty ',
    transaction: 'Transactions is empty',
    placeType: 'Places types is empty',
    city: 'Cities is empty',
    region: 'Regions is empty',
    wallet: 'Wallet is empty',
    cycle: 'Cycle is empty',
    place: 'No Registered Places',
    trips: 'No Trips',
    plans: 'No Plans',
    "offer": "No offers are loaded",
    "store": "No Stores",
  },
  app: {
    title: 'Rahal',
    ConfirmEmailAddress: 'Confirm Email Address'
  },

  tables: {
    admins: {
      firstName: 'first Name',
      lastName: 'last Name',
      phoneNumber: 'phone Number',
      email: 'email',
      role: 'role',
      createdAt: 'createdAt',
      status: 'status',
      action: 'Action',
      placeName: 'place Name',
      searchPlace: 'Search by place name',
      search: 'search',
      submit: 'submit'
    },
  },

  metaTitle: {
    mainTitle: 'Rahal Admin',
    signIn: 'Rahal  | SignIn',
    signUp: 'Rahal  | SignUp',
    forgetPass: 'Rahal  | ForgetPassword',
    emailUnverified: ' Rahal  | emailUnverified',
    emptyPermissions: 'Rahal  | emptyPermissions',
    authDahsboard: ' Rahal | Dashboard ',
    authEdit: ' Rahal | EditProfile',
    authSetting: ' Rahal | Setting  ',
    admins: '  Rahal | Database  | Admins',
    newAdmins: ' Rahal |  Database | Admins | newAdmin',
    editAdmin: 'Rahal | Database | Admins |  edit  ',
    viewAdmin: ' Rahal | Database | Admins | view ',
    users: ' Rahal | Database | Users',
    viewUser: ' Rahal | Database | Users | view',
    place: ' Rahal | Place ',
    newPlace: ' Rahal | Place |  Add-new-place ',
    editPlace: 'Rahal | Place | Edit ',
    viewPlace: 'Rahal | Place | View  ',
    newNotification: ' Rahal | Place | Notification',
  },

  entities: {
    sideMenu: {
      dashboard: 'Dashboard',
      database: 'Database',
      users: 'Users',
      admins: 'Admins',
      guides: 'Tour guides',
      clients: 'Clients',
      companies: 'Companies',
      transactions: 'Transactions',
      wallet: 'Wallet',
      cycle: 'Cycle',
      trips: 'Trips',
      plans: 'Plans',
      addLocationZone: 'Add location zone',
      RegisterPlaces: 'Register places',
      Setting: 'Setting',
      changeLanguage: 'Change Language',
      notificationLanguage: 'Notification Language',
      selectLanguages: 'Select Languages',
      changeTheme: 'Change theme color'
    },
    dashboard: {
      projection: 'Projection',
      visitors: 'Visitors',
      usersTable: 'Users Table',
      adminsTable: 'Admins Table',
      addNewAdmin: 'Add New Admin',
      registerPlaces: 'Register places',
      chooseLanguage: 'Choose Language',
      profile: 'Profile',
      Fname: 'First name',
      Lname: 'Last name',
      phoneNumber: 'Phone number',
      email: 'Email Address'
    },
    splash: {
      welcome: 'Welcome to SARYAH ADMIN PANEL',
      getStarted: 'Let’s get started',
      LOGIN: 'LOGIN',
      welcBack: 'Welcome Back! ',
      loginAccount: 'Login your account',
      pleaseValid: 'Please enter your Email address and password to login',
      orLoginWith: 'Or login with',
      dontHaveAccount: 'Don’t have account?'
    },
    signup: {
      createNew: 'Create New Account',
      pleaseEnter: 'Please enter your Email address and password to Create new account',
      signUpUp: 'Sign up',
      signUp: 'Sign up',
      alreadyMember: 'Already a member ?',
      signIn: 'Login '
    },

    guide: {
      name: 'Tour guides',
      label: 'Tour guides',
      menu: 'Tour guides',
      exporterFileName: 'trip_export',
      list: {
        menu: 'Tour guides',
        title: 'Tour guides',
      },
      create: {
        success: 'Tour guide saved successfully',
      },
      update: {
        success: 'Tour guide updated successfully',
      },
      destroy: {
        success: 'Tour guide deleted successfully',
      },
      destroyAll: {
        success: 'Tour guide(s) deleted successfully',
      },
      edit: {
        title: 'Edit Tour guide',
      },
      new: {
        title: 'Add Tour guide',
      },
      view: {
        title: 'View Tour guide',
      },
      fields: {
        id: 'Id',
        avatar: 'Avatar',
        fullName: 'Name',
        phoneNumber: 'Phone Number',
        accountType: 'Account Type',
        rate: 'Rate',
        pricePerHour: 'Price Per Hour',
        isMale: 'Is Male',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        availableNow: 'Available Now',
        languages: 'Languages',
        availableDays: 'Available Days',
        reviews : 'Reviews',
        location : 'Location',
        latitude: 'latitude',
        longitude: 'longitude',
        currency: 'Currency',
        capacity: 'Capacity',
        companyId: 'Company Id',
        branchId: 'Branch Id',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'accountType': {
          'freelance': 'Freelance',
          'company': 'Company',
        },
      },
      placeholder: {},
      importer: {
        title: 'Import Tour guide',
        fileName: 'trip_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    client: {
      name: 'Clients',
      label: 'Clients',
      menu: 'Clients',
      exporterFileName: 'client_export',
      list: {
        menu: 'Clients',
        title: 'Clients',
      },
      create: {
        success: 'Client saved successfully',
      },
      update: {
        success: 'Client updated successfully',
      },
      destroy: {
        success: 'Client deleted successfully',
      },
      destroyAll: {
        success: 'Client(s) deleted successfully',
      },
      edit: {
        title: 'Edit Client',
      },
      new: {
        title: 'Add Client',
      },
      view: {
        title: 'View Client',
      },
      fields: {
        id: 'Id',
        fullName: 'Name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        avatar: 'Avatar',
        lang: 'Language',
        points: 'Points',
        rate: 'Rate',
        deviceTokens: 'Device Tokens',
        notifications: 'Notifications',
        plans: 'Saved Plans',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Clients',
        fileName: 'client_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    company: {
      name: 'Companies',
      label: 'Companies',
      menu: 'Companies',
      exporterFileName: 'company_export',
      list: {
        menu: 'Companies',
        title: 'Companies',
      },
      create: {
        success: 'Company saved successfully',
      },
      update: {
        success: 'Company updated successfully',
      },
      destroy: {
        success: 'Company deleted successfully',
      },
      destroyAll: {
        success: 'Companies deleted successfully',
      },
      edit: {
        title: 'Edit Company',
      },
      new: {
        title: 'Add Company',
      },
      view: {
        title: 'View Company',
      },
      fields: {
        id: 'Id',
        logo: 'Logo',
        name: 'Company Name',
        description: 'Description',
        commercialRegister: 'Commercial Register',
        license: 'License',
        email: 'Email',
        websiteLink: 'Website',
        facebookLink: 'Facebook',
        twitterLink: 'Twitter',
        instagramLink: 'Instagram',
        legalAuthority: 'Legal Authority',
        rate: 'Rate',
        languages: 'Languages',
        reviews: 'Review',
        phoneNumber: 'Phone Number',
        countryCode: 'Country Code',
        countryId: 'Country Id',
        cityId: 'City Id',
        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'legalAuthority' :{
          'owner': 'Owner',
          'authorized': 'Authorized',
        },
      },
      placeholder: {},
      importer: {
        title: 'Import Companies',
        fileName: 'company_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    wallet: {
      name: 'Wallet',
      label: 'Wallet',
      menu: 'Wallet',
      exporterFileName: 'wallet_export',
      list: {
        menu: 'Wallet',
        title: 'Wallet',
      },
      create: {
        success: 'Wallet saved successfully',
      },
      update: {
        success: 'Wallet updated successfully',
      },
      destroy: {
        success: 'Wallet deleted successfully',
      },
      destroyAll: {
        success: 'Wallet(s) deleted successfully',
      },
      edit: {
        title: 'Edit Wallet',
      },
      new: {
        title: 'Add Wallet',
      },
      view: {
        title: 'View Wallet',
      },
      fields: {
        id: 'Id',
        // name:"Name",
        // phoneNumber:"Phone Number",
        // email:"Email Address",
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Wallet',
        fileName: 'wallet_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    cycle: {
      name: 'Cycle',
      label: 'Cycle',
      menu: 'Cycle',
      exporterFileName: 'cycle_export',
      list: {
        menu: 'Cycle',
        title: 'Cycle',
      },
      create: {
        success: 'Cycle saved successfully',
      },
      update: {
        success: 'Cycle updated successfully',
      },
      destroy: {
        success: 'Cycle deleted successfully',
      },
      destroyAll: {
        success: 'Cycle(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cycle',
      },
      new: {
        title: 'Add Wallet',
      },
      view: {
        title: 'View Wallet',
      },
      fields: {
        id: 'Id',
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Wallet',
        fileName: 'cycle_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    transaction: {
      name: 'Transactions',
      label: 'Transactions',
      menu: 'Transactions',
      exporterFileName: 'transaction_export',
      list: {
        menu: 'Transactions',
        title: 'Transactions',
      },
      create: {
        success: 'Transaction saved successfully',
      },
      update: {
        success: 'Transaction updated successfully',
      },
      destroy: {
        success: 'Transaction deleted successfully',
      },
      destroyAll: {
        success: 'Transaction(s) deleted successfully',
      },
      edit: {
        title: 'Edit Transaction',
      },
      new: {
        title: 'Add Transaction',
      },
      view: {
        title: 'View Transaction',
      },
      fields: {
        id: 'Id',
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Transactions',
        fileName: 'transaction_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },



    place: {
      name: 'place',
      label: 'Places',
      menu: 'Places',
      // placeholder:"Enter Your location" ,
      exporterFileName: 'place_export',
      ADD: 'Add',
      Next: 'NEXT',
      list: {
        menu: 'Places',
        title: 'Places',
      },
      create: {
        success: 'Place saved successfully',
      },
      update: {
        success: 'Place saved successfully',
      },
      destroy: {
        success: 'Place deleted successfully',
      },
      destroyAll: {
        success: 'Place(s) deleted successfully',
      },
      edit: {
        title: 'Edit Place',
      },
      fields: {
        id: 'Id',
        'lat': 'Latitude',
        'lng': 'Longitude',
        'radiusRange': 'Radius',
        'radius': 'Radius',
        'color': 'Color',
        'strokeColor': 'Stroke Color',
        'strokeWidthRange': 'StrokeWidth',
        'strokeWidth': 'Stroke width',
        'fillColor': 'Fill Color',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        'placeName': 'Place Name',
        'region': 'Region',
        'city': 'City',
        'country': 'country',
        description: 'Description',
        placePictures: 'Place Pictures',
        placeType: 'Place Type',
        notifications: 'Notifications',
      },
      enumerators: {
        placeType: {
          mountains: 'Geographical Locations',
          mosques: 'Mosques',
          market: 'Market',
          playground: 'Playground',
          museums: 'Museums',
          events: 'Events',
          cafe: 'Cafe',
        },
      },
      placeholder: {
        map: "Enter Your location",
        "placeDescription": "Type place description here",
        "placeDescriptionEnglish": "Type place description in english here",
        "placeDescriptionArabic": "Type place description in arabic here",
        "placeNameEnglish": "Place Name in English",
        "placeNameArabic": "Place Name in Arabic",
      },
      new: {
        title: 'Add Location zone',
      },
      view: {
        title: 'View Place',
      },
      importer: {
        title: 'Import Places',
        fileName: 'place_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    placeType: {
      name: 'Places Type',
      label: 'Places Type',
      menu: 'Places Type',
      exporterFileName: 'placeType_export',
      list: {
        menu: 'Places Type',
        title: 'Places Type',
      },
      create: {
        success: 'Place Type saved successfully',
        success2: 'Successfully created new place type',
      },
      update: {
        success: 'Place Type updated successfully',
      },
      destroy: {
        success: 'Place Type deleted successfully',
        message: 'Do you want to delete this place type?',
      },
      destroyAll: {
        success: 'Place Type(s) deleted successfully',
      },
      edit: {
        title: 'Edit Place Type',
      },
      new: {
        title: 'Add New Place Type',
      },
      view: {
        title: 'View Place Type',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        icon: 'Icon',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        placesType: {
          mosque: 'Mosque',
          museum: 'Museum',
          playground: 'Playground',
          events: 'Events',
          mountains: 'Geographical Location',
          market: 'Market',
          cafe: 'Cafe',
        },
      },
      placeholder: {
        chooseIcon: 'Choose Icon',
      },
      importer: {
        title: 'Import Places Type',
        fileName: 'PlaceType_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    city: {
      name: 'Cities',
      label: 'Cities',
      menu: 'Cities',
      exporterFileName: 'city_export',
      list: {
        menu: 'Cities',
        title: 'Cities',
      },
      create: {
        success: 'City saved successfully',
        success2: 'Successfully created new city',
      },
      update: {
        success: 'City updated successfully',
      },
      destroy: {
        success: 'City deleted successfully',
        message: 'Do you want to delete this city?',
      },
      destroyAll: {
        success: 'Cities deleted successfully',
      },
      edit: {
        title: 'Edit City',
      },
      new: {
        title: 'Add New City',
      },
      view: {
        title: 'View City',
      },
      fields: {
        id: 'Id',
        name: 'City Name',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        placesType: {
          mosque: 'Mosque',
          museum: 'Museum',
          playground: 'Playground',
          events: 'Events',
          mountains: 'Geographical Location',
          market: 'Market',
          cafe: 'Cafe',
        },
      },
      placeholder: {
        chooseIcon: 'Choose Icon',
      },
      importer: {
        title: 'Import City',
        fileName: 'City_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    region: {
      name: 'Regions',
      label: 'Regions',
      menu: 'Regions',
      exporterFileName: 'region_export',
      list: {
        menu: 'Regions',
        title: 'Regions',
      },
      create: {
        success: 'Region saved successfully',
        success2: 'Successfully created new region',
      },
      update: {
        success: 'Region updated successfully',
      },
      destroy: {
        success: 'Region deleted successfully',
        message: 'Do you want to delete this region?',
      },
      destroyAll: {
        success: 'Regions deleted successfully',
      },
      edit: {
        title: 'Edit Region',
      },
      new: {
        title: 'Add New Region',
      },
      view: {
        title: 'View Region',
      },
      fields: {
        id: 'Id',
        name: 'Region Name',
        code: 'Code',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        placesType: {
          mosque: 'Mosque',
          museum: 'Museum',
          playground: 'Playground',
          events: 'Events',
          mountains: 'Geographical Location',
          market: 'Market',
          cafe: 'Cafe',
        },
      },
      placeholder: {
        chooseIcon: 'Choose Icon',
      },
      importer: {
        title: 'Import Region',
        fileName: 'Region_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    notification: {
      name: 'notification',
      label: 'Notifications',
      menu: 'Notifications',
      exporterFileName: 'notification_export',
      list: {
        menu: 'Notifications',
        title: 'Notifications',
      },
      tabs: {
        all: 'All notifications',
        company: 'Company',
        guide: 'Tour Guide',
        store: 'Store',
        offers: 'Stores offers',
      },
      create: {
        success: 'Notification saved successfully',
      },
      update: {
        success: 'Notification saved successfully',
      },
      destroy: {
        success: 'Notification deleted successfully',
      },
      destroyAll: {
        success: 'Notification(s) deleted successfully',
      },
      edit: {
        title: 'Edit Notification',
      },
      fields: {
        id: 'Id',
        'title': 'Notification Title',
        'message': 'Push Message',
        'image': 'Notification Image ( optional )',
        'name': 'Notification Name ( optional )',
        'placeId': 'PlaceId',
        // PushMessage: 'Push Message',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: ' Notifications',
        hint: 'Please write the appropriate notification for this place for each language',
      },
      view: {
        title: 'View Notification',
      },
      importer: {
        title: 'Import Notifications',
        fileName: 'notification_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    trip: {
      name: 'trip',
      label: 'Trips',
      menu: 'Trips',
      viewTrip: 'view trip',
      viewReason: 'View Reason',
      reason: 'reason',
      exporterFileName: 'trip_export',
      list: {
        menu: 'Trips',
        title: 'Trips',
      },
      create: {
        success: 'Trip saved successfully',
      },
      update: {
        success: 'Trip saved successfully',
      },
      destroy: {
        success: 'Trip deleted successfully',
      },
      destroyAll: {
        success: 'Trip(s) deleted successfully',
      },
      edit: {
        title: 'Edit Trip',
      },
      fields: {
        id: 'Id',
        city: 'city',
        plan: 'plan',
        client: 'client',
        tourGuide: 'tour guide',
        company: 'company',
        paymentMethod: 'payment method',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      status: {
        completed: 'completed trips',
        planned: 'Planned trips',
        canceled: 'Canceled trips',
      },
      enumerators: {},
      placeholder: {},
      new: {
        title: 'Add Location zone',
      },
      view: {
        title: 'View Trip',
      },
      importer: {
        title: 'Import Trips',
        fileName: 'trip_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    plan: {
      name: 'Plan',
      label: 'plans',
      menu: 'plans',
      exporterFileName: 'plan_export',
      list: {
        menu: 'plans',
        title: 'plans',
      },
      days: {
        dayOne: 'Day One',
        dayTwo: 'Day Two',
        dayThree: 'Day Three',
        dayFour: 'Day Four',
        dayFive: 'Day Five',
        daySix: 'Day Six',
        daySeven: 'Day Seven',
      },
      tripDays: {
        oneDay: 'One Day',
        twoDays: 'Two Days',
        ThreeDays: 'Three Days',
        FourDays: 'Four Days',
        FiveDays: 'Five Days',
        SixDays: 'Six Days',
        SevenDays: 'Seven Days',
        moreThanDay: 'More than day',
      },
      form: {
        enterInformation: 'Enter the following information to create your plan',
        durationTitle: 'please select the trip duration',
        pleaseDragThePlaces: 'Please drag the places that you want to add in your plan',
        placesInThisCity: 'Places in this city',
        yourPlan: 'Your plan',
        dragHere: 'Drag places here',
        pleaseSelectPhotoToEachPlace: 'Please select photo to each place',
        selectPhotoToThisPlace: 'Select photo to this place',
        editPlan: 'Edit this plan',
        deletePlan: 'Delete this plan',
        reorderPlan: 'Reorder places',
        disablePlan: 'Disable this plan',
        enablePlan: 'Enable this plan',
        placesInThisPlan: 'Places in this plan',
        reordersPlaces: 'Reorders places in this plan',
        setTimeToThisPlace: 'Set time to this place',

      },
      disable: {
        success: 'plan disabled successfully',
      },
      enable: {
        success: 'plan enabled successfully',
      },
      create: {
        success: 'plan saved successfully',
      },
      update: {
        success: 'plan saved successfully',
      },
      destroy: {
        success: 'plan deleted successfully',
      },
      destroyAll: {
        success: 'plan(s) deleted successfully',
      },
      edit: {
        title: 'Edit this plan',
      },
      fields: {
        id: 'Id',
        name: 'Plan Name',
        description: 'Description',
        startCityId: 'Start City',
        startCity: 'Start City',
        region: 'City',
        city: 'City',
        type: 'Plan Type',
        programs: 'Programs',
        numberOfDays: 'Days number',

        numberOfPlans: 'Number of plans',
        dateOfCreation: 'Date of creation',
  
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        planType: {
          default: 'Default Plan',
          custom: 'Custom Plan',
        },
      },
      placeholder: {
        chooseRegions: 'Choose Regions',
        chooseRegion: 'Choose Region',
        chooseCities:  'Choose Cities',
        chooseCity: 'Choose City',
      },
      new: {
        title: 'Add New Plan',
        shortTitle: 'Add Plan',
      },
      view: {
        title: 'View Plan',
        title2: 'You can edit, delete and reorder your plan',
        moreThan:'More than +{0} places in this plan',
      },
      importer: {
        title: 'Import plans',
        fileName: 'plan_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    offer: {
      name: 'offer',
      label: 'Offers',
      menu: 'Offers',
      exporterFileName: 'offer_export',
      list: {
        menu: 'Offers',
        title: 'Offers',
      },
      create: {
        success: 'Offer saved successfully',
      },
      update: {
        success: 'Offer saved successfully',
      },
      destroy: {
        success: 'Offer deleted successfully',
      },
      destroyAll: {
        success: 'Offer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Offer',
      },
      fields: {
        id: 'Id',
        'title': 'Title',
        'description': 'Description',
        "price": "Price",
        "percentage": "Percentage",
        "oldPrice": "Old Price",

        "isActive": "Active",
        "type": "Type",
        "status": "Status",
        "rejectionReason": "Rejection Reason",
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        "type": {
          "saryah": "Rahal",
          "store": "Store",
        },
        "status": {
          "accepted": "Accepted",
          "rejected": "Rejected",
          "deleted": "Deleted",
        },
      },
      new: {
        title: 'New Offer',
      },
      view: {
        title: 'View Offer',
      },
      step: {
        one: 'Enter the data in English',
        two: 'Enter the data in Arabic',
        three: 'Enter the prices',
        four: '',
        five: '',
      },
      importer: {
        title: 'Import Offers',
        fileName: 'offer_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forget your password ?',
    signin: 'LOGIN',
    signup: 'Sign up',
    signout: 'Sign out',
    google: 'Google',
    facebook: 'Facebook',
    twitter: 'Twitter',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email`,
      verifyAccount: 'Verify your account'
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },

    AccountPermission: 'AccountPermission',
    authForgetReset: 'Enter your email address here ',
    requestReset: 'Submit',
    forgetPassword: 'forgot-password ?',
    forgetText: 'Enter your email address you are using for your account, and we will send you a password reset link ',
    cancel: 'cancel',


    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    placeEditor: {
      label: 'Place Editor',
      description: 'Edit access to Places',
    },
    placeViewer: {
      label: 'Place Viewer',
      description: 'View access to Places',
    },
    cityEditor: {
      label: 'City Editor',
      description: 'Edit access to Cities',
    },
    cityViewer: {
      label: 'City Viewer',
      description: 'View access to Cities',
    },
    regionEditor: {
      label: 'Region Editor',
      description: 'Edit access to Regions',
    },
    regionViewer: {
      label: 'Region Viewer',
      description: 'View access to Regions',
    },
    planEditor: {
      label: 'Plan Editor',
      description: 'Edit access to Plans',
    },
    planViewer: {
      label: 'Plan Viewer',
      description: 'View access to Plans',
    },
    notificationEditor: {
      label: 'Notification Editor',
      description: 'Edit access to Notifications',
    },
    notificationViewer: {
      label: 'Notification Viewer',
      description: 'View access to Notifications',
    },
    clientEditor: {
      label: 'Client Editor',
      description: 'Edit access to Clients',
    },
    clientViewer: {
      label: 'Client Viewer',
      description: 'View access to Clients',
    },
    companyEditor: {
      label: 'Company Editor',
      description: 'Edit access to Companies',
    },
    companyViewer: {
      label: 'Company Viewer',
      description: 'View access to Companies',
    },
    transactionEditor: {
      label: 'Transaction Editor',
      description: 'Edit access to Transactions',
    },
    transactionViewer: {
      label: 'Transaction Viewer',
      description: 'View access to Transactions',
    },
    cycleEditor: {
      label: 'Cycle Editor',
      description: 'Edit access to Cycles',
    },
    cycleViewer: {
      label: 'Cycle Viewer',
      description: 'View access to Cycles',
    },
    walletEditor: {
      label: 'Wallet Editor',
      description: 'Edit access to Wallets',
    },
    walletViewer: {
      label: 'Wallet Viewer',
      description: 'View access to Wallets',
    },
    offerEditor: {
      label: 'Offer Editor',
      description: 'Edit access to Offers',
    },
    offerViewer: {
      label: 'Offer Viewer',
      description: 'View access to Offers',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'Add New Admin',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      countryCode: 'countryCode',
      lang: 'lang',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      accountType: 'User Type',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
      success2: 'Settings saved successfully',
    },
    companyFeesNote: 'Please enter fees for companies by percentage or money of each trip',
    guideFeesNote: 'Please enter fees for tourguides by percentage or money of each trip',
    fields: {
      theme: 'Theme',
      defaultLanguage: 'Default Language',
      notificationLanguage: 'Notification Language',
      paymentMethods: 'Payment Methods',
      saryahFees: 'Rahal Fees',
      vatPercentage: 'VAT',
      percentage: 'Percentage cost',
      money: 'Money cost',
    },
    placeholder: {
      percentage: 'Enter your percentage cost',
      money: 'Enter your money cost',
    },
    paymentMethods: {
      cash: 'Cash',
      creditCard: 'Credit Card',
      applePay: 'Apple Pay',
      googlePay: 'Google Pay',
      stcPay: 'Stc Pay',
      paypal: 'Paypal',
      sadad: 'Sadad',
      mada: 'Mada',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    inprogressTrips: 'Inprogress trips',
    currentOffers: 'Current offers',
    activeTourGuides: 'Active tour guides',
    transactionHistory: 'Transaction history',
    fields: {
      trip: 'Trip',
      plan: 'Plan Name',
      city: 'City',
      company: 'Company',
      guide: 'Tour Guide',
      duration: 'Trip Duration',
      paymentMethod: 'Payment Method',
      date: 'Date',
      amount: 'Amount',
      rate: 'Rate',
      offer: 'Offer',
    },
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    itemCannotBeDeleted:'Deletion cannot be completed because this item is in use',
    incorrectPinCode:'Pin code is not correct',
    required: 'This field is required',
    minValue: 'The minimum value is {0}',
    maxValue: 'The maximum value is {0}',
    maxCharCount: 'Please use maximum {0} characters',
    typeRequired: 'Type is Required',
    languageRequired: 'Language is Required',
    destinationRequired: 'Destination is Required',
    purposeRequired: 'Purpose is Required',
    workAtRequired: 'Work At is Required',
    wifeNameRequired: 'Wife Name is Required',
    dateRequired: 'Date is Required',
    timeRequired: 'Time is Required',
    hospitalNameRequired: 'Hospital Name is Required',
    nameRequired: 'Name is Required',
    workPositionRequired: 'Work Position is Required',
    signatureRequired: 'Signature is Required',
    birthDateRequired: 'Birth Data is Required',
    relativeRelationRequired: 'Relative Relation is Required',
    placeOfBusinessRequired: 'Place of Business is Required',
    placeOfResidenceRequired: 'Place of Residence is Required',
    buildingRequired: 'Building is Required',
    floorRequired: 'Floor is Required',
    accountTypeRequired: 'Account Type is Required',
    managerTypeRequired: 'Manager Type is Required',
    invalidPhoneNumber: 'Invalid phone number',
    invalidNoteField: 'Invalid Note field',
    invalidReasonOfPermission: 'Invalid reason of permission field ',
    departmentRequired: 'Department is Required',
    subDepartmentRequired: 'Sub Department is Required',
    jobTitleRequired: 'Job Title is Required',
    employeeRequired: 'Employee is Required',
    employeesRequired: 'Employees is Required',

    recordIsFound: 'This record is already exist with the same barcode',

    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
      placeNameRequired: 'Place Name is required',
      placeTypeRequired: 'place Type is required',
      title: 'an error occurred',
      cantAdd:'This employee cannot be added',
      required: 'This field is required',
      typeRequired: 'Type is required',
      languageRequired: 'Language is required',
      destinationRequired: 'Destination is required',
      purposeRequired: 'Purpose is required',
      workAtRequired: 'Work At is required',
      wifeNameRequired: 'Wife Name is required',
      dateRequired: 'Date is required',
      timeRequired: 'Time is required',
      hospitalNameRequired: 'Hospital Name is required',
      nameRequired: 'Name is required',
      workPositionRequired: 'Work Position is required',
      signatureRequired: 'Signature is required',
      birthDateRequired: 'Birth Data is required',
      relativeRelationRequired:
        'Relative Relation is required',
      placeOfBusinessRequired:
        'Place of Business is required',
      placeOfResidenceRequired:
        'Place of Residence is required',
      buildingRequired: 'Building is required',
      floorRequired: 'Floor is required',
      accountTypeRequired: 'Account Type is required',
      managerTypeRequired: 'Manager Type is required',
      invalidPhoneNumber: 'Invalid phone number',
      invalidNoteField: 'Invalid note field',
      invalidReasonOfPermission: 'Invalid reason of permission field ',
      departmentRequired: 'Department is required',
      subDepartmentRequired: 'Sub Department is required',
      jobTitleRequired: 'Job title is required',
      employeeRequired: 'Employee is required',
      employeesRequired: 'Employees is required',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential': 'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use': 'Credentials are already in use',
  },
};

export default en;