import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
// import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import ImagesField from '@/shared/fields/images-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.company.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.company.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new JsonField('name', label('name'), {}),
  description: new JsonField('description', label('description'), {}),
  logo: new ImagesField('logo', label('logo'),
    // (id) => `/company/logo/${id}`,
    '/company/logo',
    { max: 1 },
  ),
  commercialRegister: new ImagesField('commercialRegister', label('commercialRegister'),
    // (id) => `/company/commercialRegister/${id}`,
    '/company/commercialRegister',
    { max: 1 },
  ),
  license: new ImagesField('license', label('license'),
    // (id) => `/company/license/${id}`,
    '/company/license',
    { max: 1 },
  ),
  email: new StringField('email', label('email'), {}),
  websiteLink: new StringField('websiteLink', label('websiteLink'), {}),
  facebookLink: new StringField('facebookLink', label('facebookLink'), {}),
  twitterLink: new StringField('twitterLink', label('twitterLink'), {}),
  instagramLink: new StringField('instagramLink', label('instagramLink'), {}),
  legalAuthority: new EnumeratorField('legalAuthority', label('legalAuthority'), [
    { value: 'owner', label: enumeratorLabel('legalAuthority', 'owner') },
    { value: 'authorized', label: enumeratorLabel('legalAuthority', 'authorized') },
  ],{}),
  rate: new DecimalField('rate', label('rate'), {}),
  wallet: new DecimalField('wallet', label('wallet'), {}),
  languages: new StringArrayField('languages', label('languages'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  countryCode: new StringField('countryCode', label('countryCode'), {}),
  companyId: new StringField('companyId', label('companyId'), {}),
  cityId: new StringField('cityId', label('cityId'), {}),

  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending') },
    { value: 'accepted', label: enumeratorLabel('status', 'accepted') },
    { value: 'rejected', label: enumeratorLabel('status', 'rejected') },
  ],{}),
  rejectionReason: new StringField('rejectionReason', label('rejectionReason'), {}),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabledAsStatus: new BooleanField('disabled', label('status'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabledAsEnum: new EnumeratorField('disabled', label('disabled'), [
    { id: 'enabled', label: i18n('user.enabled') },
    { id: 'disabled', label: i18n('user.disabled') },
  ]),
  
  reviews: new StringArrayField('reviews', label('reviews'), {}),
  // reviews: [Review]
  // branches: [Branch]
  // guides: [Guide]

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class CompanyModel extends GenericModel {
  static get fields() {
    return fields;
  }
}