import { ClientService } from '@/modules/client/client-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    clientData:{}
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    clientData:(state) => state.clientData
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    VUEXFIRE_FIND_SUCCESS(state) {
      state.loading = false;
    },

    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    LIST_PLACES(state , payload)
    {
      state.clientData = payload
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    setClients({ commit },value ){
      commit('LIST_PLACES',value)
    },

    doFindByVuexfire: firestoreAction(async (context, id) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const UserQuery = db.collection('user').doc(id)
          
        await context.bindFirestoreRef(
          'record',
          UserQuery,
          { serialize: customSerializer }
        )
        
        context.commit('VUEXFIRE_FIND_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/guide');
      }
    }),
    
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await ClientService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/client');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await ClientService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.client.create.success'));
        routerAsync().push('/client/notification/new');
        // return  res;
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await ClientService.update(id, values);
        
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.client.update.success'));
        routerAsync().push('/client');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
