//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'app-menu-item',
  // props: ['path', 'label', 'icon'],
  props: {
    path: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      link: '/',
    };
  },
  computed: {
    ...mapGetters({
      // accountType: 'layout/accountType'
    }),
    anchor() {
      if (localStorage.getItem('language') == 'ar') {
        return "center left"
      }
      return "center right"
    }
  },

  methods: {
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }
      let routePath = null
      if(this.$route.path.includes('admin') || this.$route.path.includes('client')){
        routePath = this.$route.path.split('/').slice(0,4).join("/");
      }
      else{
        routePath = this.$route.path.split('/').slice(0,3).join("/");
      }

      // const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + '/');
      return {
        active 
      };
    },
    ...mapActions({
      setAccountType:"layout/setAccountType"
    }),
    setPath(){
      this.link = this.path
      
      if(this.path.includes('/iam/client') || this.path.includes('/iam/admin')){
        // console.log('pathhhh',this.path)
       let pathParams = this.path.split('/')
       console.log('params',pathParams)
       this.setAccountType(pathParams[3])
      //  console.log('accountType layoputtttt',this.accountType)
      }
    }
  },
 
};
