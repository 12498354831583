//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';


export default {
  name: 'app-delete-item-page',
  props: ['visible', 'id', 'loading', 'storePath', 'icon', 'message'],

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      // settings:'settings/settings',
      // loading: `${this.storePath}/loading`,
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
      // doFindSettings:'settings/doFind',
      // isItemExist:'product/list/isItemExist',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
  },
};
