import service from '@/modules/auth/auth-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ProgressBar from '@/shared/progress-bar/progress-bar';

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    currentUserSocial: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    loadingGoogle: false,
    loadingFacebook: false,
    loadingTwitter: false,
  },

  getters: {
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserSocial: (state) => state.currentUserSocial,

    currentUserDetails: (state, getters) =>
      getters.currentUser ?
      getters.currentUser :
      null,

    currentUserFirstName: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.fullName.split(' ')[0] :
      null,

    currentUserLastName: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.fullName.split(' ')[1] :
      null,

    currentUserEmail: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.email :
      null,

    currentUserFullName: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.fullName :
      '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.roles || [] :
      [],

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingGoogle: (state) => !!state.loadingGoogle,
    loadingFacebook: (state) => !!state.loadingFacebook,
    loadingTwitter: (state) => !!state.loadingTwitter,

    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {

      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        // let defaultUrl= '../../../public/images/imgUploader.svg'

        return null;

      }

      return getters.currentUser.avatars[0].publicUrl;
    },

    cuurentUserSocialAvatar: (state, getters) => {

      return getters.currentUserSocial;

    }


  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },

    AUTH_START(state, payload) {
      switch (payload) {
        case 'google':
          state.loadingGoogle = true;
          break;
        case 'facebook':
            state.loadingFacebook = true;
            break;
        case 'twitter':
          state.loadingTwitter = true;
          break;
        default:
          state.loading = true;
          break;
      }
    },

    AUTH_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.currentUserSocial = payload.currentUserSocial || null;
      state.loading = false;
      state.loadingGoogle = false;
      state.loadingFacebook = false;
      state.loadingTwitter = false;
    },


    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
      state.loadingGoogle = false;
      state.loadingFacebook = false;
      state.loadingTwitter = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },
  },

  actions: {
    async doEmailVerified({ commit }) {
      try {
        // await service.checkEmailVerified();
        // commit('VERIFY_START');
        await firebase.auth().currentUser.reload();
        await firebase.auth().onAuthStateChanged((authenticationUser) => {

          // authenticationUser.emailVerified = true;
          console.log(authenticationUser.emailVerified)

          if (authenticationUser.emailVerified) {
            commit('VERIFY_SUCCESS');

            window.location.reload();
            // routerAsync().push('/');


          } else {
            // commit('VERIFY_SUCCESS');
            Message.error(i18n('Email not verified yet'));
          }
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('VERIFY_ERROR');
      }
    },
    doInitFirebase() {
      service.init();
    },
    async doInit({ commit, dispatch }) {
      // await service.init();

      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }
      const unsubscribe = service.onAuthStateChanged(
        (authenticationUser) => {
          dispatch(
            'doSigninFromAuthChange',
            authenticationUser,
          );
          unsubscribe();
        },
        (error) => {
          console.error(error);
          commit('AUTH_INIT_ERROR');
        },
      );
    },

    // async doInit({ commit, dispatch }) {
    //   await service.init();
    //   let currentUser = localStorage.getItem('currentUser');
    //   if (!currentUser) {
    //     // routerAsync().push(‘/auth/signin’);
    //     commit('AUTH_INIT_SUCCESS', {
    //       currentUser,
    //       authenticationUser: null,
    //     });
    //     ProgressBar.done();
    //   } else {
    //     const unsubscribe = service.onAuthStateChanged(
    //       (authenticationUser) => {
    //         dispatch(
    //           'doSigninFromAuthChange',
    //           authenticationUser,
    //         );
    //         unsubscribe();
    //       },
    //       (error) => {
    //         console.error(error);
    //         commit('AUTH_INIT_ERROR');
    //       },
    //     );
    //   }
    // },

    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },

    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START');

        await service.sendEmailVerification(
          getters.authenticationUser,
        );

        Message.success(
          i18n('auth.verificationEmailSuccess'),
        );

        commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_CONFIRMATION_ERROR');
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START');
        firebase.auth().languageCode = localStorage.getItem('language')
        await service.sendPasswordResetEmail(email);
        debugger
        routerAsync().push('/');
        Message.success(
          i18n('auth.passwordResetEmailSuccess')
        );
        commit('PASSWORD_RESET_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_RESET_ERROR');
      }
    },

    async doSigninSocial({ commit }, { provider, rememberMe }) {
      try {
        commit('AUTH_START', provider);
        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithSocial(
          provider,
          rememberMe,
        );

        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = true;
          currentUser.lang = localStorage.getItem('language') || 'en'
          console.log("is new user  ?", credentials.additionalUserInfo.isNewUser);

          if (credentials.additionalUserInfo.isNewUser) {
            let avatar = [];
            let firstName;
            let lastName;
            let countryCode = '';
            let lang = localStorage.getItem('language')
            currentUser.accountType = 'admin';

            //  google 
            if (provider == "google") {
              firstName = credentials.additionalUserInfo.profile.given_name
              lastName = credentials.additionalUserInfo.profile.family_name

              if (credentials.additionalUserInfo.profile.picture) {
                avatar = [{
                  id: '',
                  name: 'photo.jpg',
                  privateUrl: '',
                  publicUrl: credentials.additionalUserInfo.profile.picture,
                }, ];
              }
            }


            // facebook 
            if (provider == "facebook") {
              firstName = credentials.additionalUserInfo.profile.first_name
              lastName = credentials.additionalUserInfo.profile.last_name

              if (credentials.additionalUserInfo.profile.picture.data.url) {
                avatar = [{
                  id: '',
                  name: 'photo.jpg',
                  privateUrl: '',
                  publicUrl: credentials.additionalUserInfo.profile.picture.data.url,
                }, ];
              }
            }

            //  twitter
            if (provider == "twitter") {
              firstName = credentials.user.displayName.split(' ')[0]
              lastName = credentials.user.displayName.split(' ')[1]

              if (credentials.user.photoURL) {
                avatar = [{
                  id: '',
                  name: 'photo.jpg',
                  privateUrl: '',
                  publicUrl: credentials.user.photoURL,
                }, ];
              }
            }

            await service.updateProfile(
              firstName,
              lastName,
              credentials.user.phoneNumber,
              countryCode,
              lang,
              avatar,
              currentUser.accountType
            );
            currentUser.avatars = avatar;
            currentUser.accountType = 'admin';
          } else {
            if (currentUser.accountType != 'admin') {
              throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
            }
          }
        }

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        routerAsync().push('/');
      } catch (error) {
        // console.log('error ==>> ' ,error );
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doRegisterEmailAndPassword({ commit }, {
      email,
      password
    }, ) {
      try {
        commit('AUTH_START');

        const authenticationUser = await service.registerWithEmailAndPassword(
          email,
          password,
        );

        let currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        currentUser.lang = localStorage.getItem('language') || 'en'
        currentUser.accountType = 'admin';

        firebase.auth().languageCode = localStorage.getItem('language')

        // update profile 
        await service.updateProfile(
          currentUser.firstName,
          currentUser.lastName,
          currentUser.phoneNumber,
          currentUser.countryCode,
          currentUser.lang,
          currentUser.avatars,
          currentUser.accountType
        );

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninWithEmailAndPassword({ commit }, { email, password, rememberMe }) {
      try {
        commit('AUTH_START');
        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe,
        );
        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;
          if (currentUser.accountType != 'admin') {
            throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
          }
        }

        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        routerAsync().push('/');
      } catch (error) {
        // console.log('error ==>> ' ,error );
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSignout({ commit }) {
      try {
        commit('AUTH_START');
        await service.signout();
        localStorage.clear()

        localStorage.setItem('language', 'en');

        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null,
        });

        routerAsync().push('/auth/signin');
      } catch (error) {
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },


    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem(
          'currentUser',
        );
        // let currentUser = JSON.parse(userStorge);
        if (authenticationUser) {
          // moment.locale(arg);
          // localStorage.setItem('language', arg);
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
            // localStorage.setItem('currentUser', JSON.stringify(service.encryptObject(currentUser, 'C-U')));
          } else {
            // currentUser = service.decryptObject(currentUser, 'C-U');
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }
          // console.log(currentUser);
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode ,
          //   currentUser.lang,
          //   currentUser.avatars,
          // );

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified =
            authenticationUser.emailVerified;
        }
        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },


    async doRefreshCurrentUser({
      commit,
      getters
    }) {
      try {
        const authenticationUser = getters.authenticationUser;

        // const currentUser = await service.fetchMe();

        let currentUser = localStorage.getItem('currentUser');
        if (!currentUser) {
          currentUser = await service.fetchMe();
          localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        } else {
          currentUser = service.decryption(currentUser, 'secret-c-u');
        }

        currentUser.emailVerified =
          authenticationUser.emailVerified;

        // in background
        // service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },

    async doUpdateProfile({ commit, dispatch }, {
      firstName,
      lastName,
      phoneNumber,
      countryCode,
      avatars,
      lang,
      accountType
    }, ) {
      try {
        commit('UPDATE_PROFILE_START');

        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
          let user = service.decryption(currentUser, 'secret-c-u');
          user.firstName = firstName;
          user.lastName = lastName;
          user.fullName = firstName + ' ' + lastName;
          user.phoneNumber = phoneNumber;
          user.countryCode = countryCode;
          user.lang = lang;

          user.avatars = avatars;
          user.accountType = accountType;
          localStorage.setItem('currentUser', service.encryption(user, 'secret-c-u'));
        }

        await service.updateProfile(
          firstName,
          lastName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType
        );

        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.profile.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },
  },
};