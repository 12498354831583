import requestListStore from '@/modules/request/request-list-store';
// import requestViewStore from '@/modules/request/request-view-store';
// import requestImporterStore from '@/modules/request/request-importer-store';
import requestFormStore from '@/modules/request/request-form-store';
// import requestDestroyStore from '@/modules/request/request-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: requestDestroyStore,
    form: requestFormStore,
    list: requestListStore,
    // view: requestViewStore,
    // importer: requestImporterStore,
  },
};
