import importerStore from '@/shared/importer/importer-store';
import { ReviewService } from '@/modules/review/review-service';
import reviewImporterFields from '@/modules/review/review-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  ReviewService.import,
  reviewImporterFields,
  i18n('entities.review.importer.fileName'),
  i18n('entities.review.importer.hint'),
);
