import storesListStore from '@/modules/stores/stores-list-store';
import storesViewStore from '@/modules/stores/stores-view-store';
import storesImporterStore from '@/modules/stores/stores-importer-store';
import storesFormStore from '@/modules/stores/stores-form-store';
import storesDestroyStore from '@/modules/stores/stores-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: storesDestroyStore,
    form: storesFormStore,
    list: storesListStore,
    view: storesViewStore,
    importer: storesImporterStore,
  },
};
