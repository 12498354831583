//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import moment from 'moment';
// import ReviewsViewModal from '@/modules/reviews/components/reviews-view-modal.vue';

// import { ReviewsPermissions } from '@/modules/reviews/reviews-permissions';
// import { ReviewsModel } from '@/modules/reviews/reviews-model';
// import { FormSchema } from '@/shared/form/form-schema';
// const { fields } = ReviewsModel;

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  name: 'app-review-card',

  props: {
    value: {
      type: Object,
      required: true,
    } 
  },

  firestore() {
    return {
      reviewer: firebase.firestore().collection('user').doc(this.value.createdBy),
    }
  },
  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      model: null,
      isMore: false,
      reviewer: undefined,
    }
  },
  computed: {
    ...mapGetters({
      // record: 'reviews/view/record',
      // loading: 'reviews/view/loading',
      // destroyLoading: 'reviews/destroy/loading',
      // changeStatusLoading: 'reviews/form/changeStatusLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    readMore() {
      return this.isMore
    },

    reviewerAvatar() {
      if (this.reviewer) {
        if (this.reviewer.avatar) {
          return this.reviewer.avatar.publicUrl 
        } else if (this.reviewer.avatars && this.reviewer.avatars.length) {
          return this.reviewer.avatars[0].publicUrl
        } 
      }
      return '/images/avatars/user-avatar.svg'
    },
    // fields() {
    //   return fields;
    // },
    // hasPermissionToEdit() {
    // displayPlaces() {
    //   return this.placesName.slice(0, 3);
    // },
    //   return new ReviewsPermissions(this.currentUser).edit;
    // },
    // hasPermissionToDestroy() {
    //   return new ReviewsPermissions(this.currentUser).destroy;
    // },
    // displayImages() {
    //   return this.images.slice(0, 3);
    // },
    // displayRecord() {
    //   return {
    //     ...this.value,
    //     places: this.places,
    //     images: this.images,
    //   }
    // },
  },
  methods: {
    ...mapActions({
      // doFind: 'reviews/view/doFind',
      // doDestroy: 'reviews/destroy/doDestroy',
      // doDisable: 'reviews/form/doDisable',
      // doEnable: 'reviews/form/doEnable',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      // return moment(ReviewsModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
  },
};
