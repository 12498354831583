import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-invoice';

export class InvoiceService {
//#region [ CRED Operations ]
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation INVOICE_CREATE($data: InvoiceInput!) {
          invoiceCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.invoiceCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation INVOICE_UPDATE(
          $id: String!
          $data: InvoiceInput!
        ) {
          invoiceUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.invoiceUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation INVOICE_DESTROY($ids: [String!]!) {
          invoiceDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.invoiceDestroy;
  }
//#endregion

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation INVOICE_IMPORT(
          $data: InvoiceInput!
          $importHash: String!
        ) {
          invoiceImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.invoiceImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query INVOICE_FIND($id: String!) {
          invoiceFind(id: $id) 
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.invoiceFind;
  }

  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql `
        query LIST_INVOICES_WITH_PAGINATION(
            $filter: InvoiceFilterInput, 
            $orderBy: String,
            $pagination: PaginationInput      
        ) {
          listInvoicesWithPagination(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              receiptNum
              total
              transactionId
              paymentMethod
              guide {
                id
                fullName
                avatar {
                  name
                  publicUrl
                }
                phoneNumber
              }
              client {
                id
                fullName
                avatar {
                  name
                  publicUrl
                }
                phoneNumber
              }
              tripOwner {
                id
                name {
                  en
                  ar
                }
                avatar
                email
                phoneNumber
                rate
                type
              }
              createdAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });

    return response.data.listInvoicesWithPagination;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query INVOICE_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          invoiceAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.invoiceAutocomplete;
  }
}