import { render, staticRenderFns } from "./delete-item-modal.vue?vue&type=template&id=9b67561a&scoped=true&"
import script from "./delete-item-modal.vue?vue&type=script&lang=js&"
export * from "./delete-item-modal.vue?vue&type=script&lang=js&"
import style0 from "./delete-item-modal.vue?vue&type=style&index=0&id=9b67561a&lang=scss&scoped=true&"
import style1 from "./delete-item-modal.vue?vue&type=style&index=1&id=9b67561a&lang=scss&scoped=true&"
import style2 from "./delete-item-modal.vue?vue&type=style&index=2&id=9b67561a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b67561a",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn,QSpinnerIos,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn,QSpinnerIos})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
