import Roles from '@/security/roles';
const roles = Roles.values;

/**
 * List of Permissions and the Roles allowed of using them.
 */
class Permissions {
  static get values() {
    return {
      // ============================================================================================================ //
      //                                                   IAM
      // ============================================================================================================ //
      //#region [ IAM ]
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
          roles.viewer,
        ],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,


        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   AUDITLOG
      // ============================================================================================================ //
      //#region [ AUDITLOG ]
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.auditLogViewer, roles.viewer],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.owner],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   PLACE
      // ============================================================================================================ //
      //#region [ PLACE ]
      placeImport: {
        id: 'placeImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
      },
      placeCreate: {
        id: 'placeCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeEdit: {
        id: 'placeEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeDestroy: {
        id: 'placeDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeRead: {
        id: 'placeRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeEditor,
          roles.placeViewer,
        ],
      },
      placeAutocomplete: {
        id: 'placeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeEditor,
          roles.placeViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   PLACE TYPE
      // ============================================================================================================ //
      //#region [ AUDITLOG ]
      placeTypeImport: {
        id: 'placeTypeImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeTypeEditor,
        ],
      },
      placeTypeCreate: {
        id: 'placeTypeCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeTypeEditor,
        ],
        allowedStorageFolders: ['placeType'],
      },
      placeTypeEdit: {
        id: 'placeTypeEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeTypeEditor,
        ],
        allowedStorageFolders: ['placeType'],
      },
      placeTypeDestroy: {
        id: 'placeTypeDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeTypeEditor,
        ],
        allowedStorageFolders: ['placeType'],
      },
      placeTypeRead: {
        id: 'placeTypeRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeTypeEditor,
          roles.placeTypeViewer,
        ],
      },
      placeTypeAutocomplete: {
        id: 'placeTypeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeTypeEditor,
          roles.placeTypeViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   CITY
      // ============================================================================================================ //
      //#region [ CITY ]
      cityImport: {
        id: 'cityImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cityEditor,
        ],
      },
      cityCreate: {
        id: 'cityCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cityEditor,
        ],
        allowedStorageFolders: ['city'],
      },
      cityEdit: {
        id: 'cityEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cityEditor,
        ],
        allowedStorageFolders: ['city'],
      },
      cityDestroy: {
        id: 'cityDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cityEditor,
        ],
        allowedStorageFolders: ['city'],
      },
      cityRead: {
        id: 'cityRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.cityEditor,
          roles.cityViewer,
        ],
      },
      cityAutocomplete: {
        id: 'cityAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.cityEditor,
          roles.cityViewer,
        ],
      },
      //#endregion
      
      // ============================================================================================================ //
      //                                                   REGION
      // ============================================================================================================ //
      //#region [ REGION ]
      regionImport: {
        id: 'regionImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.regionEditor,
        ],
      },
      regionCreate: {
        id: 'regionCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.regionEditor,
        ],
        allowedStorageFolders: ['region'],
      },
      regionEdit: {
        id: 'regionEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.regionEditor,
        ],
        allowedStorageFolders: ['region'],
      },
      regionDestroy: {
        id: 'regionDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.regionEditor,
        ],
        allowedStorageFolders: ['region'],
      },
      regionRead: {
        id: 'regionRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.regionEditor,
          roles.regionViewer,
        ],
      },
      regionAutocomplete: {
        id: 'regionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.regionEditor,
          roles.regionViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   COUNTRY
      // ============================================================================================================ //
      //#region [ COUNTRY ]
      countryImport: {
        id: 'countryImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.countryEditor,
        ],
      },
      countryCreate: {
        id: 'countryCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.countryEditor,
        ],
        allowedStorageFolders: ['country'],
      },
      countryEdit: {
        id: 'countryEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.countryEditor,
        ],
        allowedStorageFolders: ['country'],
      },
      countryDestroy: {
        id: 'countryDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.countryEditor,
        ],
        allowedStorageFolders: ['country'],
      },
      countryRead: {
        id: 'countryRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.countryEditor,
          roles.countryViewer,
        ],
      },
      countryAutocomplete: {
        id: 'countryAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.countryEditor,
          roles.countryViewer,
        ],
      },
      //#endregion
      
      // ============================================================================================================ //  
      //                                                   NOTIFICATION
      // ============================================================================================================ //
      //#region [ NOTIFICATION ]
      notificationImport: {
        id: 'notificationImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
      },
      notificationCreate: {
        id: 'notificationCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationEdit: {
        id: 'notificationEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationDestroy: {
        id: 'notificationDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationRead: {
        id: 'notificationRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.notificationEditor,
          roles.notificationViewer,
        ],
      },
      notificationAutocomplete: {
        id: 'notificationAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.notificationEditor,
          roles.notificationViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   PLAN
      // ============================================================================================================ //
      //#region [ PLAN ]
      planImport: {
        id: 'planImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.planEditor,
        ],
      },
      planCreate: {
        id: 'planCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.planEditor,
        ],
        allowedStorageFolders: ['plan'],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.planEditor,
        ],
        allowedStorageFolders: ['plan'],
      },
      planDestroy: {
        id: 'planDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.planEditor,
        ],
        allowedStorageFolders: ['plan'],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.planEditor,
          roles.planViewer,
        ],
      },
      planAutocomplete: {
        id: 'planAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.planEditor,
          roles.planViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   GUIDE
      // ============================================================================================================ //
      //#region [ GUIDE ]
      guideImport: {
        id: 'guideImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.guideEditor,
        ],
      },
      guideCreate: {
        id: 'guideCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.guideEditor,
        ],
        allowedStorageFolders: ['guide'],
      },
      guideEdit: {
        id: 'guideEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.guideEditor,
        ],
        allowedStorageFolders: ['guide'],
      },
      guideDestroy: {
        id: 'guideDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.guideEditor,
        ],
        allowedStorageFolders: ['guide'],
      },
      guideRead: {
        id: 'guideRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.guideEditor,
          roles.guideViewer,
        ],
      },
      guideAutocomplete: {
        id: 'guideAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.guideEditor,
          roles.guideViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   CLIENT
      // ============================================================================================================ //
      //#region [ CLIENT ]
      clientImport: {
        id: 'clientImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.clientEditor,
        ],
      },
      clientCreate: {
        id: 'clientCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.clientEditor,
        ],
        allowedStorageFolders: ['client'],
      },
      clientEdit: {
        id: 'clientEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.clientEditor,
        ],
        allowedStorageFolders: ['client'],
      },
      clientDestroy: {
        id: 'clientDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.clientEditor,
        ],
        allowedStorageFolders: ['client'],
      },
      clientRead: {
        id: 'clientRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.clientEditor,
          roles.clientViewer,
        ],
      },
      clientAutocomplete: {
        id: 'clientAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.clientEditor,
          roles.clientViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   COMPANY
      // ============================================================================================================ //
      //#region [ COMPANY ]
      companyImport: {
        id: 'companyImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.companyEditor,
        ],
      },
      companyCreate: {
        id: 'companyCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.companyEditor,
        ],
        allowedStorageFolders: ['company'],
      },
      companyEdit: {
        id: 'companyEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.companyEditor,
        ],
        allowedStorageFolders: ['company'],
      },
      companyDestroy: {
        id: 'companyDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.companyEditor,
        ],
        allowedStorageFolders: ['company'],
      },
      companyRead: {
        id: 'companyRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.companyEditor,
          roles.companyViewer,
        ],
      },
      companyAutocomplete: {
        id: 'companyAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.companyEditor,
          roles.companyViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   TRANSACTION
      // ============================================================================================================ //
      //#region [ TRANSACTION ]
      transactionImport: {
        id: 'transactionImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.transactionEditor,
        ],
      },
      transactionCreate: {
        id: 'transactionCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.transactionEditor,
        ],
        allowedStorageFolders: ['transaction'],
      },
      transactionEdit: {
        id: 'transactionEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.transactionEditor,
        ],
        allowedStorageFolders: ['transaction'],
      },
      transactionDestroy: {
        id: 'transactionDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.transactionEditor,
        ],
        allowedStorageFolders: ['transaction'],
      },
      transactionRead: {
        id: 'transactionRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.transactionEditor,
          roles.transactionViewer,
        ],
      },
      transactionAutocomplete: {
        id: 'transactionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.transactionEditor,
          roles.transactionViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   CYCLE
      // ============================================================================================================ //
      //#region [ CYCLE ]
      cycleImport: {
        id: 'cycleImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cycleEditor,
        ],
      },
      cycleCreate: {
        id: 'cycleCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cycleEditor,
        ],
        allowedStorageFolders: ['cycle'],
      },
      cycleEdit: {
        id: 'cycleEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cycleEditor,
        ],
        allowedStorageFolders: ['cycle'],
      },
      cycleDestroy: {
        id: 'cycleDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.cycleEditor,
        ],
        allowedStorageFolders: ['cycle'],
      },
      cycleRead: {
        id: 'cycleRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.cycleEditor,
          roles.cycleViewer,
        ],
      },
      cycleAutocomplete: {
        id: 'cycleAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.cycleEditor,
          roles.cycleViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   INVOICE
      // ============================================================================================================ //
      //#region [ INVOICE ]
      invoiceImport: {
        id: 'invoiceImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.invoiceEditor,
        ],
      },
      invoiceCreate: {
        id: 'invoiceCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.invoiceEditor,
        ],
        allowedStorageFolders: ['invoice'],
      },
      invoiceEdit: {
        id: 'invoiceEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.invoiceEditor,
        ],
        allowedStorageFolders: ['invoice'],
      },
      invoiceDestroy: {
        id: 'invoiceDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.invoiceEditor,
        ],
        allowedStorageFolders: ['invoice'],
      },
      invoiceRead: {
        id: 'invoiceRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.invoiceEditor,
          roles.invoiceViewer,
        ],
      },
      invoiceAutocomplete: {
        id: 'invoiceAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.invoiceEditor,
          roles.invoiceViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   WALLET
      // ============================================================================================================ //
      //#region [ WALLET ]
      walletImport: {
        id: 'walletImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.walletEditor,
        ],
      },
      walletCreate: {
        id: 'walletCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.walletEditor,
        ],
        allowedStorageFolders: ['wallet'],
      },
      walletEdit: {
        id: 'walletEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.walletEditor,
        ],
        allowedStorageFolders: ['wallet'],
      },
      walletDestroy: {
        id: 'walletDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.walletEditor,
        ],
        allowedStorageFolders: ['wallet'],
      },
      walletRead: {
        id: 'walletRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.walletEditor,
          roles.walletViewer,
        ],
      },
      walletAutocomplete: {
        id: 'walletAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.walletEditor,
          roles.walletViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   OFFERS
      // ============================================================================================================ //
      //#region [ OFFERS ]
      offerImport: {
        id: 'offerImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
      },
      offerExport: {
        id: 'offerExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
      },
      offerCreate: {
        id: 'offerCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerEdit: {
        id: 'offerEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerDestroy: {
        id: 'offerDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerRead: {
        id: 'offerRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.offerEditor,
          roles.offerViewer,
        ],
      },
      offerAutocomplete: {
        id: 'offerAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.offerEditor,
          roles.offerViewer,
          roles.orderEditor,
          roles.orderViewer,
        ],
      },
      //#endregion


      // ============================================================================================================ //  
      //                                                   STORE
      // ============================================================================================================ //
      //#region [ STORE ]
      storeImport: {
        id: 'storeImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.storeEditor,
        ],
      },
      storeCreate: {
        id: 'storeCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.storeEditor,
        ],
        allowedStorageFolders: ['store'],
      },
      storeEdit: {
        id: 'storeEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.storeEditor,
        ],
        allowedStorageFolders: ['store'],
      },
      storeDestroy: {
        id: 'storeDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.storeEditor,
        ],
        allowedStorageFolders: ['store'],
      },
      storeRead: {
        id: 'storeRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.storeEditor,
          roles.storeViewer,
        ],
      },
      storeAutocomplete: {
        id: 'storeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.storeEditor,
          roles.storeViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   REVIEW
      // ============================================================================================================ //
      //#region [ REVIEW ]
      reviewImport: {
        id: 'reviewImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reviewEditor,
        ],
      },
      reviewCreate: {
        id: 'reviewCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reviewEditor,
        ],
        allowedStorageFolders: ['review'],
      },
      reviewEdit: {
        id: 'reviewEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reviewEditor,
        ],
        allowedStorageFolders: ['review'],
      },
      reviewDestroy: {
        id: 'reviewDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reviewEditor,
        ],
        allowedStorageFolders: ['review'],
      },
      reviewRead: {
        id: 'reviewRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.reviewEditor,
          roles.reviewViewer,
        ],
      },
      reviewAutocomplete: {
        id: 'reviewAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.reviewEditor,
          roles.reviewViewer,

        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   TRIP
      // ============================================================================================================ //
      //#region [ TRIP ]
      tripImport: {
        id: 'tripImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.tripEditor,
        ],
      },
      tripCreate: {
        id: 'tripCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.tripEditor,
        ],
        allowedStorageFolders: ['trip'],
      },
      tripEdit: {
        id: 'tripEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.tripEditor,
        ],
        allowedStorageFolders: ['trip'],
      },
      tripDestroy: {
        id: 'tripDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.tripEditor,
        ],
        allowedStorageFolders: ['trip'],
      },
      tripRead: {
        id: 'tripRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.tripEditor,
          roles.tripViewer,
        ],
      },
      tripAutocomplete: {
        id: 'tripAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.tripEditor,
          roles.tripViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   BRANCH
      // ============================================================================================================ //
      //#region [ BRANCH ]
      branchImport: {
        id: 'branchImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
      },
      branchCreate: {
        id: 'branchCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchEdit: {
        id: 'branchEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchDestroy: {
        id: 'branchDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.branchEditor,
        ],
        allowedStorageFolders: ['branch'],
      },
      branchRead: {
        id: 'branchRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.branchEditor,
          roles.branchViewer,
        ],
      },
      branchAutocomplete: {
        id: 'branchAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.branchEditor,
          roles.branchViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //  
      //                                                   RESERVATION
      // ============================================================================================================ //
      //#region [ RESERVATION ]
      reservationImport: {
        id: 'reservationImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reservationEditor,
        ],
      },
      reservationCreate: {
        id: 'reservationCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reservationEditor,
        ],
        allowedStorageFolders: ['reservation'],
      },
      reservationEdit: {
        id: 'reservationEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reservationEditor,
        ],
        allowedStorageFolders: ['reservation'],
      },
      reservationDestroy: {
        id: 'reservationDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.reservationEditor,
        ],
        allowedStorageFolders: ['reservation'],
      },
      reservationRead: {
        id: 'reservationRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.reservationEditor,
          roles.reservationViewer,
        ],
      },
      reservationAutocomplete: {
        id: 'reservationAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.reservationEditor,
          roles.reservationViewer,
        ],
      },
      //#endregion

      //#region REQUESTS
      requestAccept: {
        id: 'requestAccept',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.requestEditor,
          roles.requestViewer,
        ],
      },

      requestList: {
        id: 'requestList',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.requestEditor,
          roles.requestViewer,
        ],
      },

      requestConfirm: {
        id: 'requestConfirm',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.requestEditor,
          roles.requestViewer,
        ],
      },

      requestReject: {
        id: 'requestReject',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.requestEditor,
          roles.requestViewer,
        ],
      },
      //#endregion
      
      // ============================================================================================================ //  
      //                                                   TEST
      // ============================================================================================================ //
      //#region [ TEST ]
      //#endregion
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
