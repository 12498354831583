import { CompanyService } from '@/modules/company/company-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    VUEXFIRE_FIND_SUCCESS(state) {
      state.loading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
  },

  actions: {
    doFindByVuexfire: firestoreAction(async (context, id) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const UserQuery = db.collection('company').doc(id)
          
        await context.bindFirestoreRef(
          'record',
          UserQuery,
          { serialize: customSerializer }
        )
        
        context.commit('VUEXFIRE_FIND_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/company');
      }
    }),

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await CompanyService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/company');
      }
    },
  },
};

