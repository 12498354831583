import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-notification';

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';   // for cloud firestore
// import 'firebase/compat/auth';        // for authentication
// import 'firebase/storage';     // for storage
// import 'firebase/compat/database';    // for realtime database
// import 'firebase/messaging';   // for cloud messaging
// import 'firebase/functions';   // for cloud functions

import { Notify } from 'quasar'
import i18n from '@/vueI18n'


export class NotificationService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_CREATE($data: NotificationInput!) {
          notificationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.notificationCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_UPDATE(
          $id: String!
          $data: NotificationInput!
        ) {
          notificationUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.notificationUpdate;
  }

  static async setAction(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_ADMIN_SET_ACTION(
          $id: String!
          $data: NotificationInput!
        ) {
          adminSetAction(id: $id, data: $data)
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.notificationUpdate;
  }

  static async markAsRead(id) {
    await firebase.firestore().collection('notification').doc(id).update({ read: true })
  }
  static async markAsUnread(id) {
    await firebase.firestore().collection('notification').doc(id).update({ read: false })
  }
  static async markAsNew(id) {
    await firebase.firestore().collection('notification').doc(id).update({ isNew: true })
  }
  static async markAsOld(id) {
    await firebase.firestore().collection('notification').doc(id).update({ isNew: false })
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_DESTROY($ids: [String!]!) {
          notificationDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.notificationDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_IMPORT(
          $data: NotificationInput!
          $importHash: String!
        ) {
          notificationImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.notificationImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_FIND($id: String!) {
          notificationFind(id: $id) {
            id
            image 
            title {
              ar
              en
            }
            message {
              ar
              en
            }
            read
            isNew
            type
            itemId
            notes
            action
            updates

            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.notificationFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_LIST(
          $filter: NotificationFilterInput
          $orderBy: NotificationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          notificationList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              image 
              title {
                ar
                en
              }
              message {
                ar
                en
              }
              read
              isNew
              type
              itemId
              notes
              action
              updates

              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.notificationList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          notificationAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.notificationAutocomplete;
  }

  
  /**
   * Populates the records with all its relations.
   * @param {*} records
   */
  static async populateAll(records) {
    return await Promise.all(
      records.map((record) => this.populate(record)),
    );
  }

  /**
   * Populates the record with all its relations.
   * @param {*} record
   */
  static async populate(record) {
    if (!record) {
      return record;
    }
    // // Get Relation To One =>> Get Data Of [ Store, Offer, Company or Tour Guide ]
    const collectionName = record.type.includes('offer') ? 'offer' : 'user'
    record['itemDetails'] = await FirebaseRepository.findRelation(
      collectionName,
      record.itemId,
    );
    // await firebase.firestore().collection(data.itemType).doc(data.itemId).get()

    return record;
  }
  static showNotif(notification) {
    const language = i18n.locale
    const dismiss = Notify.create({
      position: 'top-right',
      timeout: 0,
      color: 'Pelorous',
      textColor: 'white',
      classes: 'glossy',
      progress: true,
      avatar: notification.imageUrl ? notification.imageUrl : '/images/icons/ic-notification-unread.svg',
      message: notification.title[language],
      caption: notification.body[language],
      actions: [
        {  color: 'white', icon: 'far fa-window-close', handler: () => { dismiss() } }
      ]
    })
    this.markAsOld(notification.id)
  }

  // img:/images/icons/ic-notification-unread
}