import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import BooleanField from '@/shared/fields/boolean-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import StringArrayField from '@/shared/fields/string-array-field';

function label(name) {
  return i18n(`entities.notification.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.notification.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  title: new StringField('title', label('title'), { required: true }),
  body: new StringField('body', label('message'), { required: true }),
  imageUrl: new StringField('imageUrl', label('image'), {}),
  read: new BooleanField('read', label('read'), {}),
  isNew: new BooleanField('isNew', label('isNew'), {}),
  itemId: new StringField('itemId', label('itemId'), {}),
  notes: new StringField('notes', label('notes'), {}),
  type: new EnumeratorField('read', label('read'), [
    { value: 'company', label: enumeratorLabel('type', 'company') },
    { value: 'guide', label: enumeratorLabel('type', 'guide') },
    { value: 'store', label: enumeratorLabel('type', 'store') },
    { value: 'offer', label: enumeratorLabel('type', 'offers') },
    { value: 'place', label: enumeratorLabel('type', 'places') },
    { value: 'companyUpdate', label: enumeratorLabel('type', 'companyUpdates') },
    { value: 'guideUpdate', label: enumeratorLabel('type', 'guideUpdate') },
    { value: 'storeUpdate', label: enumeratorLabel('type', 'storeUpdates') },
    { value: 'offerUpdate', label: enumeratorLabel('type', 'offerUpdate') },
    { value: 'placeUpdate', label: enumeratorLabel('type', 'placeUpdate') },
  ],{}),
  action: new EnumeratorField('action', label('action'), [
    { value: 'accepted', label: enumeratorLabel('action', 'accepted') },
    { value: 'rejected', label: enumeratorLabel('action', 'rejected') },
  ],{}),
  updates: new JsonField('updates', label('updates'), {}),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange'),
  ),
};

export class NotificationModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
