//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import NotificationsList from '@/modules/layout/components/notifications-list.vue';
// import i18n from '@/vueI18n'
// import moment from 'moment';

export default {
  name: 'app-header',
  components: {
    [NotificationsList.name]: NotificationsList,
  },

  data() {
    return {
      showM: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',

      newNotifications: 'notification/list/newNotifications',
      unreadNotifications: 'notification/list/unreadNotificationsToHeader',
      latestNotifications: 'notification/list/latestNotifications',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isActivePath() {
      return path => {
        return this.$route.path.startsWith(path)
      }
    },
    notificationCount() {
      return this.unreadNotifications > 9 ? '+9' : this.unreadNotifications
    }
  },
  async mounted() {
    this.doFetchNotificationCount()
    this.doFetchNewNotifications()
    this.doFetcLatestNotifications({ limit: 20 })
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout',

      doFetchNotificationCount: 'notification/list/doFetchCountToHeaderLayout',
      doFetchNewNotifications: 'notification/list/doFetchNewNotifications',
      doFetcLatestNotifications: 'notification/list/doFetcLatestNotifications',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    handleDropdownCommand(command) {
      if (command === 'doSignout') {
        this.doSignout();
      }

      if (command === 'doEditProfile') {
        this.doEditProfile();
      }
    },
    doViewProfile() {
      return this.$router.push('/auth/profile');
    },
    doEditProfile() {
      return this.$router.push('/auth/edit-profile');
    },
    showMenu() {
      if (this.showM == false) {
        this.showM = true;
      } else {
        this.showM = false;
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    doToggleMenu() {
      this.$emit('toggleMenu')
    },
    onLoadMenu (index, done) {
      if (index > 1) {
        setTimeout(() => {
          if (this.itemsMenu) {
            this.itemsMenu.push({}, {}, {}, {}, {}, {}, {})
            done()
          }
        }, 2000)
      }
      else {
        setTimeout(() => {
          done()
        }, 200)
      }
    },
    doSeeNotification(item) {
      // this.$router.push(`/notification?id=${item.id}&type=${item.type}`)
      this.$router.push({ 
        path: `/notification`, 
        query: {
          id: item.id,
          type: item.type
        }
      })
    },
  },
};
