import { NotificationService } from '@/modules/notification/notification-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    record: null,
    findLoading: false,
    saveLoading: false,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await NotificationService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/notification');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await NotificationService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.notification.create.success'));
        // routerAsync().push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await NotificationService.update(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.notification.update.success'));
        // routerAsync().push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doSetAction({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        // delete values.updatedBy
        // delete values.updatedAt
        // delete values.createdBy
        // delete values.createdAt
debugger
        await NotificationService.setAction(id, {
          // sendTo: Receiver
          itemId: values.itemId,
          type: values.type, 
          title: values.title,
          body: values.body,
          imageUrl: values.imageUrl,
          updates: values.updates,
          action: values.action,
        });
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.notification.update.success'));
        // routerAsync().push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doMarkAsRead({ commit }, id) {
      try {
        // commit('UPDATE_STARTED');
        await NotificationService.markAsRead(id);
        // commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doMarkAsUnread({ commit }, id) {
      try {
        // commit('UPDATE_STARTED');
        await NotificationService.markAsUnread(id);
        // commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doMarkAsNew({ commit }, id) {
      try {
        // commit('UPDATE_STARTED');
        await NotificationService.markAsNew(id);
        // commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doMarkAsOld({ commit }, id) {
      try {
        // commit('UPDATE_STARTED');
        await NotificationService.markAsOld(id);
        // commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
