import reservationListStore from '@/modules/reservation/reservation-list-store';
import reservationViewStore from '@/modules/reservation/reservation-view-store';
import reservationImporterStore from '@/modules/reservation/reservation-importer-store';
import reservationFormStore from '@/modules/reservation/reservation-form-store';
import reservationDestroyStore from '@/modules/reservation/reservation-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: reservationDestroyStore,
    form: reservationFormStore,
    list: reservationListStore,
    view: reservationViewStore,
    importer: reservationImporterStore,
  },
};
