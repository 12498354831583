// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const CountryListPage = () =>
  import('@/modules/country/components/country-list-page.vue');
const CountryFormPage = () =>
  import('@/modules/country/components/country-form-page.vue');
// const CountryViewPage = () =>
//   import('@/modules/country/components/country-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'country',
        path: '/country',
        component: CountryListPage,
        meta: {
          auth: true,
          permission: Permissions.values.countryView,
          title: i18n('metaTitle.country')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'countryNew',
        path: '/country/new',
        component: CountryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.countryCreate,
          title: i18n('metaTitle.newCountry') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'countryEdit',
        path: '/country/:id/edit',
        component: CountryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.countryEdit,          
          title: i18n('metaTitle.editCountry') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      // {
      //   name: 'countryView',
      //   path: '/country/:id',
      //   component: CountryViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.countryView,
      //     title: i18n('metaTitle.viewCountry') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
    ],
  },
];
