//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lodash from 'lodash';

export default {
  name: 'app-list-item-image',
  props: ['value'],

  data() {
    return {
      visible: false,
    }
  },
  computed: {
    isBlank() {
      return !this.value || !this.value.length;
    },
    imgSrc() {
      let src = ''
      if (lodash.isString(this.value)) {
        src = this.value
      } else if (lodash.isArray(this.value)) {
        src = this.value.length && this.value[0].publicUrl ? this.value[0].publicUrl : '/images/avatars/user-avatar.svg'
      } else if (lodash.isObject(this.value)) {
        src = this.value ? this.value.publicUrl : '/images/avatars/user-avatar.svg'
      }

      return src
    }
  },
  methods: {
    mouseOver: function() {
      console.log('mouse over');
      // setTimeout(() => {
      //   this.visible = true;   
      // }, 200) 
    },
    mouseLeave: function() {
      console.log('mouse leave');
      // setTimeout(() => {
      //   this.visible = false;   
      // }, 200)
    },
  }
};
