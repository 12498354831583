import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-country';

export class CountryService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COUNTRY_CREATE($data: CountryInput!) {
          countryCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.countryCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COUNTRY_UPDATE(
          $id: Int!
          $data: CountryInput!
        ) {
          countryUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.countryUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COUNTRY_DESTROY($ids: [String!]!) {
          countryDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.countryDestroy;
  }
  
  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COUNTRY_IMPORT(
          $data: CountryInput!
          $importHash: String!
        ) {
          countryImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.countryImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query COUNTRY_FIND($id: String!) {
          countryFind(id: $id) {
            id
            name {
              en
              ar
            }
            normalized {
              en
              ar
            }
            center {
              latitude
              longitude
            }
            iso2
            iso3
            nameEn
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.countryFind;
  }

  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query COUNTRY_LIST(
          $filter: CountryFilterInput
          $orderBy: String, 
          $pagination: PaginationInput
        ) {
          listCountriesWithPagination(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
                name {
                  en
                  ar
                }
                normalized {
                  en
                  ar
                }
                center {
                  latitude
                  longitude
                }
                iso2
                iso3
                nameEn
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });

    return response.data.listCountriesWithPagination;
  }


  static async listAutocomplete(query, limit, lang) {
    const response = await graphqlClient.query({
      query: gql`
        query COUNTRY_AUTOCOMPLETE(
          $query: String
          $limit: Int
          $lang: LanguageEnum
        ) {
          countryAutocomplete(query: $query, limit: $limit, lang: $lang) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
        lang,
      },
    });

    return response.data.countryAutocomplete;
  }
}
