import walletListStore from '@/modules/wallet/wallet-list-store';
import walletViewStore from '@/modules/wallet/wallet-view-store';

export default {
  namespaced: true,

  modules: {
    list: walletListStore,
    view: walletViewStore,
  },
};
