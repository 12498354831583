import { CityService } from '@/modules/city/city-service';
import HelperClass from '@/shared/helperClass';
import Errors from '@/shared/error/errors';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGINATION = {
  page: 1,
  currentPage: 1,
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'desc',
  orderBy: 'createdAt',
  isFirstPage: true,
  isLastPage: true,
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: INITIAL_PAGINATION,
    sorter: {},
    autocompleteList: [],
    autocompleteLoading: false,
    citiesByCountry: [],

    table: null,
  },

  getters: {
    autocompleteList: (state) => state.autocompleteList || [],
    autocompleteLoading: (state) => state.autocompleteLoading,

    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    citiesByCountry: (state) => state.citiesByCountry || [],
    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = INITIAL_PAGINATION;
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination = payload && payload.keepPagination
        ? state.pagination
        : INITIAL_PAGINATION;
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;

      if (payload.pagination) {
        let currentPage = state.pagination.currentPage
        const action = payload.pagination.action
        switch (action) {
          case 'next':
            currentPage += 1
            break;
          case 'prev':
            currentPage -= 1
            break;
        }

        state.pagination = {
          currentPage: currentPage,
          limit: payload.pagination.limit || INITIAL_PAGE_SIZE,
          sortBy: payload.pagination.sortBy || 'desc',
          orderBy: payload.pagination.orderBy || 'createdAt',
          isFirstPage: payload.pagination.isFirstPage,
          isLastPage: payload.pagination.isLastPage,
        }
      }
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    FETCH_BY_COUNTRY_IDS_STARTED(state) {
      state.loading = true
    },

    FETCH_BY_COUNTRY_IDS_SUCCESS(state, payload) {
      state.loading = false;
      state.citiesByCountry = payload;
    },

    FETCH_BY_COUNTRY_IDS_ERROR(state) {
      state.loading = false;
      state.citiesByCountry = [];
    },

    AUTOCOMPLETE_STARTED(state) {
      state.autocompleteLoading = true;
      state.autocompleteList = [];
    },

    AUTOCOMPLETE_SUCCESS(state, payload) {
      state.autocompleteList = payload;
      state.autocompleteLoading = false;
    },

    AUTOCOMPLETE_ERROR(state) {
      state.autocompleteList = [];
      state.autocompleteLoading = false;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize( { commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doFetch({ commit }, { filter, orderBy, pagination, keepPagination } = {}) {
      try {
        const ORDER_BY = orderBy || 'createdAt'
        const WITH_RELATION = ['country']
        const PAGINATION = pagination || { sortBy: "desc", limit: INITIAL_PAGE_SIZE }

        commit('FETCH_STARTED', { filter, keepPagination });
        const response = await CityService.list(filter, WITH_RELATION, ORDER_BY, PAGINATION);

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          pagination: { 
            ...PAGINATION, 
            orderBy: ORDER_BY,
            isFirstPage: response.pagination ? response.pagination.isFirstPage : true,
            isLastPage: response.pagination ? response.pagination.isLastPage : true,
          },
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchCityAutocomplete({ commit }, values) {
      try {
        debugger
        console.log('values =', values);
        commit('AUTOCOMPLETE_STARTED');
        const lang = HelperClass.isArabic(values.search) ? 'ar' : 'en'
        // if (values.search.length < 2) return
        const response = await CityService.listAutocomplete(values.search, values.limit, lang, values.countryId);
        commit('AUTOCOMPLETE_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('AUTOCOMPLETE_ERROR');
      }
    },

    /**
     * 
     * @param {*}  
     * @param {Object} query 
     * @param {String[]} query.ids 
     * @param {String} query.orderBy 
     */
    async doFetchByCountryIds({ commit }, { ids, orderBy = 'createdAt' }) {
      try {
        const ORDER_BY = orderBy || 'createdAt'

        commit('FETCH_BY_COUNTRY_IDS_STARTED');
        const response = FirebaseRepository.mapCollection(
          await firebase.firestore().collection('city').where('country_id', 'in', ids).orderBy(ORDER_BY, 'desc').get()
        )

        commit('FETCH_BY_COUNTRY_IDS_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_BY_COUNTRY_IDS_ERROR');
      }
    },
  },
};
