import { GuideModel } from '@/modules/guide/guide-model';

const { fields } = GuideModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.guideName,
  fields.city,
  fields.country,
  fields.description,
  fields.guidePictures,
  fields.guideType,
];
