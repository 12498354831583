import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/vueI18n';

const TermsAndConditions = () =>
  import('@/modules/policies/components/terms-and-conditions.vue');
const PrivacyPolicy = () =>
  import('@/modules/policies/components/privacy-policy.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'termsAndConditions',
        path: 'terms-and-conditions',
        component: TermsAndConditions,
        meta: {
          auth: true,
          title: i18n('metaTitle.termsAndConditions')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'privacyPolicy',
        path: 'privacy-policy',
        component: PrivacyPolicy,
        meta: {
          auth: true,
          title: i18n('metaTitle.privacyPolicy')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
