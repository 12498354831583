import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-offer';
// import firebase from 'firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
// import * as securePin from "secure-pin";
// import { storeAsync } from '@/app-module';
// import FirebaseQuery from '@/shared/firebase/firebaseQuery'

export class OfferService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation OFFER_CREATE($data: OfferInput!) {
          offerCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.offerCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation OFFER_UPDATE(
          $id: String!
          $data: OfferInput!
        ) {
          offerUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.offerUpdate;
  }

  static async offerCanBeDeleted(id) {
    return !!(await firebase.firestore().collection('items').where('offerId', '==', id).get()).docs.length
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation OFFER_DESTROY($ids: [String!]!) {
          offerDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.offerDestroy;
  }


  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation OFFER_IMPORT(
          $data: OfferInput!
          $importHash: String!
        ) {
          offerImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.offerImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query OFFER_FIND($id: String!) {
          offerFind(id: $id) {
            id
            image {
              id
              name
              publicUrl
            }
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            price
            percentage
            oldPrice
            isActive
            type 
            status
            rejectionReason
            location {
              latitude
              longitude
            }
            range

            createdBy 
            # {
            #   id
            #   fullName
            #   managerName
            #   email
            #   phoneNumber
            #   avatar {
            #     name
            #     publicUrl
            #   }
            #   avatars {
            #     id
            #     name
            #     publicUrl
            #   } 
            # }
            updatedBy
            #  {
            #   id
            #   fullName
            #   avatars {
            #     id
            #     name
            #     publicUrl
            #   } 
            # }
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.offerFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query OFFER_LIST(
          $filter: OfferFilterInput
          $orderBy: OfferOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          offerList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              image {
                name
                publicUrl
              }
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              price
              percentage
              oldPrice
              isActive
              type 
              status
              rejectionReason
              location {
                latitude
                longitude
              }
              range

              createdBy 
              updatedBy 
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.offerList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query OFFER_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          offerAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.offerAutocomplete;
  }

  // static mapCollection(collection) {
  //   if (collection.empty) {
  //     return [];
  //   }

  //   const list = [];

  //   collection.forEach((document) => {
  //     const item = Object.assign({}, document.data(), {
  //       id: document.id,
  //     });

  //     // this.replaceAllTimestampToDate(item);
  //     list.push(item);
  //   });

  //   return list;
  // }


  // /**
  //  * Counts the number of Pin Code.
  //  *
  //  * @param {Object} filter
  //  */
  // static async pinCodeCount() {
  //   let chain = firebase.firestore().collection('pinCode');
  //   return (await chain.get()).size;
  // }
  // static async pinCodeList() {
  //   const collection = await firebase.firestore().collection('pinCode').get();
  //   return this.mapCollection(collection)
  //   // collection.forEach(async (doc) => {
  //   //   let city = doc.data();
  //   //   city['id'] = doc.id;
  //   //   response.push(city);
  //   // });
  // } 
  // static async pinCodeListByTransaction(t) {
  //   const collection = await t.collection('pinCode').get();
  //   return this.mapCollection(collection)
  // } 

  // static async GenerateAndCreatePinCode() {
  //   try {
  //     let currentUser = storeAsync().getters['auth/currentUser']
  //     await firebase.firestore().runTransaction(async (t) => {
  //       const pinCodeCollection = await this.pinCodeListByTransaction()
  //       let limit = pinCodeCollection.length
  //       let pin 

  //       while(limit < pinCodeCollection.length + 1 ) {
  //         pin = securePin.generatePinSync(8)

  //         if (pinCodeCollection.length) {
  //           if(pinCodeCollection.indexOf(pin) === -1) limit += 1
  //         } else {
  //           limit += 1
  //         }
  //       }  

  //       let docRef = firebase.firestore().doc(`pinCode/${pin}`) 
  //       t.set(docRef, {
  //         id: pin,
  //         // pinCode: pin,
  //         createdBy: currentUser.id,
  //         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //       });
  //     });

  //      console.log('Transaction success!');
  //   } catch (e) {
  //      console.log('Transaction failure:', e);
  //   }
  // }

  // static async pinCodeGenerators(count) {
  //   try {
  //     const pinCodeCollection = await this.pinCodeList()
  //     // let limit = pinCodeCollection.length
  //     let limit = count
  //     let pinCodes = []

  //     while(pinCodes.length < limit ) {
  //       const code = securePin.generatePinSync(8)

  //       if (pinCodeCollection.length) {
  //         if(pinCodeCollection.indexOf(code) === -1) pinCodes.push(code)
  //       } else {
  //         pinCodes.push(code)
  //       }
  //     }  

  //     return pinCodes
  //   } catch (e) {
  //     console.log('Error: ', e);
  //   }
  // }

  // static async pinCodeGenerator() {
  //   try {
  //     const pinCodeCollection = await this.pinCodeList()
  //     let limit = pinCodeCollection.length
  //     let pin 

  //     while(limit < pinCodeCollection.length + 1 ) {
  //       pin = securePin.generatePinSync(8)

  //       if (pinCodeCollection.length) {
  //         if(pinCodeCollection.indexOf(pin) === -1) limit += 1
  //       } else {
  //         limit += 1
  //       }
  //     }  

  //     return pin
  //   } catch (e) {
  //      console.log('Error: ', e);
  //   }
  // }
  // // static async createPinCode(pinCode) {
  // //   let currentUser = storeAsync().getters['auth/currentUser']

  // //   let batch = firebase.firestore().batch();

  // //   let docRef = firebase.firestore().doc(`pinCode/${pinCode}`) 
  // //   batch.set(docRef, {
  // //     id: pinCode,
  // //     // pinCode: pin,
  // //     createdBy: currentUser.id,
  // //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  // //   });

  // //   await batch.commit()
  // // }

  // static async createPinCode(data) {
  //   let currentUser = storeAsync().getters['auth/currentUser']

  //   let batch = firebase.firestore().batch();

  //   let docRef = firebase.firestore().doc(`pinCode/${data.pinCode}`) 
  //   batch.set(docRef, {
  //     id: data.pinCode,
  //     distributorType: data.distributorType,
  //     wholesalerId: data.wholesalerId,
  //     createdBy: currentUser.id,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });

  //   await batch.commit()
  // }

  // static _getRandomInt(min, max) {
  //   return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  // }

}