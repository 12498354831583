import { PlanService } from '@/modules/plan/plan-service';
import Errors from '@/shared/error/errors';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    ppagination: {
      page: 1,
      limit: INITIAL_PAGE_SIZE,
      sortBy: 'desc',
      orderBy: 'createdAt',
      isFirstPage: true,
      isLastPage: true,
    },
    sorter: {},

    table: null,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;

      if (payload.pagination) {
        let currentPage = state.pagination.page
        const action = payload.pagination.action
        switch (action) {
          case 'next':
            currentPage += 1
            break;
          case 'prev':
            currentPage -= 1
            break;
        }

        state.pagination = {
          page: currentPage,
          limit: payload.pagination.limit || INITIAL_PAGE_SIZE,
          sortBy: payload.pagination.sortBy || 'desc',
          orderBy: payload.pagination.orderBy || 'createdAt',
          isFirstPage: payload.pagination.isFirstPage,
          isLastPage: payload.pagination.isLastPage,
        }
      }
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    doChangePagination({ commit, getters, dispatch },  pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetchGroupbyCity');
      // return dispatch('doFetch');
    },

    async doFetch({ commit }, { filter, orderBy, pagination, keepPagination } = {}) {
      try {
        const PAGINATION = pagination || { sortBy: "desc", limit: INITIAL_PAGE_SIZE }
        const ORDER_BY = orderBy || 'createdAt'

        commit('FETCH_STARTED', { filter, keepPagination });
        const response = await PlanService.list(filter, ORDER_BY, PAGINATION);

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          pagination: { 
            ...PAGINATION, 
            orderBy: ORDER_BY,
            isFirstPage: response.pagination ? response.pagination.isFirstPage : true,
            isLastPage: response.pagination ? response.pagination.isLastPage : true,
          },
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchGroupbyCity({ commit, getters }, { 
        filter, 
        keepPagination 
      } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await PlanService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          getters.offset,
        );
        
        //#region [ Group By Start City ID ] ===================================================== //
        let distinctCityId = [...new Set(response.rows.map(el => el.startCityId))]
        console.log('distinctCityId =>> ', distinctCityId)
        const rows = []
        distinctCityId.forEach(cityId => {
          console.log('response.rows =>> ', response.rows)
          console.log('cityId =>> ', cityId)
          const plans = response.rows.filter(el => el.startCityId == cityId)
          console.log('plans =>> ', plans)
          rows.push({
            startCityId: cityId,
            cityName: plans[0].startCity?.name,
            numberOfPlans: plans.length,
            createdAt: plans[0].createdAt,
            plans: plans,
          })
        });        
        console.log('rows =>> ', rows)
        //#endregion =============================================================================== //

        commit('FETCH_SUCCESS', {
          rows: rows,
          count: rows.length,
        });

        // commit('FETCH_SUCCESS', {
        //   rows: response.rows,
        //   count: response.count,
        // });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};
