import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CountryPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.countryRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.countryImport,
    );
    this.countryAutocomplete = permissionChecker.match(
      Permissions.values.countryAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.countryCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.countryEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.countryDestroy,
    );
  }
}
