import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire'
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import iam from '@/modules/iam/iam-module';
import settings from '@/modules/settings/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import place from '@/modules/place/place-module';
import trip from '@/modules/trip/trip-module';
import plan from '@/modules/plan/plan-module';
import notification from '@/modules/notification/notification-module';
import city from '@/modules/city/city-module';
import country from '@/modules/country/country-module';
import guide from '@/modules/guide/guide-module';
import client from '@/modules/client/client-module';
import company from '@/modules/company/company-module';
import transaction from '@/modules/transaction/transaction-module';
import wallet from '@/modules/wallet/wallet-module';
import cycle from '@/modules/cycle/cycle-module';
import invoice from '@/modules/invoice/invoice-module';
import offer from '@/modules/offer/offer-module';
import stores from '@/modules/stores/stores-module';

import review from '@/modules/review/review-module';
import reservation from '@/modules/reservation/reservation-module';
import complaint from '@/modules/complaint/complaint-module';
import request from '@/modules/request/request-module';
import policies from '@/modules/policies/policies-module';


const modules = {
  shared,
  home,
  settings,
  auth,
  iam,
  auditLog,
  layout,
  place,
  notification,
  trip,
  plan,
  city,
  country,
  guide,
  client,
  company,
  transaction,
  wallet,
  cycle,
  invoice,
  offer,
  stores,
  review,
  reservation,
  complaint,
  request,
  policies
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' },
];
// console.log('routes = ', routes);
let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      hash: false,
      routes,
      scrollBehavior: (to) => {
        if (to.hash) {
          return {selector: to.hash}
        } else {
          return { x: 0, y: 0 }
        }
      }
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ 
      modules: buildStores(), 
      mutations: {
        ...vuexfireMutations, // other mutations
      }, 
    });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code
