import { StoresModel } from '@/modules/stores/stores-model';

const { fields } = StoresModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.storeName,
  fields.city,
  fields.country,
  fields.description,
  fields.storePictures,
  fields.storeType,
];
