//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-delete-item-modal',
  props: [
    'visible', 
    'id', 
    'loading', 
    'message', 
    'icon', 
    'storePath'
  ],

  data() {
    return {

    };
  },
  computed: {
    // ...mapGetters({
    //   loading: `${this.storePath}/loading`,
    // })
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    i18n(text) {
      return i18n(text);
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },

    show() {
      this.$refs.dialog.show()
    },
    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide()
    },
    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },
    async onOKClick() {
      await this.doSubmit();
      this.hide() // then hiding dialog
    },
    onCancelClick() {
      // we just need to hide dialog
      this.hide()
    },
  },
};
