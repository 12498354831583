import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-place';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { storeAsync } from '@/app-module';

export class PlaceService {
  static async createOrUpdatePlaceNotification(
    id,
    notifications,
  ) {
    const currentUser = storeAsync().getters[
      'auth/currentUser'
    ];
    let batch = firebase.firestore().batch();

    let docRef = firebase.firestore().doc(`place/${id}`);
    batch.update(docRef, {
      notifications: notifications,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await batch.commit();
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLACE_UPDATE(
          $id: String!
          $data: PlaceInput!
        ) {
          placeUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.placeUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLACE_DESTROY($ids: [String!]!) {
          placeDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.placeDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLACE_CREATE($data: PlaceInput!) {
          placeCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.placeCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLACE_IMPORT(
          $data: PlaceInput!
          $importHash: String!
        ) {
          placeImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.placeImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query PLACE_FIND($id: String!) {
          placeFind(id: $id) {
            id
            placeName {
              en
              ar
            }
            description {
              en
              ar
            }
            countryId
            country {
              id
              name {
                en
                ar
              }
            }
            cityId
            city {
              id
              name {
                en
                ar
              }
            }
            placeType
            placePictures {
              id
              name
              sizeInBytes
              publicUrl
              privateUrl
            }
            visitCount

            address {
              en
              ar
            }
            lat
            lng
            strokeWidth
            radius
            fillColor
            strokeColor
            notifications

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.placeFind;
  }

  static async list(status, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query PLACE_LIST(
          $status: CompanyStatusEnum
          $orderBy: String
          $pagination: PaginationInput
        ) {
          placeList(
            status: $status
            orderBy: $orderBy
            pagination: $pagination
          ) {
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              id
              placeName {
                en
                ar
              }
              description {
                en
                ar
              }
              countryId
              country {
                id
                name {
                  en
                  ar
                }
              }
              cityId
              city {
                id
                name {
                  en
                  ar
                }
              }
              placeType
              placePictures {
                id
                name
                sizeInBytes
                publicUrl
                privateUrl
              }
              visitCount

              address {
                en
                ar
              }
              lat
              lng
              strokeWidth
              radius
              fillColor
              strokeColor
              status
              notifications

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        status,
        orderBy,
        pagination,
      },
    });

    return response.data.placeList;
  }

  static async listPlacesInCity(cityIds) {
    const response = await graphqlClient.query({
      query: gql`
        query placeCityList(
          $filter: PlaceFilterInput
          $limit: Int
          $offset: Int
          $orderBy: PlaceOrderByEnum
        ) {
          placeCityList(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
          ) {
            count
            rows {
              id
              placeName {
                en
                ar
              }
              cityId
              createdAt
            }
          }
        }
      `,

      variables: {
        cityIds,
      },
    });

    return response.data.placeCityList;
  }

  // static async listPlaces() {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //     query PLACE_LIST {
  //       placeList {
  //         count
  //         rows {
  //           id
  //           placeName
  //         }
  //       }
  //     }
  //     `,
  //   });

  //   return response.data.placeList;
  // }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLACE_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          placeAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.placeAutocomplete;
  }
}
