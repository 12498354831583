import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class StoresPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.storeRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.storeImport,
    );
    this.storeAutocomplete = permissionChecker.match(
      Permissions.values.storeAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.storeCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.storeEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.storeDestroy,
    );
  }
}
