//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EventBus } from '@/event-bus';

export default {
  name: 'v-auto-complete',
  
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {},
    options: {
      type: Array,
      default:  () => [],
    },
    'input-class': {
      type: String,
    },
    'char-count': {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
    },
    target: {
      type: String,
    },
    placeholder: {
      type: String,
    }, 
    // EventBus: {
    //   required: false,
    // },
  },
  data() {
    return {
      list: [],
      query: "",
      noResults: false,
      val: '',
      close: true,
    }
  },
  computed: {
    // valueChanged: {
    //   get() {
    //     return this.val;
    //   },
    //   set(newValue) {
    //     this.val = newValue;
    //   },
    // },
    optionsList() {
      return this.query && this.query.length >= this.charCount
        ? this.options
        : []
    },
  },
  mounted() {
    EventBus.$on('resetAutoComplete', this.reset)
    this.query = this.value ? this.value[this.label] : ''
    if (this.options && this.options.length) {
      const option = this.options[0]
      this.query = option.label
    }
  },
  methods: {
    updateValue(value) {
      if (this.target) {
        this.$emit('input', value[this.target]);
      } else {
        this.$emit('input', value);
      }
    },
    OnSearch(input) {
      // debugger
      if(input && input.value == "") {
        this.list = []
      }
    },
    getData() {
      if(!this.multiple) {
        this.updateValue('')
      }
      if (this.query.length >= this.charCount) {
        this.$emit("change", this.query);
        this.close = false
        // this.$nextTick(() => {
        //   this.noResults = this.optionsList.length === 0
        // })
      } else {
        // this.options = []
        this.noResults = false
      }
    },
    setSelectedOption(option) {
      if (this.multiple) {
        this.query = '';
        this.list.push({
          id: option.id, 
          name: option[this.label],
          checked: false,
        })
        this.updateValue(this.list)
        // input_search
        this.close = true;
        this.noResults = false
      } else {
        this.updateValue(option)
        this.query = option[this.label];
        //   this.valueChanged = option[this.label];
        //   this.value = option;
        this.close = true;
        this.noResults = false
      } 
    },
    removeItem(index) {
      this.list.splice(index, 1)
    },
    clearInput() {
      this.query = ''
      if(!this.multiple) {
        this.updateValue('')
      }
    },
    reset() {
      this.query = ''
      this.list = []
      this.noResults = false
      this.val = ''
      this.close = true
      // this.updateValue('')
    },
  },
  watch: {
    optionsList(newVal) {
      console.log(newVal);
      this.noResults = this.optionsList.length === 0 && this.query.length >= this.charCount
    },
    // query(newValue) {
    //     if (!newValue) {
    //         this.list = []
    //     }
    // }
  },  
};
