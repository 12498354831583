import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-request';

export class RequestService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_CREATE($data: RequestInput!) {
          requestCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.requestCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_UPDATE(
          $id: String!
          $data: RequestInput!
        ) {
          requestUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.requestUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_DESTROY($ids: [String!]!) {
          requestDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.requestDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_IMPORT(
          $data: RequestInput!
          $importHash: String!
        ) {
          requestImport(
            data: $data
            importHash: $importHash
          )
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.requestImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query REQUEST_FIND($id: String!) {
          requestFind(id: $id) {
            id
            payerId
            payeeId
            payerType
            payeeType
            amount
            payMethod
            date

            paymentId
            paymentType
            paymentBrand
            currency

            createdBy
            updatedBy
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.requestFind;
  }

  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query REQUEST_LIST(
          $filter: RequestFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          requestList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              requestNumber
              amount
              description
              walletId
              ownerId
              ownerType
              type
              status
              notes
              transactionId

              owner {
                avatar
                name {
                  en
                  ar
                }
                phoneNumber
                email
                type
              }

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });

    return response.data.requestList;
  }

  static async listRequestTransactions(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: RequestTransactionFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          listRequestTransactions(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              type

              id
              payerId
              payeeId
              payerType
              payeeType
              amount
              # payMethod
              # date
              description {
                en
                ar
              }

              paymentId
              # paymentType
              # paymentBrand
              currencyCode

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query REQUEST_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          requestAutocomplete(
            query: $query
            limit: $limit
          ) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.requestAutocomplete;
  }
  static async accept(values) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_ACCEPT(
          $id: String!
          $dueDate: DateTime!
          $notes: String
        ) {
          requestAccept(
            id: $id
            dueDate: $dueDate
            notes: $notes
          )
        }
      `,

      variables: {
        id: values.id,
        dueDate: values.dueDate,
        notes: values.notes,
      },
    });

    return response.data.requestAccept;
  }

  static async close(values) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_CONFIRM(
          $id: String!
          $data: ConfirmRequestInput!
        ) {
          requestConfirm(id: $id, data: $data)
        }
      `,

      variables: {
        id: values.id,
        data: values.data,
      },
    });

    return response.data.requestConfirm;
  }
  static async reject(values) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_REJECT(
          $id: String!
          $notes: String
        ) {
          requestReject(
            id: $id
            notes: $notes
          )
        }
      `,

      variables: {
        id: values.id,
        notes: values.notes,
      },
    });

    return response.data.requestReject;
  }
}
