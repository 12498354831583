import formatDateFilter from '@/shared/filters/format-date-filter';
import formatDatetimeFilter from '@/shared/filters/format-datetime-filter';
import autofocusMixin from '@/shared/mixins/autofocus-mixin';
import I18n from '@/shared/i18n/i18n.vue';
import I18nFlags from '@/shared/i18n/i18n-flags.vue';
import I18nToggle from '@/shared/i18n/i18n-toggle.vue';
import I18nSelect from '@/shared/i18n/i18n-select.vue';
import ImageUpload from '@/shared/form/image-upload.vue';
import ImageUploadV2 from '@/shared/form/image-upload-v2.vue';
import ImageUploadCustom from '@/shared/form/image-upload-custom.vue';
import FileUpload from '@/shared/form/file-upload.vue';
import ImageCarousel from '@/shared/view/image-carousel.vue';
import ViewItemText from '@/shared/view/view-item-text.vue';
import ViewItemImage from '@/shared/view/view-item-image.vue';
import ViewItemFile from '@/shared/view/view-item-file.vue';
import ViewItemCustom from '@/shared/view/view-item-custom.vue';
import ListItemImage from '@/shared/list/list-item-image.vue';
import ListItemFile from '@/shared/list/list-item-file.vue';
import Importer from '@/shared/importer/components/importer.vue';
import NumberRangeInput from '@/shared/form/number-range-input.vue';
import AutocompleteOneInput from '@/shared/form/autocomplete-one-input.vue';
import AutocompleteManyInput from '@/shared/form/autocomplete-many-input.vue';
import ViewItemRelationToOne from '@/shared/view/view-item-relation-to-one.vue';
import ViewItemRelationToMany from '@/shared/view/view-item-relation-to-many.vue';
import ListItemRelationToOne from '@/shared/list/list-item-relation-to-one.vue';
import ListItemRelationToMany from '@/shared/list/list-item-relation-to-many.vue';
import ImagePreview from '@/shared/view/image-preview.vue';


import DeleteItemModal from '@/shared/components/delete-item-modal.vue';
import DeleteItemPage from '@/shared/components/delete-item-page.vue';
import DisplayMessageModal from '@/shared/components/display-message-modal.vue';
import CreatedSuccessfullyModal from '@/shared/components/created-successfully-modal.vue';
import ShowDateTime from '@/shared/components/show-date-time.vue';
import ReviewListCard from '@/shared/components/review/review-list-card.vue';
import ReviewCard from '@/shared/components/review/review-card.vue';
import LoadingReviewCard from '@/shared/components/review/loading-review-card.vue';
import ToggleButton from '@/shared/components/toggle-button.vue';
import ChangeUserStatusButton from '@/shared/components/change-user-status-button.vue';
import InnerLoading from '@/shared/components/inner-loading.vue';
import AutoComplete from '@/shared/components/auto-complete.vue';
// import Chart from '@/shared/components/chart/Main.vue';



export default {
  components: [
    I18n,
    I18nFlags,
    I18nSelect,
    I18nToggle,
    ImageUpload,
    ImageUploadV2,
    ImageUploadCustom,
    FileUpload,
    ImageCarousel,
    ViewItemText,
    ViewItemImage,
    ViewItemFile,
    ViewItemCustom,
    Importer,
    ListItemImage,
    ListItemFile,
    NumberRangeInput,
    AutocompleteOneInput,
    AutocompleteManyInput,
    ViewItemRelationToOne,
    ViewItemRelationToMany,
    ListItemRelationToOne,
    ListItemRelationToMany,
    ImagePreview,

    DeleteItemModal,
    DeleteItemPage,
    DisplayMessageModal,    
    CreatedSuccessfullyModal,
    ShowDateTime,
    ReviewListCard,
    ReviewCard,
    LoadingReviewCard,
    ToggleButton,
    ChangeUserStatusButton,
    InnerLoading,
    AutoComplete,
    // Chart,
  ],

  filters: [formatDateFilter, formatDatetimeFilter],
  mixins: [autofocusMixin],
};
