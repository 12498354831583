import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-cycle';

export class CycleService {
//#region [ CRED Operations ]
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CYCLE_CREATE($data: CycleInput!) {
          cycleCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.cycleCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CYCLE_UPDATE(
          $id: String!
          $data: CycleInput!
        ) {
          cycleUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.cycleUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CYCLE_DESTROY($ids: [String!]!) {
          cycleDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.cycleDestroy;
  }
//#endregion

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CYCLE_IMPORT(
          $data: CycleInput!
          $importHash: String!
        ) {
          cycleImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.cycleImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CYCLE_FIND($id: String!) {
          cycleFind(id: $id) {
            id
            payerId
            payeeId
            payerType
            payeeType
            amount
            payMethod
            date

            paymentId
            paymentType
            paymentBrand
            currency
            
            createdBy
            updatedBy
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.cycleFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query CYCLE_LIST(
          $filter: CycleFilterInput
          $orderBy: CycleOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          cycleList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              payerId
              payeeId
              payerType
              payeeType
              amount
              payMethod
              date

              paymentId
              paymentType
              paymentBrand
              currency
              
              createdBy
              updatedBy
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.cycleList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query CYCLE_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          cycleAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.cycleAutocomplete;
  }
}
