//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
// import { i18n } from "@/i18n";
// import { getLanguageCode } from '@/i18n';


export default {
  name: 'app-layout',
  
  data() {
    return {
      drawer: false,
      left: false,
      miniState: false,
    };
  },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    asideWidth() {
      if (this.is_screen_md) {
        // return 230;
        return 250;
      }
      return 260;
    },
    // currentBrowser() {
    //   return localStorage.getItem('browser')
    // }
  },
  mounted() {},
  methods: {
    ...mapActions({
      doRefreshCurrentUser: 'auth/doRefreshCurrentUser',
      // setAccountType:'layout/setAccountType',
      // setCurrentUserListener: 'layout/setCurrentUserListener'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    toggleMenu() {
      this.drawer = !this.drawer
    },
    drawerClick (e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation()
      }
    },
  },
};
