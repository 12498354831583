import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-trip';

export class TripService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation TRIP_UPDATE(
          $id: String!
          $data: tripInput!
        ) {
          tripUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.tripUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation TRIP_DESTROY($ids: [String!]!) {
          tripDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.tripDestroy;
  }

  static async find(id, withRelation) {
    const response = await graphqlClient.query({
      query: gql `
        query TRIP_FIND($id: String!, $withRelation: [TripRelationEnum]) {
          findTripDetailsById(id: $id, withRelation: $withRelation) {
            id
            tripNumber
            name {
              en
              ar
            }
            planId
            tripTime
            status
            ownerType
            paymentMethod
            type
            reservationType
            currentPlace
            currentDay
            disabled
            tripEndTime
            capacity {
              min
              max
            }
            price {
              private
              group
              extra
            }
            vatPercentage
            feesPercentage
            options {
              details {
                en
                ar
              }
              price 
            }
            cancelationReason
            
            guidesId
            captainId
            isCaptain
            companyId
            startCityId
            location {
              latitude
              longitude
            }
            image {
              name
              publicUrl
            } 
            reservationCount
            reservation {
              id
              clientId
              tourGuideId
              tripId
              optionIndex
              qrCode
              isArrived
              numberOfPeople
              status
              paymentMethod
              members {
                name
                phoneNumber
              }
              client {
                id
                fullName
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
            plan {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              type
              numberOfDays
              enabled
              programs {
                places {
                  place {
                    id
                    placeName {
                      en
                      ar
                    }
                    description {
                      en
                      ar
                    }
                    countryId
                    country {
                      id
                      name {
                        en
                        ar
                      }
                    }
                    cityId
                    city {
                      id
                      country_id
                      name  {
                        en
                        ar
                      }
                    }
                    placeType
                    placePictures {
                      name
                      publicUrl
                    }
                    visitCount
                    address {
                      en
                      ar
                    }
                    lat
                    lng
                    strokeWidth
                    radius
                    fillColor
                    strokeColor
                    notifications
                    status
                    rejectionReason
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                  id
                  selectedImage
                  averageTime
                }
              }
                            
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
            startCity {
              id
              country_id
              name {
                en
                ar
              }
              center {
                latitude
                longitude
              }
            }
            owner {
              id 
              avatar 
              name {
                en
                ar
              }
              rate 
            }
            guides {
              id
              fullName
            }

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
        withRelation,
      },
    });

    return response.data.findTripDetailsById;
  }

  static async list(filter, withRelation, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql `
        query LIST_IN_PROGRESS_TRIPS(
          $filter: TripCustomFilterInput,
          $withRelation: [TripRelationEnum]
          $orderBy: String,
          $pagination: PaginationInput
        ) {
          listTrips(filter: $filter, withRelation: $withRelation, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              tripNumber
              name {
                en
                ar
              }
              planId
              tripTime
              status
              ownerType
              paymentMethod
              type
              reservationType
              currentPlace
              currentDay
              disabled
              tripEndTime
              capacity {
                min
                max
              }
              price {
                private
                group
                extra
              }
              vatPercentage
              feesPercentage
              options {
                details {
                  en
                  ar
                }
                price 
              }
              cancelationReason
              
              guidesId
              captainId
              isCaptain
              companyId
              startCityId
              location {
                latitude
                longitude
              }
              image {
                name
                publicUrl
              } 
              reservationCount
              reservation {
                id
                clientId
                tourGuideId
                tripId
                optionIndex
                qrCode
                isArrived
                numberOfPeople
                status
                paymentMethod
                members {
                  name
                  phoneNumber
                }
                client {
                  id
                  fullName
                }
                createdBy
                updatedBy
                createdAt
                updatedAt
              }
              plan {
                id
                name {
                  en
                  ar
                }
                description {
                  en
                  ar
                }
                type
                numberOfDays
                enabled
                programs {
                  places {
                    place {
                      id
                      placeName {
                        en
                        ar
                      }
                      description {
                        en
                        ar
                      }
                      countryId
                      country {
                        id
                        name {
                          en
                          ar
                        }
                      }
                      cityId
                      city {
                        id
                        country_id
                        name  {
                          en
                          ar
                        }
                      }
                      placeType
                      placePictures {
                        name
                        publicUrl
                      }
                      visitCount
                      address {
                        en
                        ar
                      }
                      lat
                      lng
                      strokeWidth
                      radius
                      fillColor
                      strokeColor
                      notifications
                      status
                      rejectionReason
                      createdBy
                      updatedBy
                      createdAt
                      updatedAt
                    }
                    id
                    selectedImage
                    averageTime
                  }
                }
                              
                createdBy
                updatedBy
                createdAt
                updatedAt
              }
              startCity {
                id
                country_id
                name {
                  en
                  ar
                }
                center {
                  latitude
                  longitude
                }
              }
              owner {
                id 
                avatar 
                name {
                  en
                  ar
                }
                rate 
              }
              guides {
                id
                fullName
              }

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        withRelation,
        orderBy,
        pagination
      },
    });

    return response.data.listTrips;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query TRIP_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          tripAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.tripAutocomplete;
  }
}