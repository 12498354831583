import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-auth';
import firebaseInit from '@/shared/firebase/firebase-init';

export default class AuthService {
  static init() {
    return firebaseInit();
  }

  static onAuthStateChanged(
    callbackSuccess,
    callbackError,
  ) {
    return firebase
      .auth()
      .onAuthStateChanged(callbackSuccess, callbackError);
  }

  static async sendEmailVerification(authenticationUser) {
    if (await this.isEmailConfigured()) {
      return this.sendEmailVerificationFromBackend();
    }

    return this.sendEmailVerificationFromClient(
      authenticationUser,
    );
  }

  static async sendEmailVerificationFromBackend() {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL {
          authSendEmailAddressVerificationEmail
        }
      `,
    });

    return response.data
      .authSendEmailAddressVerificationEmail;
  }

  static async sendEmailVerificationFromClient(
    authenticationUser,
  ) {
    return authenticationUser.sendEmailVerification();
  }

  static async sendPasswordResetEmail(email) {
    const isEmailConfigured = await this.isEmailConfigured()
    console.log(isEmailConfigured);
    if (isEmailConfigured) {
      return this.sendPasswordResetEmailFromBackend(email);
    }
    debugger
    return this.sendPasswordResetEmailFromClient(email);
  }

  static async sendPasswordResetEmailFromBackend(email) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_PASSWORD_RESET_EMAIL(
          $email: String!
        ) {
          authSendPasswordResetEmail(email: $email)
        }
      `,
      variables: {
        email,
      },
    });
    debugger
    return response.data.authSendPasswordResetEmail;
  }

  static async sendPasswordResetEmailFromClient(email) {
    console.log(`sendPasswordResetEmailFromClient`);
    debugger
    firebase.auth().sendPasswordResetEmail(email);
  }

  static async registerWithEmailAndPassword(
    email,
    password,
  ) {
    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    this.sendEmailVerification(credentials.user);
    return credentials.user;
  }

  static async signinWithSocial(
    provider,
    rememberMe = false,
  ) {
    
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    const providers = {
      google: firebase.auth.GoogleAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
    };

    return firebase
      .auth()
      .signInWithPopup(new providers[provider]());


  }

  static async signinWithEmailAndPassword(
    email,
    password,
    rememberMe = false,
  ) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  static async fetchMe() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authMe {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            countryCode
            accountType
            lang
            email
            roles
            avatars {
              id
              name
              publicUrl
            }
          }
        }
      `,
    });
    
    return response.data.authMe;
  }

  static async isEmailConfigured() {
    debugger
    const response = await graphqlClient.query({
      query: gql`
        {
          authIsEmailConfigured
        }
      `,
    });

    return response.data.authIsEmailConfigured;
  }

  static async reauthenticateWithStorageToken() {
    try {
      // //;
      let token = localStorage.getItem('token');
      // console.log('token = ', token);
      if (!token) {
        const response = await graphqlClient.query({
          query: gql`
            {
              authStorageToken
            }
          `,
        });
        token = response.data.authStorageToken;
        // console.log('token = ', token);

        localStorage.setItem(
          'token',
          this.encryptString(token, 'secret token'),
        );
      } else {
        token = this.decryptString(token, 'secret token');
      }
      return firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      console.error(error);
    }
  }

  static signout() {
    return firebase.auth().signOut();
  }

  static async updateProfile(
    firstName,
    lastName,
    phoneNumber,
    countryCode,
    lang,
    avatars,
    accountType
   
  ) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_UPDATE_PROFILE(
          $profile: UserProfileInput!
        ) {
          authUpdateProfile(profile: $profile)
        }
      `,

      variables: {
        profile: {
          firstName,
          lastName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType
          
        },
      },
    });

    return response.data.authUpdateProfile;
  }


  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  //#region [  ]
  static decryption(cipherData, key) {
    var CryptoJS = require('crypto-js');
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      cipherData.toString(),
      key,
    );
    var decryptedData = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8),
    );
    return decryptedData;
  }

  static decryptString(ciphertext, key) {
    const CryptoJS = require('crypto-js');
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      ciphertext.toString(),
      key,
    );
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
 
 static encryptString(message, key) {
    const CryptoJS = require('crypto-js');
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, key);
    return ciphertext;
  }

  static encryption(data, key) {
    const CryptoJS = require('crypto-js');
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key,
    );
    return ciphertext;
  }
  //#endregion
}
