import { PlanService } from '@/modules/plan/plan-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    changeStatusLoading: false,
    record: null,
    planData: {}
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    changeStatusLoading: (state) => !!state.changeStatusLoading,
    planData: (state) => state.planData
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    CHANGE_STATUS_STARTED(state) {
      state.changeStatusLoading = true;
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.changeStatusLoading = false;
    },
    CHANGE_STATUS_ERROR(state) {
      state.changeStatusLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await PlanService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/plan');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await PlanService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.plan.create.success'));
        routerAsync().push('/plan');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await PlanService.update(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.plan.update.success'));
        routerAsync().push('/plan');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doToggleStatus({ commit, dispatch}, { id, status }) {
      commit('CHANGE_STATUS_STARTED');
      if (status) {
        dispatch('doEnable', id)
      } else {
        dispatch('doDisable', id)
      }
    },

    async doDisable({ commit }, id) {
      try {
        commit('CHANGE_STATUS_STARTED');
        await PlanService.disable(id)
        commit('CHANGE_STATUS_SUCCESS');
        Message.success(i18n('entities.plan.disable.success'));

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_ERROR');

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      }
    },

    async doEnable({ commit }, id) {
      try {
        commit('CHANGE_STATUS_STARTED');
        await PlanService.enable(id)
        commit('CHANGE_STATUS_SUCCESS');
        Message.success(i18n('entities.plan.enable.success'));

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_ERROR');

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      }
    },

  },
};