import { i18n } from '@/vueI18n';
// import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
// import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
// import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.store.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.store.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  authenticationUid: new StringField('authenticationUid', label('authenticationUid'), {}),
  avatar: new ImagesField('avatar', label('avatar'), 
    (id) => `/users/${id}`,
    { max: 1 },
  ),
  fullName: new StringField('fullName', label('fullName'), {}),
  managerName: new StringField('managerName', label('managerName'), {}),
  email: new StringField('email', label('email'), { required: true, max: 255 }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    matches: /^[0-9]/,
    max: 24,
  }),
  countryCode: new StringField('countryCode', label('countryCode'), {
    matches: /^[0-9]/,
    max: 24,
  }),
  accountType: new EnumeratorField('accountType', label('accountType'), [
    { value: 'store', label: enumeratorLabel('accountType', 'store') },
  ],{}),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabledAsStatus: new BooleanField('disabled', label('status'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  lang: new StringField('lang', label('lang'), {}),

  taxCard: new ImagesField('taxCard', label('taxCard'), 
    (id) => `/users/${id}`,
    { max: 1 },
  ),
  commercialRegister: new StringField('commercialRegister', label('commercialRegister'), {}),
  companyOwnerName: new StringField('companyOwnerName', label('companyOwnerName'), {}),
  webLink: new StringField('webLink', label('webLink'), {}),
  twitterLink: new StringField('twitterLink', label('twitterLink'), {}),
  facebookLink: new StringField('facebookLink', label('facebookLink'), {}),
  instagramLink: new StringField('instagramLink', label('instagramLink'), {}),
  description: new StringField('description', label('description'), {}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending') },
    { value: 'accepted', label: enumeratorLabel('status', 'accepted') },
    { value: 'rejected', label: enumeratorLabel('status', 'rejected') },
  ],{}),
  rejectionReason: new StringField('rejectionReason', label('rejectionReason'), {}),
  deviceTokens: new JsonField('deviceTokens', label('deviceTokens'), {}),
  notifications: new StringArrayField('notifications', label('notifications'), {}),

  placeType: new StringField('placeType', label('placeType'), {}),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class StoresModel extends GenericModel {
  static get fields() {
    return fields;
  }
}