import tripListStore from '@/modules/trip/trip-list-store';
import tripViewStore from '@/modules/trip/trip-view-store';
import tripDestroyStore from '@/modules/trip/trip-destroy-store';
// import tripImporterStore from '@/modules/trip/trip-importer-store';
// import tripFormStore from '@/modules/trip/trip-form-store';

// import reservationListStore from '@/modules/trip/reservation-list-store';

export default {
  namespaced: true,

  modules: {
    list: tripListStore,
    view: tripViewStore,
    destroy: tripDestroyStore,
    // form: tripFormStore,
    // importer: tripImporterStore,
    // 'reservation/list': reservationListStore,
  },
};
