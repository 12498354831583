import transactionListStore from '@/modules/transaction/transaction-list-store';
import transactionViewStore from '@/modules/transaction/transaction-view-store';

export default {
  namespaced: true,

  modules: {
    list: transactionListStore,
    view: transactionViewStore,
  },
};
