//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';


export default {
  name: 'app-change-user-status-button',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    labelEnableText: {
      type: String,
      default: 'Enable',
    },

    labelDisableText: {
      type: String,
      default: 'Disable',
    },
    id: {
      type: String,
      default: 'primary',
    },
    defaultState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 'income',
      model: null,
      currentState: this.defaultState,
    };
  },
  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState);
    },
  },
  computed: {
    ...mapGetters({
      loading: 'iam/view/loading',
    }),
    
    // currentState() {
    //     return this.defaultState;
    // },
    isActive() {
      return this.currentState;
    },
    enableText() {
      return this.labelEnableText;
    },
    disabledText() {
      return this.labelDisableText;
    },
    checkedValue: {
      get() {
        return this.currentState;
      },
      set(newValue) {
        this.currentState = newValue;
        this.$emit('change', newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus',
    }),

  },
};
