import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ClientListPage = () =>
  import('@/modules/client/components/client-list-page.vue');
const ClientViewPage = () =>
  import('@/modules/client/components/client-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'client',
        path: '/client',
        component: ClientListPage,
        meta: {
          auth: true,
          permission: Permissions.values.clientView,
          title: i18n('metaTitle.client')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'clientView',
        path: '/client/:id',
        props: true,
        component: ClientViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.clientView,
          title: i18n('metaTitle.viewClient') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
