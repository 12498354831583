import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const vueI18n = new VueI18n({
  locale: localStorage.getItem('language') || process.env.VUE_APP_I18N_LOCALE || 'ar',
  fallbackLocale: localStorage.getItem('language') || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
  messages: loadLocaleMessages()
})

export default vueI18n

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (
      match,
      number,
    ) {
      return typeof args[number] != 'undefined' ?
        args[number] :
        match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function i18n(key, ...args) {
  // return vueI18n.t(key, args);

  // const message = _get(getLanguage().dictionary, key);
  const message = vueI18n.t(key, args);

  if (!message) {
    return key;
  }

  return format(message, args);
}