import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import { IamService } from '@/modules/iam/iam-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    record: null,
    owner: null,
    loading: false,
    statusLoading: false,
  },

  getters: {
    record: (state) => state.record,
    owner: (state) => state.owner,
    loading: (state) => !!state.loading,
    statusLoading: (state) => !!state.statusLoading,
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    VUEXFIRE_FIND_SUCCESS(state) {
      state.loading = false;
    },
    VUEXFIRE_FIND_OWNER_SUCCESS(state, payload) {
      if (lodash.isArray(payload)) {
        state.record = payload.length ? payload[0] : null
      } else {
        state.record = payload
      }
      state.loading = false;
    },

    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true;
    },

    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false;
    },

    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
  },

  actions: {
    doFindByVuexfire: firestoreAction(async (context, id) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const UserQuery = db.collection('user').doc(id)
          
        await context.bindFirestoreRef(
          'record',
          UserQuery,
          { serialize: customSerializer }
        )
        
        context.commit('VUEXFIRE_FIND_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/admins');
      }
    }),

    doFindCompanyOwner: firestoreAction(async (context, companyId) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          context.commit('VUEXFIRE_FIND_OWNER_SUCCESS', data);
          return data
        }

        const db = firebase.firestore()
        const UserQuery = db.collection('user')
          .where("roles", "array-contains", "owner")
          .where("companyId", "==", companyId)
          .limit(1)
          
        await context.bindFirestoreRef(
          'owner',
          UserQuery,
          { serialize: customSerializer }
        )
        // .then((users) => {
        //   debugger
        //   context.commit('VUEXFIRE_FIND_OWNER_SUCCESS', users);
        // })
        
        // context.commit('VUEXFIRE_FIND_OWNER_SUCCESS', context.getters.owner);
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/admins');
      }
    }),

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await IamService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/admins');
      }
    },

    async doToggleStatus({ getters, commit, dispatch }) {
      try {
        const record = getters.record;

        commit('CHANGE_STATUS_STARTED');

        const isEnabling = !!record.disabled;

        if (isEnabling) {
          await IamService.enable([record.id]);
        } else {
          await IamService.disable([record.id]);
        }

        if (isEnabling) {
          Message.success(i18n('iam.doEnableSuccess'));
        } else {
          Message.success(i18n('iam.doDisableSuccess'));
        }

        dispatch('doFind', record.id);
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_ERROR');
      }
    },

    async doToggleUserAccountStatus(context, id) {
      try {
        // debugger
        
        const db = firebase.firestore();
        const doc = await db.collection('user').doc(id).get();
        const record = FirebaseRepository.mapDocument(doc)

        context.commit('CHANGE_STATUS_STARTED');

        const isEnabling = !!record.disabled;

        if (isEnabling) {
          await IamService.enable([id]);
        } else {
          await IamService.disable([id]);
        }

        if (isEnabling) {
          Message.success(i18n('iam.doEnableSuccess'));
        } else {
          Message.success(i18n('iam.doDisableSuccess'));
        }

        context.commit('CHANGE_STATUS_SUCCESS');
        // context.dispatch('doFind', record.id);
      } catch (error) {
        Errors.handle(error);
        context.commit('CHANGE_STATUS_ERROR');
      }
    },
  },
};
