import _get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/ar';
import { setLocale as setYupLocale } from 'yup';
import en from '@/i18n/en';
// import elementUIEn from 'element-ui/lib/locale/lang/en';
import quasarEn from 'quasar/lang/en-us'
import quasarAr from 'quasar/lang/ar'

import ar from '@/i18n/ar';
import service from '@/modules/auth/auth-service';
// import { routerAsync } from '@/app-module';
// const store = routerAsync()
// import elementUIAr from 'element-ui/lib/locale/lang/ar'
// import flag from '../plugins/RTLPlugin'
// import elementUIPtBR from 'element-ui/lib/locale/lang/pt-br';


let currentLanguageCode = null;

const languages = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/24/englishLang.svg',
    secondFlag: '/images/flags/24/englishRtl.svg',
    dictionary: en,
    elementUI: quasarEn,
  },
  ar: {
    id: 'ar',
    label: 'العربية',
    flag: '/images/flags/24/arabicLang.svg',
    secondFlag: '/images/flags/24/arabicRtl.svg',
    dictionary: ar,
    elementUI: quasarAr,
  },
};

async function init() {
  // if ('serviceWorker' in navigator ) {
  //   window.addEventListener('load', () => {
  //     navigator.serviceWorker
  //       .register('/sw.js')
  //       .then((registration) => {
  //         console.log(
  //           `Service Worker registered! Scope: ${registration.scope}`,
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(
  //           `Service Worker registration failed: ${err}`,
  //         );
  //       });
  //   });
  //   // window.process = { env: { NODE_ENV: 'production' } };
  // }

  currentLanguageCode = localStorage.getItem('language') || 'en';


  // let firstName ='' ;
  // let lastName ='';
  // let phoneNumber ='';
  // let countryCode = ''
  let lang = currentLanguageCode;
  // let avatars = {};

  // if(localStorage.getItem('language'))
  // {}

  let currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    let user = service.decryption(currentUser, 'secret-c-u');
    // console.log(user)

    user.lang = lang;

    localStorage.setItem('currentUser', service.encryption(user, 'secret-c-u'));
  }


  setLanguageCode(lang);
}

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (
      match,
      number,
    ) {
      return typeof args[number] != 'undefined' ?
        args[number] :
        match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getElementUILanguage() {
  return getLanguage().elementUI;
}

export function getLanguageCode() {
  if (!currentLanguageCode) {
    init();
  }

  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  moment.locale(arg);
  // if(arg == 'ar')
  //    this.$rtl.enableRTL();
  // }
  currentLanguageCode = arg
  localStorage.setItem('language', arg);
  // store.dispatch('layout/changeCurrentLanguageCode', arg);

  if (getLanguage().dictionary.validation) {
    setYupLocale(getLanguage().dictionary.validation);
  }
}

export function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key);
  return !!message;
}

// import i18n from '@/vueI18n'
export function i18n(key, ...args) {
  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
  // return i18n.t(key, args);
}