import { RequestService } from '@/modules/request/request-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    record: null,
    findLoading: false,
    saveLoading: false,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await RequestService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/request');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await RequestService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.request.update.success'));
        routerAsync().push('/request');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doAccept({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await RequestService.accept(values);
        Message.success(i18n('entities.request.accept.success'));
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doReject({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await RequestService.reject(values);
        Message.success(i18n('entities.request.reject.success'));
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doClose({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await RequestService.close(values);
        Message.success(i18n('entities.request.close.success'));
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
