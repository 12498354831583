import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const WalletListPage = () =>
  import('@/modules/wallet/components/wallet-list-page.vue');
const WalletViewPage = () =>
  import('@/modules/wallet/components/wallet-view-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'wallet',
        path: '/wallet',
        component: WalletListPage,
        meta: {
          auth: true,
          permission: Permissions.values.walletView,
          title: i18n('metaTitle.wallet')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'walletView',
        path: '/wallet/:id',
        component: WalletViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.walletView,
          title: i18n('metaTitle.viewWallet') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
