import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const PlanListPage = () =>
  import('@/modules/plan/components/plan-list-page.vue');
const PlanFormPage = () =>
  import('@/modules/plan/components/plan-form-page.vue');
const PlanEditPage = () =>
  import('@/modules/plan/components/plan-edit-page.vue');
const PlanReorderPage = () =>
  import('@/modules/plan/components/plan-reorder-page.vue');

const PlanViewPage = () =>
  import('@/modules/plan/components/plan-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'plan',
        path: '/plan',
        component: PlanListPage,
        meta: {
          auth: true,
          permission: Permissions.values.planView,
          title: i18n('metaTitle.plan')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'planNew',
        path: '/plan/new',
        component: PlanFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.planCreate,
          title: i18n('metaTitle.newPlan') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'planEdit',
        path: '/plan/:id/edit',
        component: PlanEditPage,
        meta: {
          auth: true,
          permission: Permissions.values.planEdit,          
          title: i18n('metaTitle.editPlan') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'planReorder',
        path: '/plan/:id/reorder',
        component: PlanReorderPage,
        meta: {
          auth: true,
          permission: Permissions.values.planEdit,          
          title: i18n('metaTitle.planReorder') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'planView',
        path: '/plan/:id',
        component: PlanViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.planView,
          title: i18n('metaTitle.viewPlan') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
