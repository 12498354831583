import { ClientModel } from '@/modules/client/client-model';

const { fields } = ClientModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.clientName,
  fields.city,
  fields.country,
  fields.description,
  fields.clientPictures,
  fields.clientType,
];
