// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.guide.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.guide.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  avatar: new StringField('avatar', label('avatar'), {}),
  fullName: new StringField('fullName', label('fullName'), {}),
  email: new StringField('email', label('email'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  accountType: new EnumeratorField('accountType', label('accountType'), [
    { value: 'guideFreelance', label: enumeratorLabel('accountType', 'freelance') },
    { value: 'guideCompany', label: enumeratorLabel('accountType', 'company') },
  ],{}),
  rate: new DecimalField('rate', label('rate'), {}),
  pricePerHour: new IntegerField('pricePerHour', label('pricePerHour'), {}),
  isMale: new BooleanField('isMale', label('gender'), {
    yesLabel: i18n(`entities.guide.fields.male`),
    noLabel: i18n(`entities.guide.fields.female`),
  }),
  availableNow: new BooleanField('availableNow', label('availableNow'), {
    yesLabel: i18n('common.available'),
    noLabel: i18n('common.unavailable'),
  }),
  languages: new StringArrayField('languages', label('languages'), {}),
  availableDays: new StringArrayField('availableDays', label('availableDays'), {}),
  reviews: new StringArrayField('reviews', label('reviews'), {}),
  currency: new StringField('currency', label('currency'), {}),
  capacity: new StringField('capacity', label('capacity'), {}),
    
  location: new JsonField('address', label('address'), {}),
  lat: new DecimalField('lat', label('lat'), {
    "required": true,
    // "scale": 2,
    "min": -90,
    "max": 90
  }),
  lng: new DecimalField('lng', label('lng'), {
    "required": true,
    // "scale": 2,
    "min": -180,
    "max": 180
  }),
  companyId: new StringField('companyId', label('companyId'), {}),
  branchId: new StringField('branchId', label('branchId'), {}),
  wallet: new DecimalField('wallet', label('wallet'), {}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending') },
    { value: 'accepted', label: enumeratorLabel('status', 'accepted') },
    { value: 'rejected', label: enumeratorLabel('status', 'rejected') },
  ],{}),
  rejectionReason: new StringField('rejectionReason', label('rejectionReason'), {}),

  disabledAsStatus: new BooleanField('disabled', label('status'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class GuideModel extends GenericModel {
  static get fields() {
    return fields;
  }
}