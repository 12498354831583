//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { PlacePermissions } from '@/modules/place/place-permissions';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { CityPermissions } from '@/modules/city/city-permissions';
import { CountryPermissions } from '@/modules/country/country-permissions';
import { GuidePermissions } from '@/modules/guide/guide-permissions';
import { StoresPermissions } from '@/modules/stores/stores-permissions';
import { OfferPermissions } from '@/modules/offer/offer-permissions';

// import { i18n } from '@/vueI18n';
import i18n from '@/vueI18n'
import MenuItem from '@/modules/layout/components/menu-item.vue';

export default {
  name: 'app-menu',

  components: {
    [MenuItem.name]: MenuItem,
  },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',

      newComplaintCount: 'complaint/newComplaintCount',
      newRequestsCount: 'request/list/newRequestsCount',
    }),
    hasPermissionToSettings() {
      return new SettingsPermissions(this.currentUser).edit;
    },
    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).read;
    },
    hasPermissionToIam() {
      return new IamPermissions(this.currentUser).read;
    },
    hasPermissionToPlace() {
      return new PlacePermissions(this.currentUser).read;
    },
    hasPermissionToNotification() {
      return new NotificationPermissions(this.currentUser).read;
    },
    hasPermissionToPlan() {
      return new PlanPermissions(this.currentUser).read;
    },
    hasPermissionToCity() {
      return new CityPermissions(this.currentUser).read;
    },
    hasPermissionToCountry() {
      return new CountryPermissions(this.currentUser).read;
    },
    hasPermissionToGuide() {
      return new GuidePermissions(this.currentUser).read;
    },
    hasPermissionToStores() {
      return new StoresPermissions(this.currentUser).read;
    },
    hasPermissionToOffer() {
      return new OfferPermissions(this.currentUser).read;
    },
    asideWidth() {
      if (!this.collapsed) {
        return '200px';
      }

      return '0px';
    },
    language() {
      return i18n.locale
    },
    isOpened() {
      return (type) => {
        const routePath = this.$route.path;
        if (type == 'users') {
          const active = 
            routePath === '/admins' || routePath.startsWith('/admins' + '/') ||
            routePath === '/client' || routePath.startsWith('/client' + '/') || 
            routePath === '/guide' || routePath.startsWith('/guide' + '/') || 
            routePath === '/company' || routePath.startsWith('/company' + '/') || 
            routePath === '/store' || routePath.startsWith('/store' + '/') 
          return active ? 'users' : null

        } else if (type == 'trips') {
          const active = 
            routePath === '/plan' || routePath.startsWith('/plan' + '/') ||
            routePath === '/trip' || routePath.startsWith('/trip' + '/') 
          return active ? 'trips' : null

        } else if (type == 'places') {
          const active = 
            routePath === '/country' || routePath.startsWith('/country' + '/') ||
            routePath === '/city' || routePath.startsWith('/city' + '/') ||
            routePath === '/place/new' || routePath.startsWith('/place/new' + '/') ||
            routePath === '/place' || routePath.startsWith('/place' + '/') 
          return active ? 'places' : null

        } else if (type == 'payment') {
          const active = 
            routePath === '/transaction' || routePath.startsWith('/transaction' + '/') ||
            routePath === '/wallet' || routePath.startsWith('/wallet' + '/') ||
            routePath === '/cycle' || routePath.startsWith('/cycle' + '/') ||
            routePath === '/invoice' || routePath.startsWith('/invoice' + '/') ||
            routePath === '/request' || routePath.startsWith('/request' + '/') 
          return active ? 'payment' : null
        } else if (type == 'policies') {
          const active = 
            routePath === '/terms-and-conditions' || routePath.startsWith('/terms-and-conditions' + '/') ||
            routePath === '/privacy-policy' || routePath.startsWith('/privacy-policy' + '/') 
          return active ? 'policies' : null

        }
      }
    },
  },
  async mounted() {
    this.doFetchCountOfNewComplaint()
    this.doFetchCountOfNewRequests()
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetchCountOfNewComplaint: 'complaint/doFetchCountOfNewComplaint',
      doFetchCountOfNewRequests: 'request/list/doFetchCountOfNewRequests',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + "/");

      return {
        active,
      };

      // return {
      //   active: this.$route.path.startsWith(path),
      // };
    },
  },
};
