import { render, staticRenderFns } from "./image-upload.vue?vue&type=template&id=d0e33500&scoped=true&"
import script from "./image-upload.vue?vue&type=script&lang=js&"
export * from "./image-upload.vue?vue&type=script&lang=js&"
import style0 from "./image-upload.vue?vue&type=style&index=0&lang=css&"
import style1 from "./image-upload.vue?vue&type=style&index=1&id=d0e33500&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0e33500",
  null
  
)

export default component.exports
import {QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg})
