import guideListStore from '@/modules/guide/guide-list-store';
import guideViewStore from '@/modules/guide/guide-view-store';
import guideImporterStore from '@/modules/guide/guide-importer-store';
import guideFormStore from '@/modules/guide/guide-form-store';
import guideDestroyStore from '@/modules/guide/guide-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: guideDestroyStore,
    form: guideFormStore,
    list: guideListStore,
    view: guideViewStore,
    importer: guideImporterStore,
  },
};
