import { render, staticRenderFns } from "./notifications-list.vue?vue&type=template&id=9b4eaeae&scoped=true&"
import script from "./notifications-list.vue?vue&type=script&lang=js&"
export * from "./notifications-list.vue?vue&type=script&lang=js&"
import style0 from "./notifications-list.vue?vue&type=style&index=0&id=9b4eaeae&lang=scss&scoped=true&"
import style1 from "./notifications-list.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b4eaeae",
  null
  
)

export default component.exports
import {QMenu,QItemLabel,QScrollArea,QList,QInfiniteScroll,QItem,QItemSection,QAvatar,QImg,QSpinnerIos,QSeparator,QSpinnerDots,QIcon,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMenu,QItemLabel,QScrollArea,QList,QInfiniteScroll,QItem,QItemSection,QAvatar,QImg,QSpinnerIos,QSeparator,QSpinnerDots,QIcon,QBtn})
