//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-review-list-card',
  props: {
    userId: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
      default: 'user',
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    totalRating: {
      type: Number,
      required: false,
      default: 0,
    },
    // reviews: {
    //   type: Array,
    //   required: true,
    // },
    // loading: {
    //   type: Boolean,
    //   required: false,
    // }
  },

  mounted() {
    this.doFilter();
    // this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      rows: 'review/list/rows',
      loading: 'review/list/loading',
      moreRows: 'review/list/moreRows',
      rowsBatch: 'review/list/rowsBatch',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      resetCursor: 'review/list/resetCursor',
      setLoadMoreLast: 'review/list/setLoadMoreLast',
      doFetchReviews: 'review/list/doFetchReviews',
      doFetchMoreReviews: 'review/list/doFetchMoreReviews',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    async doFilter() {
      await this.resetCursor()
      await this.doFetchReviews({ userId: this.userId, type: this.type, limit: 10 })
      // this.rowsToBeDisplayed = this.rows
      // this.reviews = this.rows
    },
    doFetchMore() {
      this.doFetchMoreReviews({ userId: this.userId, type: this.type, limit: 10 })
    },
    onLoad(index, done) {
      console.log('index = ', index);
      // if (index > 1) {
        setTimeout(() => {
          if (this.moreRows) {
            this.doFetchMore()
            done()
          }
        }, 2000)      
      // } else {
      //   setTimeout(() => {
      //     if (this.moreRows) {
      //       done()
      //     }
      //   }, 200)      
      // }
    },
    onLoadReview (index, done) {
      console.log('index = ', index);
      setTimeout(() => {
        this.reviews.push({
          rate: 3.5,
          description: {
            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
            ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
          },
          client: {
            firstName: 'Ahmed',
            fullName: 'Ahmed Mostafa',
            avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
          },
          createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          createdAt: '2021-02-08T20:59:08.103Z',
          updatedAt: '2021-02-08T20:59:08.103Z',
        })
        done()
      }, 2000)
    },
  },
};
