import { getLanguageCode } from '@/i18n';
import HelperClass from '@/shared/helperClass';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,

  state: {
    currentLanguageCode: getLanguageCode(),
    currentLocation: {
      latitude: null,
      longitude: null,
      city: null,
      country: null,
      countryCode: null,
      region: null,
      regionName: null,
      timezone: null,
      zip: null,
    },
    menuCollapsed: false,
    isMobile: false,
    is_screen_xs: false,
    is_screen_sm: false,
    is_screen_md: false,
    is_screen_lg: false,
    is_labtop: false,
    screen_size: null
  },

  getters: {
    currentLanguageCode: (state) => state.currentLanguageCode,
    currentLocation: (state) => state.currentLocation,
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    is_screen_xs: (state) => !!state.is_screen_xs,
    is_screen_sm: (state) => !!state.is_screen_sm,
    is_screen_md: (state) => !!state.is_screen_md,
    is_screen_lg: (state) => !!state.is_screen_lg,
    is_labtop: (state) => !!state.is_labtop,
    screen_size: (state) => state.screen_size,

    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
  },

  mutations: {
    SET_CURRENT_LANGUAGE_CODE(state, payload) {
      state.currentLanguageCode = payload
    },

    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.screen_size = payload
      // state.isMobile = payload.width < 576;
      state.isMobile = payload.width < 470;
      state.is_screen_xs = payload.width < 600;
      state.is_screen_sm = payload.width >= 600 && payload.width < 1024;
      state.is_screen_md = payload.width >= 1024 && payload.width < 1440;
      state.is_screen_lg = payload.width >= 1440 && payload.width < 1920;
      state.is_labtop = payload.width <= 1400 && payload.height <= 800;
    },

    SET_CURRENT_LOCATION(state, payload) {
      state.currentLocation = { 
        ...state.currentLocation,
        ...payload
      }
    }
  },

  actions: {
    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    changeCurrentLanguageCode({ commit }, language) {
      commit('SET_CURRENT_LANGUAGE_CODE', language)
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },

    async findCurrentLocation({ commit }) {
      try {
        HelperClass.getCurrentLocation(async(position) => {
          commit('SET_CURRENT_LOCATION', {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        })

        HelperClass.reverseGeocoding((res) => {
          commit('SET_CURRENT_LOCATION', {
            city: res.city,
            country: res.country,
            countryCode: res.countryCode,
            region: res.region,
            regionName: res.regionName,
            timezone: res.timezone,
            zip: res.zip,
          })
        })
      } catch (error) {
        console.error(error);
        Errors.handle(error);
      }
    }


  },
};
