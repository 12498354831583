//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-created-successfully-modal',
  // props: ['visible', 'message'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: false,
    },
  },

  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('close');
    }, 3000);
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        setTimeout(() => {
          this.$emit('close');
        }, 3000);
      }
    }
  },
};
