// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const TransactionListPage = () =>
  import('@/modules/transaction/components/transaction-list-page.vue');
const TransactionViewPage = () =>
  import('@/modules/transaction/components/transaction-view-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'transaction',
        path: '/transaction',
        component: TransactionListPage,
        meta: {
          auth: true,
          permission: Permissions.values.transactionView,
          title: i18n('metaTitle.transaction')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'transactionView',
        path: '/transaction/:id',
        component: TransactionViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.transactionView,
          title: i18n('metaTitle.viewTransaction') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
