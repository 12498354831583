import { SettingsService } from '@/modules/settings/settings-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    settings: null,
  },

  getters: {
    settings: (state) => state.settings,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    ...vuexfireMutations,

    FIND_STARTED(state) {
      state.settings = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.settings = payload;
      state.findLoading = false;
    },

    VUEXFIRE_FIND_SUCCESS(state) {
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.settings = null;
      state.findLoading = false;
    },

    SAVE_STARTED(state) {
      state.saveLoading = true;
    },

    SAVE_SUCCESS(state) {
      state.saveLoading = false;
    },

    SAVE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    async doFind({ commit }) {
      try {
        commit('FIND_STARTED');
        const settings = await SettingsService.find();
        commit('FIND_SUCCESS', settings);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/');
      }
    },

    doFindByVuexfire: firestoreAction(async (context) => {
      try {
        context.commit('FIND_STARTED')

        const customSerializer = (doc) => {
          let data = FirebaseRepository.mapDocument(doc)
          // Object.defineProperty(data, '_doc', { value: doc })
          return data
        }
        const db = firebase.firestore()
        const settingQuery = db.collection('settings').doc('default')

        await context.bindFirestoreRef(
          'settings',
          settingQuery,
          { serialize: customSerializer }
        )
        .then((settings) => {
          // context.commit('FIND_SUCCESS', settings)   
          if(!settings){
            context.dispatch('doFind') 
          }
        })

        context.commit('VUEXFIRE_FIND_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/');
      }  
    }),

    async doSave({ commit }, values) {
      try {
        commit('SAVE_STARTED');
        await SettingsService.save(values);
        commit('SAVE_SUCCESS');
        Message.success(i18n('settings.save.success2'));
        // const secondsForReload = 3;
        // Message.success(
        //   i18n('settings.save.success', secondsForReload),
        // );
        /**
         * Theme change happens at boot time.
         * So to take effect the page must be reloaded
         */
        // setTimeout(
        //   () => window.location.reload(false),
        //   secondsForReload * 1000,
        // );
      } catch (error) {
        Errors.handle(error);
        commit('SAVE_ERROR');
      }
    },
  },
};
