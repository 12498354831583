import { PlanService } from '@/modules/plan/plan-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    places: [],
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    places: (state) => state.places,
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    VUEXFIRE_FIND_SUCCESS(state) {
      state.loading = false;
    },
    FIND_PLACES_SUCCESS(state, payload) {
      state.places = payload;
      state.loading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
  },

  actions: {
    doFindByVuexfire: firestoreAction(async (context, id) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const PlanQuery = db.collection('plan').doc(id)
          
        await context.bindFirestoreRef(
          'record',
          PlanQuery,
          { serialize: customSerializer }
        )
        
        const placesID = context.getters.record.programs.map(item => {
          return item.places.map(place => place.id)
        })
        console.log('Places ID = ', placesID.flat(Infinity));
        context.dispatch('doFindPlaces',  placesID.flat(Infinity))
        // await PlanService.populate(context.getters.record)

        context.commit('VUEXFIRE_FIND_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/trip');
      }
    }),

    doFindPlacesByVuexfire: firestoreAction(async (context, ids) => {
      try {

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const PlaceQuery = db.collection('place').where('id', 'in', ids)
          
        await context.bindFirestoreRef(
          'places',
          PlaceQuery,
          { serialize: customSerializer }
        )
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/trip');
      }
    }),

    async doFindPlaces(context, ids) {
      try {
        const response = await FirebaseRepository.findDocuments('place', ids)
        context.commit('FIND_PLACES_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/trip');
      }
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await PlanService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/plan');
      }
    },
  },
};

