import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import config from '@/config';

export default function firebaseInit() {
  if (firebase.apps.length === 0) {
    const app = firebase.initializeApp(config.firebaseConfig);
    app.auth();
  }
}
