// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const StoresListPage = () =>
  import('@/modules/stores/components/stores-list-page.vue');
const StoresViewPage = () =>
  import('@/modules/stores/components/stores-view-page.vue');
// const StoreFormPage = () =>
//   import('@/modules/stores/components/stores-form-page.vue');
// const StoreImporterPage = () =>
  // import('@/modules/stores/components/stores-importer-page.vue');
// const NotificationFormPage = () =>
  // import('@/modules/notification/components/notification-form-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'store',
        path: '/store',
        component: StoresListPage,
        meta: {
          auth: true,
          permission: Permissions.values.storeView,
          title: i18n('metaTitle.store')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      // {
      //   name: 'storeNew',
      //   path: '/store/new',
      //   component: StoreFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.storeCreate,
      //     title: i18n('metaTitle.newStore') 
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'storeImporter',
      //   path: '/store/import',
      //   component: StoreImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.storeImport,
      //   },
      // },
      // {
      //   name: 'storeEdit',
      //   path: '/store/:id/edit',
      //   component: StoreFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.storeEdit,          
      //     title: i18n('metaTitle.editStore') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      {
        name: 'storeView',
        path: '/store/:id',
        component: StoresViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.storeView,
          title: i18n('metaTitle.viewStore') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
