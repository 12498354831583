import countryListStore from '@/modules/country/country-list-store';
import countryViewStore from '@/modules/country/country-view-store';
import countryFormStore from '@/modules/country/country-form-store';
import countryDestroyStore from '@/modules/country/country-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: countryDestroyStore,
    form: countryFormStore,
    list: countryListStore,
    view: countryViewStore,
  },
};
