import { NotificationService } from '@/modules/notification/notification-service';
import notificationListExporterFields from '@/modules/notification/notification-list-exporter-fields';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';
import lodash from 'lodash';

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

// const db = firebase.firestore()

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    table: null,
    
    moreRowsPending: true,
    rowsBatch: [],

    latestNotifications: [],
    itemList: [],
    newNotifications: [],

    unreadNotificationsToHeader: 0,
    totalUnreadNotifications: 0,
    unreadStoreNotifications: 0,
    unreadOfferNotifications: 0,
    unreadCompanyNotifications: 0,
    unreadGuideNotifications: 0,
    unreadPlaceNotifications: 0,

    firstBatch: [],
    secondBatch: [],
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    newRowsLength: 0
  },

  getters: {
    unreadNotificationsToHeader: (state) => state.unreadNotificationsToHeader,
    totalUnreadNotifications: (state) => state.totalUnreadNotifications,
    unreadStoreNotifications: (state) => state.unreadStoreNotifications,
    unreadOfferNotifications: (state) => state.unreadOfferNotifications,
    unreadCompanyNotifications: (state) => state.unreadCompanyNotifications,
    unreadGuideNotifications: (state) => state.unreadGuideNotifications,
    unreadPlaceNotifications: (state) => state.unreadPlaceNotifications,
    
    firstBatch: (state) => state.firstBatch || [],
    secondBatch: (state) => state.secondBatch || [],
    newRowsLength: (state) => state.newRowsLength,
    loadFirstRecord: (state) => state.loadFirstRecord,
    loadMoreLastRecord: (state) => state.loadMoreLastRecord,

    itemList: (state) => state.itemList || [],
    latestNotifications: (state) => state.latestNotifications || [],
    newNotifications: (state) => state.newNotifications || [],
    
    moreRows: (state) => state.moreRowsPending,
    rowsBatch: (state) => state.rowsBatch || [],

  //#region [ default ]
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    
    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  //#endregion
  },

  mutations: {
  //#region [ default ]  
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_STARTED(state) {
      state.loading = true;

      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },
    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },
    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },
    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
  //#endregion
   
    ...vuexfireMutations,
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null
      state.loadFirstRecord = null
      state.firstBatch = []
      state.secondBatch = []
      state.newRowsLength = 0
      state.moreRowsPending = true

      state.rows = []
      state.count = 0
    },
    MERGE_ROWS_BATCH(state, payload) {
      console.log(`Adding ${payload.notifications.length} to the list`)
      const myArray = state.rows.concat(payload.notifications)
      // state.rows = [...new Set(myArray)]
      state.rows = lodash.union(state.rows, myArray);
    },
    SET_LOAD_MORE_LAST(state) {
      // debugger
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
      state.rowsBatch = []
    },
    ALL_ROWS_LOADED(state) {
      state.moreRowsPending = false
    },

    APPEND_TO_SECOND_BATCH(state, payload){
      state.secondBatch.push(payload)
    },
    SET_LAST_RECORD_SECOND_BATCH(state) {
      state.loadMoreLastRecord = state.secondBatch[state.secondBatch.length - 1]
    },
    SET_LOAD_FIRST_RECORD(state){
      state.loadFirstRecord = state.rows[0]._doc
      console.log('state.drugsCurrent[0]._doc', state.rows[0]._doc);
      console.log('loadFirstRecord ====>', state.rows[0].name);
    },
    
    NEW_ROWS_LENGTH(state,payload) {
      state.newRowsLength = payload
    },


    VUEXFIRE_FETCH_STARTED(state) {
      state.loading = true;
    },
    VUEXFIRE_FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload;
      state.count = payload.length;
      // state.firstBatch = payload;

      // state.rows = payload.rows;
      // state.count = payload.count;
    },
    VUEXFIRE_FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
      state.firstBatch = [];
    },

    // FETCH_NEW_STARTED(state) {
    //   state.loading = true;
    // },
    FETCH_NEW_SUCCESS(state, payload) {
      // debugger
      state.newNotifications = payload;
    },
    FETCH_NEW_ERROR(state) {
      state.newNotifications = [];
    },
    FETCH_COUNT_TO_HEADER_SUCCESS(state, payload) {
      state.unreadNotificationsToHeader = payload;
    },
    FETCH_COUNT_TO_HEADER_ERROR(state) {
      state.unreadNotificationsToHeader = 0;
    },
    FETCH_COUNT_SUCCESS(state, payload) {
      state.totalUnreadNotifications = payload.total;
      state.unreadCompanyNotifications = payload.company;
      state.unreadGuideNotifications = payload.guide;
      state.unreadStoreNotifications = payload.store;
      state.unreadOfferNotifications = payload.offer;
      state.unreadPlaceNotifications = payload.place;
    },
    FETCH_COUNT_ERROR(state) {
      state.totalUnreadNotifications = 0
      state.unreadCompanyNotifications = 0
      state.unreadGuideNotifications = 0
      state.unreadStoreNotifications = 0
      state.unreadOfferNotifications = 0
      state.unreadPlaceNotifications = 0
    },
    FETCH_LATEST_NOTIFICATIONS_ERROR(state) {
      state.latestNotifications = [];
    },
  },

  actions: {
  //#region [ ======{ default }====== ]
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doExport({ commit, getters }) {
      try {
        if (!notificationListExporterFields || !notificationListExporterFields.length) {
          throw new Error('notificationListExporterFields is required');
        }

        commit('EXPORT_STARTED');

        const filter = getters.filter;

        const response = await NotificationService.list(
          filter,
          getters.orderBy,
          null,
          null,
        );

        new Exporter(
          notificationListExporterFields,
          'notification',
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    // async doFetch(
    //   { commit, getters },
    //   { filter, keepPagination } = {},
    // ) {
    //   try {
    //     commit('FETCH_STARTED', { filter, keepPagination });

    //     const response = await NotificationService.list(
    //       filter,
    //       getters.orderBy,
    //       getters.limit,
    //       getters.offset,
    //     );

    //     commit('FETCH_SUCCESS', {
    //       rows: response.rows,
    //       count: response.count,
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },
    async doFetchInBackground(
      { commit, getters },
      { userId, filter, keepPagination } = {},
    ) {
      try {
        commit('FETCH_STARTED_IN_BACKGROUND', { filter, keepPagination });

        const response = await NotificationService.list(
          userId,
          filter,
          getters.orderBy,
          // getters.limit,
          // getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  //#endregion

  //#region [ ======{ To Layout Header }====== ]
    // doFetchNewNotifications: firestoreAction(async ({ bindFirestoreRef, commit, getters }) => {
    //   try {
    //     // commit('FETCH_NEW_STARTED')
    //     const customSerializer = (doc) => {
    //       const data = FirebaseRepository.mapDocument(doc)
    //       return data
    //     }

    //     const db = firebase.firestore()
    //     const query = db.collection('notification')
    //       .where("action", "==", null)
    //       .where("isNew", "==", true)
    //       .orderBy('createdAt', 'desc')
    //       .limit(10)

    //     await bindFirestoreRef(
    //       'newNotifications',
    //       query,
    //       { serialize: customSerializer }
    //     );
        
    //     getters.newNotifications.forEach(item => NotificationService.showNotif(item))

    //     // const response = await NotificationService.populateAll(getters.itemList)
    //     // commit('VUEXFIRE_FETCH_SUCCESS', response)   
    //     // commit('FETCH_SUCCESS')   
    //     // commit('FETCH_NEW_SUCCESS', getters.newNotifications)   
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_NEW_ERROR');
    //   }  
    // }),

    async doFetchNewNotifications({ commit, getters }) {
      try {
        const db = firebase.firestore()
        const query = db.collection('notification')
          .where("action", "==", null)
          .where("isNew", "==", true)
          .orderBy('createdAt', 'desc')
          .limit(10)

        query.onSnapshot(querySnapshot => {
          console.log(`Received query snapshot of size ${querySnapshot.size}`);
          commit('FETCH_NEW_SUCCESS', FirebaseRepository.mapCollection(querySnapshot))
          getters.newNotifications.forEach(item => NotificationService.showNotif(item))
        }, err => {
          console.log(`Encountered error: ${err}`);
          throw err
        });
        
        // getters.newNotifications.forEach(item => NotificationService.showNotif(item))
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_NEW_ERROR');
      }  
    },
    async doFetchCountToHeaderLayout({ commit }) {
      try {
        const db = firebase.firestore()
        const query = db.collection('notification')
          .where("action", "==", null)
          .where("read", "==", false)
          .limit(10)

        query.onSnapshot(querySnapshot => {
          // console.log(`Received query snapshot of size ${querySnapshot.size}`);
          commit('FETCH_COUNT_TO_HEADER_SUCCESS', querySnapshot.size)  
        }, err => {
          console.log(`Encountered error: ${err}`);
          throw err
        });

        // commit('FETCH_COUNT_TO_HEADER_SUCCESS', count)  
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_COUNT_TO_HEADER_ERROR');
      }
    },
    async doFetchCount({ commit }) {
      try {
        const db = firebase.firestore()
        const query = db.collection('notification')
          .where("action", "==", null)
          .where("read", "==", false)

        query.onSnapshot(querySnapshot => {
          const documents = FirebaseRepository.mapCollection(querySnapshot)
          const payload = {
            company: documents.filter(el => el.type == 'company' || el.type == 'companyUpdate').length,
            guide: documents.filter(el => el.type == 'guide' || el.type == 'guideUpdate').length,
            store: documents.filter(el => el.type == 'store' || el.type == 'storeUpdate').length,
            offer: documents.filter(el => el.type == 'offer' || el.type == 'offerUpdate').length,
            place: documents.filter(el => el.type == 'place' || el.type == 'placeUpdate').length,
            total: documents.length,
          }
          commit('FETCH_COUNT_SUCCESS', payload)  
        }, err => {
          console.log(`Encountered error: ${err}`);
          throw err
        });

      } catch (error) {
        Errors.handle(error);
        commit('FETCH_COUNT_ERROR');
      }
    },
    doFetcLatestNotifications: firestoreAction(async (context, payload) => {
      try {

        const customSerializer = (doc) => {
          // let data = doc.data()
          // Object.defineProperty(data, '_doc', { value: doc })
          const data = FirebaseRepository.mapDocument(doc)
          return data
        }
        const db = firebase.firestore()
        const notificationQuery = db.collection('notification')
          .where("action", "==", null)
          .orderBy('createdAt', 'desc')
          .limit(payload.limit || 15)

        await context.bindFirestoreRef(
          'latestNotifications',
          notificationQuery,
          { serialize: customSerializer }
        );

        // context.commit('FETCH_LATEST_NOTIFICATIONS_SUCCESS', context.getters.latestNotifications.length)   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_LATEST_NOTIFICATIONS_ERROR');
      } 
    }),
  //#endregion

  //#region [ Vuexfire ]
    doFetchNotifications: firestoreAction(async (context, payload) => {
      try {
        context.commit('FETCH_STARTED')
        // debugger
        const customSerializer = (doc) => {
          // let data = doc.data()
          let data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }
        const db = firebase.firestore()
        const notificationQuery = db.collection('notification')
          .where("action", "==", null)
          .where("type", "in", [payload.type, `${payload.type}Update`])
          .orderBy('createdAt', 'desc')
          .limit(payload.limit || 10)

        await context.bindFirestoreRef(
          'rowsBatch',
          notificationQuery,
          { serialize: customSerializer }
        )
        .then((notifications) => {
          console.log('notifications', notifications);
          console.log(`Got ${notifications.length} notifications`)
          if(notifications.length > 0){
            // context.commit('MERGE_ROWS_BATCH', { notifications })
            context.commit('VUEXFIRE_FETCH_SUCCESS', notifications) 
            context.commit('SET_LOAD_MORE_LAST')
          }
          // set all loaded if we dont return as many as the limit
          if (notifications.length < payload.limit) context.commit('ALL_ROWS_LOADED')
        })

        // const response = await NotificationService.populateAll(getters.itemList)
        // commit('VUEXFIRE_FETCH_SUCCESS', response)   
        context.commit('FETCH_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_ERROR');
      }  
    }),
    setLoadMoreLast({ commit }) {
      commit('SET_LOAD_MORE_LAST')
    },
    resetCursor({ commit }) {
      commit('RESET_CURSOR')
    },
    setLoadFirstRecord({ commit }) {
      commit('SET_LOAD_FIRST_RECORD')
    },
    async doFetch({ commit, getters }, payload) {
      try {
        const notificationsCollection = await firebase.firestore()
        .collection('notification')
        .where("action", "==", null)
        .where("type", "in", [payload.type, `${payload.type}Update`])
        .orderBy('createdAt', 'desc')
        .startAfter(getters.loadMoreLastRecord)
        .limit(payload.limit || 10)
        .get()

        let currentNotifications = []
        notificationsCollection.docs.forEach(doc => {
          // let notification = doc.data()
          let notification = FirebaseRepository.mapDocument(doc)
          notification['_doc'] = doc
          currentNotifications.push(notification)
          // Object.defineProperty(notification, '_doc', { value: doc })
          // commit('APPEND_TO_SECOND_BATCH', notification)
        })
        // debugger
        
        console.log(`Got ${currentNotifications.length} notifications`)
        if(currentNotifications.length > 0){
          // context.commit('MERGE_ROWS_BATCH', { notifications })
          commit('MERGE_ROWS_BATCH', { notifications: currentNotifications }) 
          commit('SET_LOAD_MORE_LAST')
        }
        // set all loaded if we dont return as many as the limit
        if (currentNotifications.length < payload.limit) commit('ALL_ROWS_LOADED')

        // commit('NEW_ROWS_LENGTH',notifications.docs.length)
        // getters.secondBatch = currentNotifications
        // commit('SET_LAST_RECORD_SECOND_BATCH')
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      } 
    }, 
  //#endregion

  },
};
