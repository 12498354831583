import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-client';
import { IamService } from '@/modules/iam/iam-service';

export class ClientService {
  static async enable(ids) {
    return IamService._changeStatus(ids, false);
  }

  static async disable(ids) {
    return IamService._changeStatus(ids, true);
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_CREATE($data: ClientInput!) {
          clientCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.clientCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_UPDATE(
          $id: String!
          $data: ClientInput!
        ) {
          clientUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.clientUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_DESTROY($ids: [String!]!) {
          clientDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.clientDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLIENT_IMPORT(
          $data: ClientInput!
          $importHash: String!
        ) {
          clientImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.clientImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CLIENT_FIND($id: String!) {
          clientFind(id: $id) {
            id
            fullName
            email
            phoneNumber
            avatar {
              name
              publicUrl
            }
            lang
            points
            wallet
            disabled
            deviceTokens
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.clientFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query CLIENT_LIST(
          $filter: ClientFilterInput
          $orderBy: ClientOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          clientList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              fullName
              email
              phoneNumber
              avatar {
                name
                publicUrl
              }
              lang
              points
              wallet
              disabled
              deviceTokens
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.clientList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query CLIENT_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          clientAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.clientAutocomplete;
  }
}
