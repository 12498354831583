// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const IamListPage = () =>
  import('@/modules/iam/components/iam-list-page.vue');
const IamNewPage = () =>
  import('@/modules/iam/components/iam-new-page.vue');
const IamEditPage = () =>
  import('@/modules/iam/components/iam-edit-page.vue');
const IamViewPage = () =>
  import('@/modules/iam/components/iam-view-page.vue');
const IamImporterPage = () =>
  import('@/modules/iam/components/iam-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'iam',
        path: '/admins',
        component: IamListPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamRead,
          title: i18n('metaTitle.admins')
        },
       
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'iamNew',
        path: '/admins/new',
        component: IamNewPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamCreate,
          title: i18n('metaTitle.newAdmins')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'iamImporter',
        path: '/admins/import',
        component: IamImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamImport,
        },
      },
      {
        name: 'iamEdit',
        path: '/admins/:id/edit',
        component: IamEditPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamEdit,
          title: i18n('metaTitle.editAdmin')
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'iamView',
        path: '/admins/:id',
        component: IamViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamRead,
          title: i18n('metaTitle.viewAdmin')
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
