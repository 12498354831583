// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/vueI18n';

const SigninPage = () =>
  import('@/modules/auth/components/signin-page.vue');
const SignupPage = () =>
  import('@/modules/auth/components/signup-page.vue');
const ForgotPasswordPage = () =>
  import('@/modules/auth/components/forgot-password-page.vue');
// const ResetPasswordPage = () =>
//   import('@/modules/auth/components/reset-password-page.vue');  
const EmailUnverifiedPage = () =>
  import('@/modules/auth/components/email-unverified-page.vue');
const EmptyPermissionsPage = () =>
  import('@/modules/auth/components/empty-permissions-page.vue');
const ProfileFormPage = () =>
  import('@/modules/auth/components/profile-form-page.vue');

// const Dashboard = () =>
//   import('@/modules/home/components/Dashboard.vue');


export default [
  {
    name: 'signin',
    path: '/auth/signin',
    component: SigninPage,
    meta: { unauth: true , title: i18n('metaTitle.signIn')},
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title
      next()
    }
  },
  {
    name: 'signup',
    path: '/auth/signup',
    component: SignupPage,
    meta: { unauth: true , title: i18n('metaTitle.signUp') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title
      next()
    }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPasswordPage,
    meta: { unauth: true , title: i18n('metaTitle.forgetPass')},
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title
      next()
    }
  },
  // {
  //   name: 'resetPassword',
  //   path: '/__/auth/reset-password/action',
  //   component: ResetPasswordPage,
  //   meta: { unauth: true , title: i18n('metaTitle.forgetPass')},
  //   beforeEnter: (to, from, next) => {
  //     document.title = to.meta.title
  //     next()
  //   }
  // },
  {
    name: 'emailUnverified',
    path: '/auth/email-unverified',
    component: EmailUnverifiedPage,
    meta: { auth: true, emailAlreadyVerified: true , title: i18n('metaTitle.emailUnverified')},
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title
      next()
    }
  },
  {
    name: 'emptyPermissions',
    path: '/auth/empty-permissions',
    component: EmptyPermissionsPage,
    meta: { auth: true, notEmptyPermissions: true , title: i18n('metaTitle.emptyPermissions')},
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title
      next()
    }
  },
  {
    name: '',
    path: '/',
    // redirect: Dashboard,
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'editProfile',
        path: '/auth/edit-profile',
        component: ProfileFormPage,
        meta: { auth: true , title: i18n('metaTitle.authEdit')},
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
