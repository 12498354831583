import placeListStore from '@/modules/place/place-list-store';
import placeViewStore from '@/modules/place/place-view-store';
import placeFormStore from '@/modules/place/place-form-store';
import placeDestroyStore from '@/modules/place/place-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: placeDestroyStore,
    form: placeFormStore,
    list: placeListStore,
    view: placeViewStore,
  },
};
