// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const CompanyListPage = () =>
  import('@/modules/company/components/company-list-page.vue');
const CompanyViewPage = () =>
  import('@/modules/company/components/company-view-page.vue');
  // const CompanyFormPage = () =>
  //   import('@/modules/company/components/company-form-page.vue');
// const CompanyImporterPage = () =>
  // import('@/modules/company/components/company-importer-page.vue');
// const NotificationFormPage = () =>
  // import('@/modules/notification/components/notification-form-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'company',
        path: '/company',
        component: CompanyListPage,
        meta: {
          auth: true,
          permission: Permissions.values.companyView,
          title: i18n('metaTitle.company')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'companyView',
        path: '/company/:id',
        component: CompanyViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.companyView,
          title: i18n('metaTitle.viewCompany') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
