import {
  i18n
} from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
// import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.review.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.review.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  payerId: new StringField('payerId', label('payerId'), {}),
  payeeId: new StringField('payeeId', label('payeeId'), {}),
  payerType: new EnumeratorField('payerType', label('payerType'), [
    { value: 'guide', label: enumeratorLabel('userType', 'guide') },
    { value: 'company', label: enumeratorLabel('userType', 'company') },
    { value: 'saryah', label: enumeratorLabel('userType', 'saryah') },
    { value: 'client', label: enumeratorLabel('userType', 'client') },
  ],{}),
  payeeType: new EnumeratorField('payeeType', label('payeeType'), [
    { value: 'guide', label: enumeratorLabel('userType', 'guide') },
    { value: 'company', label: enumeratorLabel('userType', 'company') },
    { value: 'saryah', label: enumeratorLabel('userType', 'saryah') },
    { value: 'client', label: enumeratorLabel('userType', 'client') },
  ],{}),
  amount: new DecimalField('amount', label('amount'), {}),
  payMethod: new StringField('payMethod', label('payMethod'), {}),
  date: new DateTimeField('date', label('date')),
  paymentId: new StringField('paymentId', label('paymentId'), {}),
  paymentType: new StringField('paymentType', label('paymentType'), {}),
  paymentBrand: new StringField('paymentBrand', label('paymentBrand'), {}),
  currency: new StringField('currency', label('currency'), {}),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ReviewModel extends GenericModel {
  static get fields() {
    return fields;
  }
}