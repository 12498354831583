import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import PortalVue from 'portal-vue';
import CKEditor from 'ckeditor4-vue';

Vue.use(CKEditor)
//#region [ Quasar ]
import Quasar from 'quasar';
import {
  Notify,
  LocalStorage,
  SessionStorage,
} from 'quasar';

Vue.use(Quasar, {
  plugins: {
    Notify,
    LocalStorage,
    SessionStorage,
  },
  config: {
    notify: {
      /* Notify defaults */
    },
    screen: {
      bodyClasses: true // <<< add this
    }
  },
});
import langAr from 'quasar/lang/ar';
Vue.use(Quasar, {
  lang: langAr,
});
import { LoadingBar } from 'quasar';
LoadingBar.setDefaults({
  color: 'cyan',
  size: '1px',
  position: 'top',
});
import './quasar';
//#endregion

// pretty checkbox
import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

// import BlackDashboard from './plugins/blackDashboard';
import * as VueGoogleMaps from 'vue2-google-maps';
import BootstrapVue from 'bootstrap-vue';
import VuePageTransition from 'vue-page-transition';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import { SettingsService } from '@/modules/settings/settings-service';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { i18n } from '@/i18n';
import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import vueSmoothScroll from 'vue2-smooth-scroll';



import draggable from 'vuedraggable'
import VueSelectImage from 'vue-select-image'
import i18n from './vueI18n'

import { firestorePlugin } from 'vuefire'


// Custom Styles ==> SASS Theme 
import './assets/scss/index.scss'
// import '../public/styles/scss/index.scss'

(async function() {
  document.title = i18n.t('app.title');
  // ProgressBar.start();
  SettingsService.fetchAndApply();
  // routerAsync().push('/auth/signin');

  // ======================================= New Installer ======================================= //
  //#region [ New Installer ]
  Vue.use(firestorePlugin)
  Vue.use(PerfectScrollbar);
  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('vue-phone-number-input', VuePhoneNumberInput);
  Vue.component('draggable', draggable);
  
  Vue.use(VueSelectImage)
  require('vue-select-image/dist/vue-select-image.css') // add stylesheet
  
  Vue.use(VuePageTransition);
  Vue.use(BootstrapVue);
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo',
      libraries: 'places', // necessary for places input
      region: i18n.locale === 'ar' ? 'EG' : 'US',
      language: i18n.locale,
    },
  });
  // Vue.use(BlackDashboard);
  Vue.use(vueSmoothScroll);
  Vue.directive('title', {
    inserted: (el, binding) =>
    (document.title = binding.value),
    update: (el, binding) =>
    (document.title = binding.value),
  });
  //#endregion
  // ============================================================================================= //
  
  Vue.use(Vuex);
  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  setupComponentsFiltersDirectivesAndMixins();

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();
