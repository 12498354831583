import { ReviewModel } from '@/modules/review/review-model';

const { fields } = ReviewModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.reviewName,
  fields.city,
  fields.country,
  fields.description,
  fields.reviewPictures,
  fields.reviewType,
];
