import {
  i18n
} from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
// import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.client.fields.${name}`);
}

// function enumeratorLabel(name, value) {
//   return i18n(`entities.clientType.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id')),
  avatar: new StringField('avatar', label('avatar'), {}),
  fullName: new StringField('fullName', label('fullName'), {}),
  email: new StringField('email', label('email'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  lang: new StringField('lang', label('lang'), {}),
  wallet: new DecimalField('wallet', label('wallet'), {}),
  points: new IntegerField('points', label('points'), {}),
  deviceTokens: new StringArrayField('deviceTokens', label('deviceTokens'), {}), 
  disabledAsStatus: new BooleanField('disabled', label('status'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'enabled',
      label: i18n('user.enabled'),
    },
    {
      id: 'disabled',
      label: i18n('user.disabled'),
    },
  ]),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ClientModel extends GenericModel {
  static get fields() {
    return fields;
  }
}