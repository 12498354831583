// import { i18n } from '@/i18n';
import _values from 'lodash/values';
import { i18n } from '@/vueI18n'

/**
 * List of Roles available for the Users.
 */
class Roles {
  static get values() {
    return {
      owner: 'owner',
      admin: 'admin',
      editor: 'editor',
      viewer: 'viewer',

      auditLogViewer: 'auditLogViewer',
      iamSecurityReviewer: 'iamSecurityReviewer',
      entityEditor: 'entityEditor',
      entityViewer: 'entityViewer',
      
      placeEditor: 'placeEditor',
      placeViewer: 'placeViewer',

      cityEditor: 'cityEditor',
      cityViewer: 'cityViewer',  

      planEditor: 'planEditor',
      planViewer: 'planViewer', 

      notificationEditor: 'notificationEditor',
      notificationViewer: 'notificationViewer',

      regionEditor: 'regionEditor',
      regionViewer: 'regionViewer',

      countryEditor: 'countryEditor',
      countryViewer: 'countryViewer',

      clientEditor: 'clientEditor',
      clientViewer: 'clientViewer',

      companyEditor: 'companyEditor',
      companyViewer: 'companyViewer',

      transactionEditor: 'transactionEditor',
      transactionViewer: 'transactionViewer',
      
      cycleEditor: 'cycleEditor',
      cycleViewer: 'cycleViewer',
      
      walletEditor: 'walletEditor',
      walletViewer: 'walletViewer',

      invoiceEditor: "invoiceEditor",
      invoiceViewer: "invoiceViewer",

      offerEditor: 'offerEditor',
      offerViewer: 'offerViewer',

      storeEditor: 'storeEditor',
      storeViewer: 'storeViewer',

      reviewEditor: 'reviewEditor',
      reviewViewer: 'reviewViewer',
      
      tripEditor: 'tripEditor',
      tripViewer: 'tripViewer',

      branchEditor: 'branchEditor',
      branchViewer: 'branchViewer',

      reservationEditor: 'reservationEditor',
      reservationViewer: 'reservationViewer',

      requestEditor: 'requestEditor',
      requestViewer: 'requestViewer',

    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Roles;
