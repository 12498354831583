import { ReviewService } from '@/modules/review/review-service';
import reviewListExporterFields from '@/modules/review/review-list-exporter-fields';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';
// import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';
import lodash from 'lodash';

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,

    loadMoreLastRecord: null,
    moreRowsPending: true,
    rowsBatch: [],
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },

    loadMoreLastRecord: (state) => state.loadMoreLastRecord,
    moreRows: (state) => state.moreRowsPending,
    rowsBatch: (state) => state.rowsBatch || [],
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null
      state.moreRowsPending = true
      state.rowsBatch = []
      state.rows = []
      state.count = 0
    },
    ADD_AT_FIRST_ROWS(state, payload) {
      const myArray = state.rows
      myArray.unshift(payload)
      state.rows = myArray
    },
    MERGE_ROWS_BATCH(state, payload) {
      console.log(`Adding ${payload.length} to the list`)
      // const myArray = state.rows.concat(payload)
      // state.rows = [...new Set(myArray)]
      
      const myArray = lodash.concat(state.rows, payload)
      state.rows = lodash.uniqWith(myArray, lodash.isEqual)
    },
    SET_LOAD_MORE_LAST(state) {
      console.log('Setting last...')
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
      // state.rowsBatch = []
    },
    ALL_ROWS_LOADED(state) {
      state.moreRowsPending = false
    },
    VUEXFIRE_FETCH_STARTED(state) {
      state.loading = true;
    },
    VUEXFIRE_FETCH_SUCCESS(state, payload) {
      // debugger
      state.loading = false;
      state.rows = payload;
      state.count = payload.length;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },
    VUEXFIRE_FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },


//#region [ default ]
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
      // state.rows = payload;
      // state.count = payload.length;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
//#endregion    
  },

  actions: {
  //#region [ default ]  
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doExport({ commit, getters }) {
      try {
        if (!reviewListExporterFields || !reviewListExporterFields.length) {
          throw new Error('reviewListExporterFields is required');
        }

        commit('EXPORT_STARTED');

        const filter = getters.filter;

        const response = await ReviewService.list(
          filter,
          getters.orderBy,
          null,
          null,
        );

        new Exporter(
          reviewListExporterFields,
          'review',
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch(
      { commit, getters },
      { filter, keepPagination } = {},
    ) {
      try {
        
        commit('FETCH_STARTED', { filter, keepPagination });
        const response = await ReviewService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    //#endregion

  //#region [ Vuexfire ]
    resetCursor({ commit }) {
      commit('RESET_CURSOR')
    },

    doFetchReviews: firestoreAction(async (context, payload) => {
      try {
        const limit = payload.limit || 10
        context.commit('FETCH_STARTED')
        // debugger
        const customSerializer = (doc) => {
          // let data = doc.data()
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          if (context.getters.rows.length && context.getters.rows.length > limit ) {
            context.commit('ADD_AT_FIRST_ROWS', data)
          }
          return data
        }

        const db = firebase.firestore()
        const reviewQuery = db.collection(`${payload.type}/${payload.userId}/review`)
          .orderBy('createdAt', 'desc')
          .limit(limit)

        await context.bindFirestoreRef(
          'rowsBatch',
          reviewQuery,
          { serialize: customSerializer }
        )
        .then((reviews) => {
          // debugger
          console.log(`Got ${reviews.length} reviews`)
          if(reviews.length > 0){
            // context.commit('MERGE_ROWS_BATCH', { reviews })
            // context.commit('MERGE_ROWS_BATCH', reviews)
            context.commit('VUEXFIRE_FETCH_SUCCESS', reviews) 
            context.commit('SET_LOAD_MORE_LAST')
          }
          // set all loaded if we dont return as many as the limit
          if (reviews.length < limit) context.commit('ALL_ROWS_LOADED')
        })

        context.commit('FETCH_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_ERROR');
      }  
    }),
    
    async doFetchMoreReviews({ commit, getters }, payload) {
      try {
        const limit = payload.limit || 10
        const reviewCollection = await firebase.firestore()
          .collection(`${payload.type}/${payload.userId}/review`)
          .orderBy('createdAt', 'desc')
          .startAfter(getters.loadMoreLastRecord)
          .limit(limit)
          .get()

        let currentReviews = []
        reviewCollection.docs.forEach(doc => {
          // let review = doc.data()
          let review = FirebaseRepository.mapDocument(doc)
          review['_doc'] = doc
          currentReviews.push(review)
          // Object.defineProperty(review, '_doc', { value: doc })
          // commit('APPEND_TO_SECOND_BATCH', review)
        })
        // debugger
        
        console.log(`Got ${currentReviews.length} review`)
        if(currentReviews.length > 0){
          // context.commit('MERGE_ROWS_BATCH', { review })
          commit('MERGE_ROWS_BATCH', currentReviews) 
          commit('SET_LOAD_MORE_LAST')
        }
        // set all loaded if we dont return as many as the limit
        if (currentReviews.length < limit) commit('ALL_ROWS_LOADED')
        // commit('NEW_ROWS_LENGTH',review.docs.length)
        // getters.secondBatch = currentReviews
        // commit('SET_LAST_RECORD_SECOND_BATCH')
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      } 
    }, 

  //#endregion

  },
};
