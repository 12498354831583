import { GuideService } from '@/modules/guide/guide-service';
import guideListExporterFields from '@/modules/guide/guide-list-exporter-fields';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';

import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,

    loadMoreLastRecord: null,
    moreRowsPending: true,
    rowsBatch: [],
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        // ...state.pagination,
        page: state.pagination && state.pagination.currentPage ? state.pagination.currentPage : 1,
        currentPage: state.pagination && state.pagination.currentPage ? state.pagination.currentPage : 1,
        rowsPerPage: getters.limit,
        pageSize: getters.limit,
        first: null,
        last: getters.loadMoreLastRecord,
        isFirstPage: state.pagination && state.pagination.currentPage ? state.pagination.currentPage == 1 : true,
        isLastPage: !getters.moreRows,
        rowsNumber: getters.count,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },

    loadMoreLastRecord: (state) => state.loadMoreLastRecord,
    moreRows: (state) => state.moreRowsPending,
    rowsBatch: (state) => state.rowsBatch || [],
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null
      state.moreRowsPending = true
      state.rowsBatch = []
      state.rows = []
      state.count = 0
    },
    ADD_AT_FIRST_ROWS(state, payload) {
      const myArray = state.rows
      myArray.unshift(payload)
      state.rows = myArray
    },
    MERGE_ROWS_BATCH(state, payload) {
      console.log(`Adding ${payload.length} to the list`)
      // const myArray = state.rows.concat(payload)
      // state.rows = [...new Set(myArray)]
      
      const myArray = lodash.concat(state.rows, payload)
      state.rows = lodash.uniqWith(myArray, lodash.isEqual)
    },
    SET_LOAD_MORE_LAST(state) {
      console.log('Setting last...')
      // debugger
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
      // state.rowsBatch = []
      state.loading = false;
    },
    ALL_ROWS_LOADED(state) {
      state.moreRowsPending = false
    },
    VUEXFIRE_FETCH_STARTED(state) {
      state.loading = true;
    },
    VUEXFIRE_FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload;
      // state.count = payload.length;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },
    VUEXFIRE_FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

  //#region [ default ]    
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
        page: payload || 1,
        rowsPerPage: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
        page: previousPagination.currentPage || 1,
        rowsPerPage: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    LOADING_FINISH(state){
      state.loading = false
    }
  //#endregion
  },

  actions: {
  //#region [ default ]
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doExport({ commit, getters }) {
      try {
        if (!guideListExporterFields || !guideListExporterFields.length) {
          throw new Error('guideListExporterFields is required');
        }

        commit('EXPORT_STARTED');

        const filter = getters.filter;

        const response = await GuideService.list(
          filter,
          getters.orderBy,
          null,
          null,
        );

        new Exporter(
          guideListExporterFields,
          'guide',
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch(
      { commit, getters },
      { filter, keepPagination } = {},
    ) {
      try {
        
        commit('FETCH_STARTED', { filter, keepPagination });
        const response = await GuideService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  //#endregion

  //#region [ Vuexfire ]
    resetCursor({ commit }) {
      commit('RESET_CURSOR')
    },
    doFetchByVuexfire: firestoreAction(async (context, { filter, keepPagination } = {}) => {
      try {
        const limit = context.getters.limit 
        // context.commit('PAGINATION_CHANGED', { filter, keepPagination });
        context.commit('FETCH_STARTED', { filter, keepPagination });

        const customSerializer = (doc) => {
          // let data = doc.data()
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const userQuery = db.collection('user')
          .where("accountType", "==", "guideFreelance")
          .orderBy('createdAt', 'desc')
          .limit(limit)

        await context.bindFirestoreRef('rows', userQuery, { 
          serialize: customSerializer
        })
        context.commit('LOADING_FINISH')
        // context.commit('SET_LOAD_MORE_LAST')
        // if (context.getters.rows.length < limit) context.commit('ALL_ROWS_LOADED')
        // .then((users) => {
        //   console.log(`Got ${users.length} users`)
        //   if(users.length > 0){
        //     // context.commit('MERGE_ROWS_BATCH', { users })
        //     // context.commit('VUEXFIRE_FETCH_SUCCESS', users) 
        //   }
        //   // set all loaded if we dont return as many as the limit
        // })
        // const response = await NotificationService.populateAll(getters.itemList)
        // commit('VUEXFIRE_FETCH_SUCCESS', response)   
        // context.commit('FETCH_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_ERROR');
      }  
    }),
    doFetchNextPage: firestoreAction(async (context, { filter, keepPagination } = {}) => {
      try {
        const limit = context.getters.limit 
        context.commit('FETCH_STARTED', { filter, keepPagination });

        const customSerializer = (doc) => {
          // let data = doc.data()
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const userQuery = db.collection('user')
          .where("accountType", "==", "guideFreelance")
          .orderBy('createdAt', 'desc')
          .limit(limit)

        await context.bindFirestoreRef(
          'rows',
          userQuery,
          { serialize: customSerializer }
        )
        .then((users) => {
          console.log(`Got ${users.length} users`)
          if(users.length > 0){
            // context.commit('MERGE_ROWS_BATCH', { users })
            // context.commit('VUEXFIRE_FETCH_SUCCESS', users) 
            context.commit('SET_LOAD_MORE_LAST')
          }
          // set all loaded if we dont return as many as the limit
          if (users.length < limit) context.commit('ALL_ROWS_LOADED')
        })
        // const response = await NotificationService.populateAll(getters.itemList)
        // commit('VUEXFIRE_FETCH_SUCCESS', response)   
        // context.commit('FETCH_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_ERROR');
      }  
    }),
    doFetchPrevPage: firestoreAction(async (context, { filter, keepPagination } = {}) => {
      try {
        const limit = context.getters.limit 
        context.commit('FETCH_STARTED', { filter, keepPagination });

        const customSerializer = (doc) => {
          // let data = doc.data()
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const userQuery = db.collection('user')
          .where("accountType", "==", "guideFreelance")
          .orderBy('createdAt', 'desc')
          .limit(limit)

        await context.bindFirestoreRef(
          'rows',
          userQuery,
          { serialize: customSerializer }
        )
        .then((users) => {
          console.log(`Got ${users.length} users`)
          if(users.length > 0){
            // context.commit('MERGE_ROWS_BATCH', { users })
            // context.commit('VUEXFIRE_FETCH_SUCCESS', users) 
            context.commit('SET_LOAD_MORE_LAST')
          }
          // set all loaded if we dont return as many as the limit
          if (users.length < limit) context.commit('ALL_ROWS_LOADED')
        })
        // const response = await NotificationService.populateAll(getters.itemList)
        // commit('VUEXFIRE_FETCH_SUCCESS', response)   
        // context.commit('FETCH_SUCCESS')   
      } catch (error) {
        Errors.handle(error);
        context.commit('FETCH_ERROR');
      }  
    }),
  //#endregion
  },
};
