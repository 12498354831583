import { CompanyModel } from '@/modules/company/company-model';

const { fields } = CompanyModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.companyName,
  fields.city,
  fields.country,
  fields.description,
  fields.companyPictures,
  fields.companyType,
];
