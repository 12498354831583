//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import i18n from '@/vueI18n'
// import moment from 'moment';

export default {
  name: 'app-notifications-list',
  props: {
    title: {
      type: String,
    },
    notifications: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      scrollTarget: void 0,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },

  methods: {
    ...mapActions({

    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      // return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
    },
    doOpenMessage(item) {
      this.$emit('openMessage', item)
    },
    onLoadMenu (index, done) {
      if (index > 1) {
        setTimeout(() => {
          if (this.itemsMenu) {
            this.itemsMenu.push({}, {}, {}, {}, {}, {}, {})
            done()
          }
        }, 2000)
      }
      else {
        setTimeout(() => {
          done()
        }, 200)
      }
    },
  },
};
