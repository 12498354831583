import importerStore from '@/shared/importer/importer-store';
import { StoresService } from '@/modules/stores/stores-service';
import storesImporterFields from '@/modules/stores/stores-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  StoresService.import,
  storesImporterFields,
  i18n('entities.store.importer.fileName'),
  i18n('entities.store.importer.hint'),
);
