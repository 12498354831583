import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
// import { GuideService } from '@/modules/guide/guide-service';
// import Errors from '@/shared/error/errors';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import HelperClass from '@/shared/helperClass';

import {
  firestoreAction,
  vuexfireMutations,
} from 'vuexfire';

export default {
  namespaced: true,

  state: {
    loading: false,
    currentDate: null,
    statistics: {
      clientsCount: 0,
      companiesCount: 0,
      freelanceGuidesCount: 0,
      offersCount: 0,
      totalProfit: 0,
      tripsCount: 0,
      completedTripsCount: 0,
      percentageOfCompletedTrips: 0,
    },
    inProgressTrips: [],
  },

  getters: {
    loading: (state) => state.loading,
    currentDate: (state) => state.currentDate,
    statistics: (state) => state.statistics,
    inProgressTrips: (state) => state.inProgressTrips,
  },

  mutations: {
    ...vuexfireMutations,

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state) {
      state.loading = false;
    },
    FETCH_ERROR(state) {
      state.loading = false;
    },

    SET_STATISTICS(state, payload) {
      const percentage = payload.completedTripsCount / payload.tripsCount || 0
      state.statistics = {
        ...payload,
        clientsCount: payload.clientsCount || 0,
        companiesCount: payload.companiesCount || 0,
        freelanceGuidesCount: payload.freelanceGuidesCount || 0,
        offersCount: payload.offersCount || 0,
        totalProfit: payload.totalProfit || 0,
        tripsCount: payload.tripsCount || 0,
        completedTripsCount: payload.completedTripsCount || 0,
        percentageOfCompletedTrips: parseFloat(percentage).toFixed(2),
      }

      state.loading = false;
    },

    UPDATE_CURRENT_DATE(state, payload) {
      state.currentDate = payload
    }
  },

  actions: {
    async doFetchDashboard({ commit }) {
      commit('FETCH_STARTED');
      const currentDate = HelperClass.getDateNowDetails()
      commit('UPDATE_CURRENT_DATE', currentDate)

      firebase.database().ref(`admin-dashboard`).on('value', (snapshot) => {
        let data = snapshot.val();
        if (!data[currentDate.year]) {
          data[currentDate.year] = {}
          data[currentDate.year][currentDate.month] = {}
        } else if (!data[currentDate.year][currentDate.month]) {
          data[currentDate.year][currentDate.month] = {}
        }
        commit('SET_STATISTICS', data);
      });
    },

    doFetchInProgressTrips: firestoreAction(
      async (context) => {
        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc);
          Object.defineProperty(data, '_doc', {
            value: doc,
          });
          return data;
        };

        const db = firebase.firestore();
        const query = db.collection('trip').where('status', 'in', 'started');

        await context.bindFirestoreRef(
          'inProgressTrips',
          query,
          { serialize: customSerializer },
        );
        await context.dispatch('doFetchTripInfo');
      },
    ),
  },
};
