import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-plan';
import { storeAsync } from '@/app-module';

import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';

export class PlanService {
//#region [ CRED Operations ]  
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_CREATE($data: PlanInput!) {
          planCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.planCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_UPDATE($id: String!, $data: PlanInput!) {
          planUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.planUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_DESTROY($ids: [String!]!) {
          planDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.planDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_IMPORT(
          $data: PlanInput!
          $importHash: String!
        ) {
          planImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.planImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_FIND($id: String!) {
          planFind(id: $id) {
            id
            images
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            type
            numberOfDays
            enabled
            programs {
              places {
                place {
                  id
                  placeName {
                    en
                    ar
                  }
                  description {
                    en
                    ar
                  }
                  countryId
                  country {
                    id
                    name {
                      en
                      ar
                    }
                  }
                  cityId
                  city {
                    id
                    country_id
                    name  {
                      en
                      ar
                    }
                  }
                  placeType
                  placePictures {
                    name
                    publicUrl
                  }
                  visitCount
                  address {
                    en
                    ar
                  }
                  lat
                  lng
                  strokeWidth
                  radius
                  fillColor
                  strokeColor
                  notifications
                  status
                  rejectionReason
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
                }
                id
                selectedImage
                averageTime
              }
            }

            owner {
              id 
              avatar 
              name {
                en
                ar
              }
              email
              phoneNumber
              type
              rate 
            }
        
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.planFind;
  }
//#endregion

  static async enable(id) {
    return this._changeStatus(id, true);
  }

  static async disable(id) {
    return this._changeStatus(id, false);
  }

  static async _changeStatus(id, enabled) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`plan/${id}`) 
    batch.update(docRef, {
      enabled,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    return await batch.commit()
  }

  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_MY_PLANS_WITH_PAGINATION(
          $filter: PlanFilterInput, 
          $orderBy: String,
          $pagination: PaginationInput
        ) {
          listMyPlansWithPagination(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              ownerId
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              type
              numberOfDays
              enabled
              images
              startPoint {
                placeName {
                  en
                  ar
                }
              }
              endPoint {
                placeName {
                  en
                  ar
                }
              }
              programs {
                places {
                  place {
                    id
                    placeName {
                        en
                        ar
                    }
                    description {
                        en
                        ar
                    }
                    countryId
                    country {
                      id
                      name {
                        en
                        ar
                      }
                    }
                    cityId
                    city {
                        id
                        country_id
                        name  {
                            en
                            ar
                        }
                    }
                    placeType
                    placePictures {
                        name
                        publicUrl
                    }
                    visitCount
                    address {
                        en
                        ar
                    }
                    lat
                    lng
                    strokeWidth
                    radius
                    fillColor
                    strokeColor
                    notifications
                    status
                    rejectionReason
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                  }
                  id
                  selectedImage
                  averageTime
                }
              }

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });

    return response.data.listMyPlansWithPagination;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          planAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.planAutocomplete;
  }


  /**
   * Populates the records with all its relations.
   * @param {*} records
   */
  static async populateAll(records) {
    return await Promise.all(
      records.map((record) => this.populate(record)),
    );
  }

  /**
   * Populates the record with all its relations.
   * @param {*} record
   */
  static async populate(record) {
    if (!record) {
      return record;
    }

    // Get Relation To One
    // record['startCity'] = await FirebaseRepository.findRelation('city', record.startCityId);

    // Get Relation To Many
    // const programs = await this.findProgramsRelation(record.programs);
    const programs = await new Promise(() => this.findProgramsRelation(record.programs))
    debugger
    record.programs = programs

    // Get Relation To Many
    // record.products = (await this.findRelation('product', record.products)) || [];
    return record;
  }

  static async findProgramsRelation(programs) {
    if (!programs) {
      return programs;
    }

    if (Array.isArray(programs)) {
      return await Promise.all(
        programs.map(program => {
          // debugger
          return {
            name: program.name,
            places: this.findPlaceDocuments(program.places)
          }
        })
      )
      // return this.findPlaceDocuments(values);
    }
  }
  static async findPlaceDocuments(values) {
    return await Promise.all(
      values.map(async (item) => {
        let Place = await this.findPlaceById(item.id);
debugger
        return {
          place: Place,
          id: item.id,
          selectedImage: item.selectedImage,
          averageTime: item.averageTime,
        }
      }),
    );
  }
  static async findPlaceById(id) {
    const record = await FirebaseRepository.findDocument('place', id);
    return record
  }

}
