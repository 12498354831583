import importerStore from '@/shared/importer/importer-store';
import { ClientService } from '@/modules/client/client-service';
import clientImporterFields from '@/modules/client/client-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  ClientService.import,
  clientImporterFields,
  i18n('entities.client.importer.fileName'),
  i18n('entities.client.importer.hint'),
);
