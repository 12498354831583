import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const InvoiceListPage = () =>
  import('@/modules/invoice/components/invoice-list-page.vue');
const InvoiceViewPage = () =>
  import('@/modules/invoice/components/invoice-view-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'invoice',
        path: '/invoice',
        component: InvoiceListPage,
        meta: {
          auth: true,
          permission: Permissions.values.invoiceView,
          title: i18n('metaTitle.invoice')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'invoiceView',
        path: '/invoice/:id',
        component: InvoiceViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.invoiceView,
          title: i18n('metaTitle.viewInvoice') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
