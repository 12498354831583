import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-store';
import { IamService } from '@/modules/iam/iam-service';

export class StoresService {
  static async enable(ids) {
    return IamService._changeStatus(ids, false);
  }

  static async disable(ids) {
    return IamService._changeStatus(ids, true);
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation STORE_CREATE($data: StoreInput!) {
          storeCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.storeCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation STORE_UPDATE(
          $id: String!
          $data: StoreInput!
        ) {
          storeUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.storeUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation STORE_DESTROY($ids: [String!]!) {
          storeDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.storeDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation STORE_IMPORT(
          $data: StoreInput!
          $importHash: String!
        ) {
          storeImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.storeImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query STORE_FIND($id: String!) {
          storeFind(id: $id) {
            id
            authenticationUid
            disabled
            fullName
            managerName
            email
            phoneNumber
            avatar {
              name
              publicUrl
            }
            taxCard {
              name
              publicUrl
            }
            commercialRegister
            webLink
            twitterLink
            facebookLink
            instagramLink
            description
            status
            rejectionReason
            accountType
            lang
            deviceTokens
            notifications
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.storeFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query STORE_LIST(
          $filter: StoreFilterInput
          $orderBy: StoreOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          storeList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              authenticationUid
              disabled
              fullName
              managerName
              email
              phoneNumber
              avatar {
                name
                publicUrl
              }
              taxCard {
                name
                publicUrl
              }
              commercialRegister
              webLink
              twitterLink
              facebookLink
              instagramLink
              description
              status
              rejectionReason
              accountType
              lang
              deviceTokens
              notifications
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.storeList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query STORE_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          storeAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.storeAutocomplete;
  }
}
