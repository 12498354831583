//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { I18nUtil } from '@/shared/i18n/i18n-util';
import i18n from '@/vueI18n';
import { getLanguages, setLanguageCode } from '@/i18n';
import Quasar from 'quasar';

export default {
  name: 'app-i18n-flags',

  mounted() {
    if (localStorage.getItem('language') == 'en') {
      document.getElementById('English').style.display = 'none';
    } else if (localStorage.getItem('language') == 'ar') {
      document.getElementById('العربية').style.display = 'none';
    } else if (localStorage.getItem('language') == '') {
      localStorage.setItem('language', 'en');
    }
  },

  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    languages() {
      return getLanguages();
    },
    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },

  methods: {
    ...mapActions({
      changeCurrentLanguageCode: 'layout/changeCurrentLanguageCode',
    }),
    // doChangeLanguage(language) {
    //   if (language != localStorage.getItem('language')) {
    //     I18nUtil.doChangeLanguage(language);
    //   }
    // },
    doChangeLanguage(language) {
      i18n.locale = language;
      localStorage.setItem('language', language);
      setLanguageCode(language)
      this.changeCurrentLanguageCode(language)

      // const { lang } = this.$route.params;
      // let route = '';
      // if (lang && (lang === 'ar' || lang === 'en')) {
      //   route = `/${language}${this.$route.fullPath.substring(3)}`;
      // } else {
      //   route = `/${language}${this.$route.fullPath}`;
      // }

      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          console.log(lang);
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          console.log(lang);
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      // this.$router.push(route);
      // I18nUtil.doChangeLanguage(language);
    },
  },
  watch: {
    currentLanguageCode(val) {
      if (val == 'en') {
        document.getElementById('English').style.display = 'none';
        document.getElementById('العربية').style.display = 'block';
      } else if (val == 'ar') {
        document.getElementById('العربية').style.display = 'none';
        document.getElementById('English').style.display = 'block';
      } else if (val == '') {
        localStorage.setItem('language', 'en');
      }
    }
  }
};
