import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import { StoresService } from '@/modules/stores/stores-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
  },

  mutations: {
    // adds Vuexfire built-in mutations
    ...vuexfireMutations,
    VUEXFIRE_FIND_SUCCESS(state) {
      state.loading = false;
    },

    CHANGE_STATUS_STARTED(state) {
      state.loading = true;
    },

    CHANGE_STATUS_ERROR(state) {
      state.loading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
  },

  actions: {
    doFindByVuexfire: firestoreAction(async (context, id) => {
      try {
        context.commit('FIND_STARTED');

        const customSerializer = (doc) => {
          const data = FirebaseRepository.mapDocument(doc)
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }

        const db = firebase.firestore()
        const UserQuery = db.collection('user').doc(id)
          
        await context.bindFirestoreRef(
          'record',
          UserQuery,
          { serialize: customSerializer }
        )
        
        context.commit('VUEXFIRE_FIND_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        context.commit('FIND_ERROR');
        routerAsync().push('/store');
      }
    }),
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await StoresService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/store');
      }
    },

    async doToggleStatus({ getters, commit, dispatch }) {
      try {
        const record = getters.record;

        commit('CHANGE_STATUS_STARTED');

        const isEnabling = !!record.disabled;

        if (isEnabling) {
          await StoresService.enable([record.id]);
        } else {
          await StoresService.disable([record.id]);
        }

        if (isEnabling) {
          Message.success(i18n('stores.doEnableSuccess'));
        } else {
          Message.success(i18n('stores.doDisableSuccess'));
        }

        dispatch('doFind', record.id);
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_ERROR');
      }
    },
  },
};
