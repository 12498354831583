const ar = {
  common: {
    "at": " فى ",
    or: 'أو',
    cancel: 'إلغاء',
    reset: 'إلغاء',
    save: 'حفظ',
    saveChanges: 'حفظ التغيرات',
    search: 'بحث',
    edit: 'تعديل',
    remove: 'إزالة',
    new: 'جديد',
    export: 'تصدير إلى Excel',
    noDataToExport: 'لا توجد بيانات للتصدير',
    import: 'استيراد',
    discard: 'تجاهل',
    yes: 'نعم',
    no: 'لا',
    pause: 'توقف',
    areYouSure: 'هل أنت متأكد للإزالة ؟',
    view: 'عرض',
    destroy: 'حذف',
    mustSelectARow: 'يجب إختيار صف',
    confirm: 'تأكيد ',
    submit: 'تأكيد',
    doYouWantToDeleteThisPlace: 'هل تريد حذف هذا المكان؟',
    doYouWantToDeleteThisPlan: 'هل تريد حذف هذه الخطة؟',
    doYouWantToRemoveThisPlace: 'هل تريد إزالة هذا المكان؟',
    pleaseSetTimeToThisPlace: 'يرجى تحديد الوقت بالدقائق لهذا المكان',
    yesDelete: 'نعم ، احذف',
    noThanks: 'لا، شكرا',
    searchByCity: 'بحث بإسم المدينة',
    FilterByCity: 'فلتر حسب اسم المدينة',
    searchByRegion: 'بحث بإسم المنطقة',
    FilterByRegion: 'فلتر حسب اسم المنطقة',
    // FilterByCity: 'تصفية حسب اسم المدينة',
    noResults: 'لا توجد نتائج',
    next: 'التالى',
    back: 'رجوع',
    daysNumber: 'عدد الأيام',
    viewMore: 'عرض المزيد',
    delete: 'حذف',
    setTime: 'ضبط الوقت',
    selectImage: 'أختر صورة',
    createdBy: 'تم الإنشاء بواسطة',
    updatedBy: 'تم التحديث بواسطة',
    createdAt: 'تم الإنشاء في',
    updatedAt: 'تم التحديث في',
    createdAtRange: 'تم الإنشاء في',
    all: 'الكل',
    noImgInPlace: 'لا توجد صور لهذا المكان',
    accept: 'قبول',
    ignore: 'تجاهل',
    reject: 'رفض',
    available: 'متاح',
    unavailable: 'غير متاح',
    action: 'الإجراء',
    actions: 'الإجراءات',
    arabic: 'عربي',
    english: 'إنجليزي',
    "title": "العنوان",
    "description": "الوصف",
    "uploadPhoto": "حمل الصورة",
    "uploadNewPicture": "تحميل صورة جديدة",
    "uploadPicture": "حمل الصورة",

    "createNewAccount": "إنشاء حساب جديد",
    "publish": "انشر",
    "name": "الاسم",
    "email": "البريد الالكترونى",
    "phone": "الهاتف",
    "message": "الرسالة",
    "address": "العنوان",
    "phoneNumber": "رقم الهاتف",
    "emailAddress": "البريد الاكترونى",
    "spaceArea": "المساحة",
    "firstName": "الاسم الاول",
    "lastName": "اللقب",
    "fullName": "الاسم",
    "joined": "اشترك منذ",
    "monthAgo": "شهر",
    "monthsAgo": "اشهر",
    "joinedThisMonth": "اشترك هذا الشهر",
    "status": "الحالة",
    "postStatus": "حالة المنشور",
    "views": "مشاهدات",
    "uploadDrugFile": "حمل ملف الادوية",
    "searchInDrugs": "ابحث فى الادوية",
    "id": "الرقم",
    "viewDetails": "مزيد من التفاصيل",
    "post": "منشور",
    "profile": "الملف الشخصى",
    "logout": "الخروج",
    "deleteThisUser": "امسح هذا المستخدم؟",
    "disabled": "غير متاح",
    "enabled": "متاح",
    "deleteThisAdmin": "امسح هذا المدير",
    "role": "الدور",
    "profilePicture": "الصورة الشخصية",
    "time": "التوقيت",
    "signature": "توقيع",
    "undo": "تراجع",
    "enterThePinCode": "من فضلك أدخل الرقم السري",
    "pinCodePlaceHolder": "أكتب الرقم السري هنا",
    "close": "إغلاق",
    "attachFile": "إرفاق ملف",
    "approve": "اعتماد",
    "apply": "تطبيق",
    "destroyAll": "حذف الكل",
    "continue": "التالي",
    "previous": "السابق",
    "comingSoon": " قريبـــــا",
    "underConstruction": "هذا الموقع لا يزال قيد الإنشاء",
    "today": "اليوم",
    "earlier": "سابقاً",
    "day": "اليوم",
    "date": "التاريخ",
    "print": "طباعة",
    "milady": "ميلادي",
    "hijri": "هجري",
    "add": "أضف",
    "send": "أرسال",
    "ex": "مثال",
    "rePublish": "إعادة النشر",
    "mins": "دقيقة",
    "loading": " جاري التحميل... ",
    "searching": " جاري البحث... ",
    "to": "إلي",
    "congratulations": "مبــروك!",
    "attachedFile": "الملف المرفق",
    "addQualification": "إضافة مؤهل",
    "addCourse": "إضافة دورة",
    "other": "آخرى",
    "chooseDate": "اختر التاريخ",
    "employee": " موظف ",
    "employees": " موظفين ",
    "addNote": "اضف ملاحظة",
    "successfully": "بنجاح",
    "updatedMaximumNumber": "تحديث العدد الأقصى",
    "noteAdded": "تمت إضافة ملاحظتك",
    "submitForm": "إرسال نموذج",
    "submitRequest": "إرسال طلب",
    "history": "السجل",
    "create": "إنشاء",
    "update": "تحديث",
    "from": "من",
    "subject": "الموضوع",
    "reply": "رد",
    "ticketComplete": "اكتملت التذكرة",
    "showDetails": "عرض التفاصيل",
    "stop": "توقف",
    "readMore": "قراءة المزيد",
    "readLess": "قراءة أقل",
    "forward": "إعادة توجيه",
    "done": "تم",
    "features": "المميزات",
    "deleteThisItem": "هل تريد حذف هذا العنصر؟",
    "page": "صفحة",
    "preview": "معاينة",
    "total": "الإجمالي",
    "exportationItems": "العناصر المصدرة",
    "items": "العناصر",
    "createMainData": "إنشاء البيانات الأساسية",
    "addDetails": "إضافة التفاصيل",
    "addParts": "إضافة أجزاء المنتج",
    "partsSelected": "الأجزاء التي تم اختيارها",
    "chooseYourParts": "إختر القطع",
    "year": "سنة",
    "qrCode": "رمز الاستجابة السريعة",
    "url": "Url",
    "finish": "إنهاء",
    "error": "خطأ",
    "tryAgain": "حاول مرة أخري",
    "nextItem": "العنصر التالي",
    "startExport": "ابدأ التصدير",
    "scanItems": "فحص العنصر",
    "scan": "فحص",
    "waitingSellerCheck": "في انتظار فحص البائع",
    "distributorName": " اسم الموزع ",
    "bills": "الفواتير",
    "barcode": "الباركود",
    "printBarcode": "طباعة الباركود",
    "chooseOperation": "اختر العملية",
    "generatePin": "إنشاء رمز PIN",
    "addDistributor": "إضافة موزع",
    "updateBrowser": "يرجى تحديث المتصفح الخاص بك إلى أحدث إصدار.",
    "notifyUpdateBrowser": "متصفح الويب الخاص بك ({0}) قديم. قم بتحديث المستعرض الخاص بك لمزيد من الأمان والسرعة وأفضل تجربة على هذا الموقع.",
    "generate": "إنشاء رموز"
  },
  empty: {
    title: 'لا توجد بيانات لعرضها',
    damin: 'لا يوجد مشرفين',
    client: 'لا يوجد عملاء',
    company: 'لا توجد شركات',
    guide: 'لا يوجد مرشيدين سياحيين',
    transaction: 'لا توجد معاملات',
    placeType: 'Places types is empty',
    city: 'لا توجد مدن',
    region: 'لا توجد مناطق',
    wallet: 'لا توجد محفظة',
    cycle: 'Cycle is empty',
    place: 'لا توجد اماكن مسجلة',
    trips: 'لا توجد رحلات',
    plans: 'لا توجد خطط',
    "offer": " لم يتم تحميل اى عروض بعد",
    "store": "لا توجد متاجر",
  },
  app: {
    title: 'سارية',
    ConfirmEmailAddress: 'تأكيد عنوان البريد الإلكترونى'
  },
  tables: {
    admins: {
      firstName: 'الأسم الأول ',
      lastName: 'الأسم الأخير',
      phoneNumber: 'رقم الجوال',
      email: 'البريد الإلكتروني',
      role: 'الدور',
      createdAt: 'إنشاء فى',
      status: 'الحالة',
      action: 'الامر ',
      placeName: 'إسم الموقع',
      searchPlace: 'بحث بإسم الموقع',
      search: 'بحث',
      submit: 'تأكيد'
    },
  },

  metaTitle: {
    mainTitle: ' لوحة التحكم ',
    signIn: 'سارية | تسجيل الدخول ',
    signUp: ' سارية | إنشاء حساب ',
    forgetPass: ' سارية | نسيت كلمة المرور ',
    emailUnverified: ' سارية | تأكيد الحساب الشخصي',
    emptyPermissions: ' سارية | إذن الدخول للحساب ',
    authDahsboard: 'سارية | لوحة التحكم ',
    authEdit: ' سارية | تعديل الملف الشخصي ',
    authSetting: ' سارية | الإعدادات  ',
    admins: ' سارية | قاعدة البيانات | المشرفين ',
    newAdmins: ' سارية | قاعدة البيانات | المشرفين | مشرف  جديد ',
    editAdmin: ' سارية | قاعدة البيانات | المشرفين | تعديل ',
    viewAdmin: ' سارية | قاعدة البيانات | المشرفين | عرض  ',
    users: ' سارية | قاعدة البيانات | العملاء ',
    viewUser: ' سارية | قاعدة البيانات | العملاء | عرض  ',
    place: 'سارية | موقع  ',
    newPlace: ' سارية | موقع | إضافة موقع ',
    editPlace: ' سارية | موقع | تعديل ',
    viewPlace: ' سارية | موقع | عرض ',
    newNotification: ' سارية | موقع | الإشعارات ',
  },

  entities: {
    sideMenu: {
      dashboard: 'لوحة التحكم',
      database: 'قاعدة البيانات',
      users: 'العملاء',
      admins: 'المشرفين',
      guides: 'المرشدين السياحيين',
      clients: 'العملاء',
      companies: 'الشركات',
      transactions: 'المعاملات',
      wallet: 'المحفظة',
      cycle: 'الدورات',
      trips: 'الرحلات',
      plans: 'الخطط',
      addLocationZone: 'إضافة موقع ',
      RegisterPlaces: 'المواقع المسجلة',
      Setting: 'الإعدادات',
      changeLanguage: 'تغيير اللغة',
      notificationLanguage: 'لغة الإشعارات',
      selectLanguages: 'حدد اللغات',
      changeTheme: 'تغيير لون الإطار '
    },
    dashboard: {
      projection: 'تخطيط',
      visitors: 'الزائرين',
      usersTable: 'جدول العملاء',
      adminsTable: 'جدول المشرفين',
      addNewAdmin: 'إضافة مشرف جديد',
      registerPlaces: 'المواقع المسجلة',
      chooseLanguage: 'إختار اللغه',
      profile: 'الملف الشخصي',
      Fname: 'الإسم الاول',
      Lname: 'الإسم الأخير',
      phoneNumber: 'رقم الجوال',
      email: 'البريد الإلكتروني'
    },
    splash: {
      welcome: 'مرحبا بك فى لوحة التحكم الخاصة بسارية',
      getStarted: 'هيا بنا نبدأ',
      LOGIN: 'تسجيل الدخول',
      welcBack: 'مرحبا بالرجوع  ',
      loginAccount: 'تسجيل الدخول الى حسابك الشخصي',
      pleaseValid: 'من فضلك ادخل البريد الإلكتروني ورقم المرور لستجيل الدخول',
      orLoginWith: 'أو قم بتسجيل الدخول من خلال ',
      dontHaveAccount: 'ليس لديك حساب شخصي ؟'
    },
    signup: {
      createNew: 'إنشاء حساب جديد',
      pleaseEnter: 'من فضلك قم بادخال بريد الكترونى ورقم المرور لانشاء حساب',
      signUpUp: 'إنشاء حساب جديد',
      signUp: 'تسجل ',
      alreadyMember: 'بالفعل عضو ؟',
      signIn: ' تسجيل دخول'
    },

    guide: {
      name: 'المرشدين السياحيين',
      label: 'المرشدين السياحيين',
      menu: 'المرشدين السياحيين',
      exporterFileName: 'trip_export',
      list: {
        menu: 'المرشدين السياحيين',
        title: 'المرشدين السياحيين',
      },
      create: {
        success: 'Tour guide saved successfully',
      },
      update: {
        success: 'Tour guide updated successfully',
      },
      destroy: {
        success: 'Tour guide deleted successfully',
      },
      destroyAll: {
        success: 'Tour guide(s) deleted successfully',
      },
      edit: {
        title: 'Edit Tour guide',
      },
      new: {
        title: 'Add Tour guide',
      },
      view: {
        title: 'View Tour guide',
      },
      fields: {
        id: 'Id',
        avatar: 'آفاتار',
        fullName: 'الإسم',
        phoneNumber: 'رقم الهاتف',
        accountType: 'نوع الحساب',
        rate: 'التقييم',
        pricePerHour: 'السعر بالساعة',
        isMale: 'Is ذكر',
        gender: 'الجنس',
        male: 'ذكر',
        female: 'أنثى',
        availableNow: 'متاح الآن',
        languages: 'اللغات',
        availableDays: 'الأيام المتاحة',
        reviews: 'التعليقات',
        location: 'الموقع الجغرافي',
        latitude: 'خط العرض',
        longitude: 'خط الطول',
        currency: 'العملة',
        capacity: 'السعة',
        companyId: 'الشركة',
        branchId: 'الفرع',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {
        'accountType': {
          'freelance': 'عمل حر',
          'company': 'تابع لشركة',
        },
      },
      placeholder: {},
      importer: {
        title: 'Import Tour guide',
        fileName: 'trip_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    client: {
      name: 'العملاء',
      label: 'العملاء',
      menu: 'العملاء',
      exporterFileName: 'client_export',
      list: {
        menu: 'العملاء',
        title: 'العملاء',
      },
      create: {
        success: 'Client saved successfully',
      },
      update: {
        success: 'Client updated successfully',
      },
      destroy: {
        success: 'Client deleted successfully',
      },
      destroyAll: {
        success: 'Client(s) deleted successfully',
      },
      edit: {
        title: 'Edit Client',
      },
      new: {
        title: 'Add Client',
      },
      view: {
        title: 'View Client',
      },
      fields: {
        id: 'Id',
        fullName: 'الإسم',
        email: 'البريد الإليكتروني',
        phoneNumber: 'رقم الهاتف',
        avatar: 'الصورة الشخصية',
        lang: 'اللغة',
        points: 'التقاط',
        rate: 'التقييم',
        deviceTokens: 'Device Tokens',
        notifications: 'الإشعارات',
        plans: 'الخطط المحفوظة',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Clients',
        fileName: 'client_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    company: {
      name: 'الشركات',
      label: 'الشركات',
      menu: 'الشركات',
      exporterFileName: 'company_export',
      list: {
        menu: 'الشركات',
        title: 'الشركات',
      },
      create: {
        success: 'Company saved successfully',
      },
      update: {
        success: 'Company updated successfully',
      },
      destroy: {
        success: 'Company deleted successfully',
      },
      destroyAll: {
        success: 'Companies deleted successfully',
      },
      edit: {
        title: 'Edit Company',
      },
      new: {
        title: 'Add Company',
      },
      view: {
        title: 'عرض الشركة',
      },
      fields: {
        id: 'Id',
        logo: 'شعار الشركة',
        name: 'إسم الشركة',
        description: 'الوصف',
        commercialRegister: 'السجل التجاري',
        license: 'الترخيص',
        email: 'البريد الإليكتروني',
        websiteLink: 'الموقع الكتروني',
        facebookLink: 'الفيس بوك',
        twitterLink: 'تويتر',
        instagramLink: 'إنستجرام',
        legalAuthority: 'السلطة القانونية',
        rate: 'التقييم',
        languages: 'اللغات',
        reviews: 'التعليقات',
        phoneNumber: 'رقم الهاتف',
        countryCode: 'كود الدولة',
        countryId: 'الدولة',
        cityId: 'المدينة',
        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {
        'legalAuthority': {
          'owner': 'مالك',
          'authorized': 'مفوض',
        },
      },
      placeholder: {},
      importer: {
        title: 'Import Companies',
        fileName: 'company_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    wallet: {
      name: 'المحفظة',
      label: 'المحفظة',
      menu: 'المحفظة',
      exporterFileName: 'wallet_export',
      list: {
        menu: 'المحفظة',
        title: 'المحفظة',
      },
      create: {
        success: 'Wallet saved successfully',
      },
      update: {
        success: 'Wallet updated successfully',
      },
      destroy: {
        success: 'Wallet deleted successfully',
      },
      destroyAll: {
        success: 'Wallet(s) deleted successfully',
      },
      edit: {
        title: 'Edit Wallet',
      },
      new: {
        title: 'Add Wallet',
      },
      view: {
        title: 'View Wallet',
      },
      fields: {
        id: 'Id',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Wallet',
        fileName: 'wallet_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    cycle: {
      name: 'الدورات',
      label: 'الدورات',
      menu: 'الدورات',
      exporterFileName: 'cycle_export',
      list: {
        menu: 'الدورات',
        title: 'الدورات',
      },
      create: {
        success: 'Cycle saved successfully',
      },
      update: {
        success: 'Cycle updated successfully',
      },
      destroy: {
        success: 'Cycle deleted successfully',
      },
      destroyAll: {
        success: 'Cycle(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cycle',
      },
      new: {
        title: 'Add Cycle',
      },
      view: {
        title: 'View Cycle',
      },
      fields: {
        id: 'Id',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Cycle',
        fileName: 'cycle_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    transaction: {
      name: 'المعاملات',
      label: 'المعاملات',
      menu: 'المعاملات',
      exporterFileName: 'transaction_export',
      list: {
        menu: 'المعاملات',
        title: 'المعاملات',
      },
      create: {
        success: 'Transaction saved successfully',
      },
      update: {
        success: 'Transaction updated successfully',
      },
      destroy: {
        success: 'Transaction deleted successfully',
      },
      destroyAll: {
        success: 'Transaction(s) deleted successfully',
      },
      edit: {
        title: 'Edit Transaction',
      },
      new: {
        title: 'Add Transaction',
      },
      view: {
        title: 'View Transaction',
      },
      fields: {
        id: 'Id',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {},
      placeholder: {},
      importer: {
        title: 'Import Transactions',
        fileName: 'transaction_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    place: {
      name: 'المواقع',
      label: 'المواقع',
      menu: 'المواقع',
      ADD: 'إضافة',
      Next: 'التالي',
      // placeholder:"أدخل موقعك الحالى " ,
      exporterFileName: 'place_export',
      list: {
        menu: 'المواقع',
        title: 'المواقع',
      },
      create: {
        success: 'تم حفظ الموقع بنجاح ',
      },
      update: {
        success: 'تم حفظ الموقع بنجاح',
      },
      destroy: {
        success: 'تم مسح الموقع بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المكان (المواقع) بنجاح',
      },
      edit: {
        title: 'تعديل الموقع',
      },
      fields: {
        id: 'رقم المكان',
        'lat': 'خط الطول',
        'lng': 'خط العرض ',
        'radiusRange': 'نصف القطر',
        'radius': 'نصف القطر ',
        'color': 'لون',
        'strokeColor': 'لون الدائرة',
        'strokeWidthRange': 'عرض الإطار',
        'strokeWidth': 'سمك الدائرة',
        'fillColor': 'اللون السائد',
        createdAt: 'إنشاء فى',
        updatedAt: 'تعديل فى ',
        createdAtRange: 'أنشئت في',
        'placeName': 'إسم الموقع',
        'region': 'المنطقة',
        'city': 'المدينة',
        'country': 'البلد',
        description: 'الوصف',
        placePictures: 'صور مكان',
        placeType: 'نوع المكان',
        notifications: 'الإشعارات',
      },
      enumerators: {
        placeType: {
          mosques: 'جوامع',
          mountains: 'موقع جغرافي',
          market: 'اسواق',
          playground: 'ملعب',
          museums: 'متاحف',
          events: 'حدث',
          cafe: 'كافيه',
        },
      },
      placeholder: {
        map: 'أدخل موقعك الحالي',
        "placeDescription": "اكتب وصف المكان هنا",
        "placeDescriptionEnglish": "اكتب وصف المكان باللغة الإنجليزية هنا",
        "placeDescriptionArabic": "اكتب وصف مكان باللغة العربية هنا",
        "placeNameEnglish": "اسم المكان بالانجليزية",
        "placeNameArabic": "اسم المكان بالعربي",
      },
      new: {
        title: 'إضافة موقع',
      },
      view: {
        title: 'عرض الموقع',
      },
      importer: {
        title: 'استيراد الأماكن',
        fileName: 'place_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    placeType: {
      name: 'نوع الأماكن',
      label: 'نوع الأماكن',
      menu: 'نوع الأماكن',
      exporterFileName: 'placeType_export',
      list: {
        menu: 'نوع الأماكن',
        title: 'نوع الأماكن',
      },
      create: {
        success: 'تم حفظ نوع المكان بنجاح',
        success2: 'تم إنشاء نوع مكان جديد بنجاح',
      },
      update: {
        success: 'تم تحديث نوع المكان بنجاح',
      },
      destroy: {
        success: 'تم حذف نوع المكان بنجاح',
      },
      destroyAll: {
        success: 'تم حذف نوع (أنواع) المكان بنجاح',
      },
      edit: {
        title: 'تعديل نوع المكان',
      },
      new: {
        title: 'أضف نوع مكان جديد',
      },
      view: {
        title: 'عرض نوع المكان',
      },
      fields: {
        id: 'Id',
        name: 'الاسم',
        icon: 'أيقونة',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {
        placesType: {
          mosque: 'مسجد',
          museum: 'متحف',
          playground: 'ملعب',
          events: 'حدث',
          mountains: 'موقع جغرافي',
          market: 'سوق',
          cafe: 'كافيه',
        },
      },
      placeholder: {
        chooseIcon: 'اختر أيقونة',
      },
      importer: {
        title: 'استيراد نوع الأماكن',
        fileName: 'PlaceType_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    city: {
      name: 'المدن',
      label: 'المدن',
      menu: 'المدن',
      exporterFileName: 'placeType_export',
      list: {
        menu: 'المدن',
        title: 'المدن',
      },
      create: {
        success: 'تم حفظ المدينة بنجاح',
        success2: 'تم إنشاء نوع مكان جديد بنجاح',
      },
      update: {
        success: 'تم تحديث المدينة بنجاح',
      },
      destroy: {
        success: 'تم حذف المدينة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المدن بنجاح',
      },
      edit: {
        title: 'تعديل المدينة',
      },
      new: {
        title: 'إضافة مدينة جديدة',
      },
      view: {
        title: 'عرض المدينة',
      },
      fields: {
        id: 'Id',
        name: 'إسم المدينة',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {
        placesType: {
          mosque: 'مسجد',
          museum: 'متحف',
          playground: 'ملعب',
          events: 'حدث',
          mountains: 'موقع جغرافي',
          market: 'سوق',
          cafe: 'كافيه',
        },
      },
      placeholder: {
        chooseIcon: 'اختر أيقونة',
      },
      importer: {
        title: 'استيراد مدن',
        fileName: 'PlaceType_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    region: {
      name: 'المناطق',
      label: 'المناطق',
      menu: 'المناطق',
      exporterFileName: 'placeType_export',
      list: {
        menu: 'المناطق',
        title: 'المناطق',
      },
      create: {
        success: 'تم حفظ المنطقة بنجاح',
        success2: 'تم إنشاء نوع مكان جديد بنجاح',
      },
      update: {
        success: 'تم تحديث المنطقة بنجاح',
      },
      destroy: {
        success: 'تم حذف المنطقة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المناطق بنجاح',
      },
      edit: {
        title: 'تعديل المنطقة',
      },
      new: {
        title: 'إضافة مدينة جديدة',
      },
      view: {
        title: 'عرض المنطقة',
      },
      fields: {
        id: 'Id',
        name: 'إسم المنطقة',
        code: 'Code',

        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
      },
      enumerators: {
        placesType: {
          mosque: 'مسجد',
          museum: 'متحف',
          playground: 'ملعب',
          events: 'حدث',
          mountains: 'موقع جغرافي',
          market: 'سوق',
          cafe: 'كافيه',
        },
      },
      placeholder: {
        chooseIcon: 'اختر أيقونة',
      },
      importer: {
        title: 'استيراد مناطق',
        fileName: 'PlaceType_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    notification: {
      name: 'الإشعارات',
      label: 'الإشعارات',
      menu: 'الإشعارات',
      exporterFileName: 'notification_export',
      list: {
        menu: 'الإشعارات',
        title: 'الإشعارات',
      },
      tabs: {
        all: 'كل الإشعارات',
        company: 'الشركات',
        guide: 'مرشد سياحي',
        store: 'متجر',
        offers: 'عروض المتاجر',
      },
      create: {
        success: 'تم حفظ الإشعار بنجاح',
      },
      update: {
        success: 'تم حفظ الإشعار بنجاح',
      },
      destroy: {
        success: 'تم مسح الإشعار بنجاح',
      },
      destroyAll: {
        success: 'Notification(s) deleted successfully',
      },
      edit: {
        title: 'تعديل الإشعار',
      },
      fields: {
        id: 'رقم الإشعار',
        'title': 'عنوان الإشعار',
        'message': 'محتوى الإشعار',
        'image': 'صوره الإشعار (إختياري)',
        'name': 'اسم الإشعار (إختياري)',
        'placeId': 'PlaceId',
        createdAt: 'انشاء فى ',
        updatedAt: 'تعديل فى ',
        createdAtRange: 'إنشاء في',
      },
      enumerators: {

      },
      new: {
        title: ' الإشعارات',
        hint: 'يرجى كتابة الإشعار المناسب لهذا المكان لكل لغة',
      },
      view: {
        title: 'عرض الإشعار',
      },
      importer: {
        title: 'Import Notifications',
        fileName: 'notification_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    trip: {
      name: 'الرحلة',
      label: 'الرحلات',
      menu: 'الرحلات',
      viewTrip: 'عرض الرحلة',
      viewReason: 'عرض السبب',
      reason: 'السبب',
      exporterFileName: 'trip_export',
      list: {
        menu: 'الرحلات',
        title: 'الرحلات',
      },
      create: {
        success: 'Trip saved successfully',
      },
      update: {
        success: 'Trip saved successfully',
      },
      destroy: {
        success: 'Trip deleted successfully',
      },
      destroyAll: {
        success: 'Trip(s) deleted successfully',
      },
      edit: {
        title: 'Edit Trip',
      },
      fields: {
        id: 'Id',
        city: 'المدينة',
        plan: 'الخطة',
        client: 'العميل',
        tourGuide: 'المرشد السياحي',
        company: 'الشركة',
        paymentMethod: 'طريقة الدفع / السداد',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        // : '',
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      status: {
        completed: 'الرحلات المكتملة',
        planned: 'الرحلات المجدولة',
        canceled: 'الرحلات الملغية',
      },
      enumerators: {},
      placeholder: {},
      new: {
        title: 'Add الموقع الجغرافي zone',
      },
      view: {
        title: 'عرض الرحلة',
      },
      importer: {
        title: 'Import Trips',
        fileName: 'trip_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    plan: {
      name: 'خطة',
      label: 'الخطط',
      menu: 'الخطط',
      exporterFileName: 'plan_export',
      list: {
        menu: 'الخطط',
        title: 'الخطط',
      },
      days: {
        dayOne: 'اليوم الأول',
        dayTwo: 'اليوم الثاني',
        dayThree: 'اليوم الرابع',
        dayFour: 'اليوم الرابع',
        dayFive: 'اليوم الخامس',
        daySix: 'اليوم السادس',
        daySeven: 'اليوم السابع',
      },
      tripDays: {
        oneDay: 'يوم واحد',
        twoDays: 'يومين',
        ThreeDays: 'ثلاثة أيام',
        FourDays: 'أربعة أيام',
        FiveDays: 'خمسة أيام',
        SixDays: 'ستة أيام',
        SevenDays: 'سبعة أيام',
        moreThanDay: 'أكثر من يوم',
      },
      form: {
        enterInformation: 'أدخل المعلومات التالية لإنشاء خطتك',
        durationTitle: 'يرجى تحديد مدة الرحلة',
        pleaseDragThePlaces: 'الرجاء سحب الأماكن التي تريد إضافتها في خطتك',
        placesInThisCity: 'أماكن في هذه المدينة',
        yourPlan: 'خطتك',
        dragHere: 'اسحب الأماكن هنا',
        pleaseSelectPhotoToEachPlace: 'يرجي تحديد الصور لكل مكان',
        selectPhotoToThisPlace: 'حدد صور لهذا المكان',
        editPlan: 'تعديل هذه الخطة',
        deletePlan: 'حذف هذه الخطة',
        reorderPlan: 'إعادة ترتيب الأماكن',
        disablePlan: 'تعطيل هذه الخطة',
        placesInThisPlan: 'الأماكن في هذه الخطة',
        reordersPlaces: 'إعادة ترتيب الأماكن في هذه الخطة',
        setTimeToThisPlace: 'حدد الوقت لهذا المكان',
      },
      disable: {
        success: 'تم تعطيل الخطة بنجاح',
      },
      enable: {
        success: 'تم تفعيل الخطة بنجاح',
      },
      create: {
        success: 'تم حفظ الخطة بنجاح',
      },
      update: {
        success: 'تم حفظ الخطة بنجاح',
      },
      destroy: {
        success: 'تم حذف الخطة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف الخطط بنجاح',
      },
      edit: {
        title: 'تعديل الخطة',
      },
      fields: {
        id: 'Id',
        region: 'المنطقة',
        city: 'المدينة',
        numberOfPlans: 'عدد الخطط',
        dateOfCreation: 'تاريخ الإنشاء',
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',

        name: 'اسم الخطة',
        description: 'الوصف',
        startCityId: 'بداية المدينة',
        startCity: 'بداية المدينة',
        type: 'نوع الخطة',
        programs: 'البرامج',
        numberOfDays: 'عدد الإيام',

      },
      enumerators: {
        planType: {
          default: 'خطة افتراضية',
          custom: 'خطة مخصصة',
        },
      },
      placeholder: {
        chooseRegions: 'أختر المناطق',
        chooseRegion: 'أختر المنطقة',
        chooseCities: 'أختر المدن',
        chooseCity: 'أختر المدينة',
      },
      new: {
        title: 'إضافة خطة جديدة',
        shortTitle: 'إضافة خطة',
      },
      view: {
        title: 'عرض الخطة',
        title2: 'يمكنك تعديل خطتك وحذفها وإعادة ترتيبها',
        moreThan: 'أكثر من +4 أماكن في هذه الخطة',
      },
      importer: {
        title: 'Import plans',
        fileName: 'plan_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    offer: {
      name: 'offer',
      label: 'Offers',
      menu: 'Offers',
      exporterFileName: 'offer_export',
      list: {
        menu: 'Offers',
        title: 'Offers',
      },
      create: {
        success: 'Offer saved successfully',
      },
      update: {
        success: 'Offer saved successfully',
      },
      destroy: {
        success: 'Offer deleted successfully',
      },
      destroyAll: {
        success: 'Offer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Offer',
      },
      fields: {
        id: 'Id',
        'title': 'العنوان',
        'description': 'الوصف',
        "price": "السعر",
        "percentage": "النسبة المئوية",
        "oldPrice": "السعر القديم",

        "isActive": "نشط",
        "type": "نوع العرض",
        "status": "الخالة",
        "rejectionReason": "سبب الرفض",

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        "type": {
          "saryah": "من سارية",
          "store": "من المتجر"
        },
        "status": {
          "accepted": "تم القبول",
          "rejected": "مرفوض",
          "deleted": "محذوفة",
        },
      },
      new: {
        title: 'اضافة عرض جديد',
      },
      view: {
        title: "مشاهدة العرض",
      },
      step: {
        one: 'أدخل البيانات باللغة الإنجليزية',
        two: 'أدخل البيانات باللغة العربية',
        three: "ادخل الاسعار",
        four: '',
        five: '',
      },
      importer: {
        title: 'Import Offers',
        fileName: 'offer_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'تعديل الملف الشخصي',
      success: 'تم تعديل الملف الشخصي بنجاح',
    },
    createAnAccount: 'إنشاء حساب جديد',
    rememberMe: 'تذكرنى',
    forgotPassword: 'لا أتذكر  كلمة المرور ؟',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب ',
    signout: 'تسجيل الخروج',
    "google": "جوجل",
    "facebook": "فيسبوك",
    "twitter": "تويتر",
    alreadyHaveAnAccount: 'بالفعل لدى حساب ؟ تسجيل دخول',
    signinWithAnotherAccount: 'تسجيل دخول بحساب اخر ',
    emailUnverified: {
      message: `قم بتاكيد بريد الإلكتروني  <strong>{0}</strong> للاستكمال`,
      submit: `إعادة إرسال بريد الكترونى`,
      verifyAccount: 'تأكيد حسابك الشخصي'
    },
    emptyPermissions: {
      message: `ليس لديك صلاحية الان يرجى الانتظار لحين موافقة الادمن`,
    },
    AccountPermission: 'إذن الدخول للحساب الشخصي',
    authForgetReset: 'أدخل بريدك الإلكتروني هنا  ',
    requestReset: 'إرسال',
    forgetPassword: 'نسيت كلمة المرور ؟',
    forgetText: ' أدخل بريدك الإلكتروني الذى تستخدمه لحسابك و نحن سوف نرسل لك رابط إاعاده تعيين كلمة المرور ',
    cancel: 'إالغاء',
    passwordResetEmail: {
      message: 'إرسال كلمة المرور ',
      error: `البريد الإلكتروني غير معروف `,
    },
    passwordReset: {
      message: 'إعادة تعين كلمة المرور',
    },
    emailAddressVerificationEmail: {
      error: `البريد الإلكتروني غير معروف `,
    },
    verificationEmailSuccess: `تم إرسال البريد الاكترونى التعرفى `,
    passwordResetEmailSuccess: `تم إرسال كلمة المرور بنجاح`,
    passwordResetSuccess: `تم تعديل كلمة المرور بنجاح`,
    verifyEmail: {
      success: 'تم تاكيد البريد الإلكتروني بنجاح',
      message: 'لحظة ، يتم التحقق من بريدك الإلكتروني ...',
    },
  },

  roles: {
    owner: {
      label: 'مالك',
      description: 'صلاحية الوصول الكامل إلى جميع الموارد',
    },
    editor: {
      label: 'محرر',
      description: 'صلاحية تعديل جميع الموارد',
    },
    viewer: {
      label: 'مشاهد',
      description: 'صلاحية مشاهدة جميع الموارد',
    },
    auditLogViewer: {
      label: 'مشاهدة سجل التدقيق',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'مشاهدة الصلاحيات',
      description: `الوصول الكامل لإدارة أدوار المستخدمين`,
    },
    entityEditor: {
      label: 'صلاحية التعديل',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'صلاحية المشاهدة',
      description: 'View access to all entities',
    },
    placeEditor: {
      label: 'صلاحية تعديل الأماكن',
      description: 'Edit access to Places',
    },
    placeViewer: {
      label: 'صلاحية مشاهدة الأماكن',
      description: 'View access to Places',
    },
    cityEditor: {
      label: 'صلاحية تعديل المدن',
      description: 'Edit access to Cities',
    },
    cityViewer: {
      label: 'صلاحية مشاهدة المدن',
      description: 'View access to Cities',
    },
    regionEditor: {
      label: ' صلاحية تعديل المناطق',
      description: 'Edit access to Regions',
    },
    regionViewer: {
      label: 'صلاحية مشاهدة المناطق',
      description: 'View access to Regions',
    },
    planEditor: {
      label: 'صلاحية تعديل الخطط',
      description: 'Edit access to Plans',
    },
    planViewer: {
      label: 'صلاحية مشاهدة الخطط',
      description: 'View access to Plans',
    },
    notificationEditor: {
      label: 'صلاحية تعديل الإشعارات',
      description: 'Edit access to Notifications',
    },
    notificationViewer: {
      label: ' صلاحية مشاهدة الإشعارات',
      description: 'View access to Notifications',
    },
    clientEditor: {
      label: 'صلاحية تعديل العملاء',
      description: 'Edit access to Clients',
    },
    clientViewer: {
      label: 'صلاحية مشاهدة العملاء',
      description: 'View access to Clients',
    },
    companyEditor: {
      label: 'صلاحية تعديل الشركات',
      description: 'Edit access to Companies',
    },
    companyViewer: {
      label: 'صلاحية مشاهدة الشركات',
      description: 'View access to Companies',
    },
    transactionEditor: {
      label: 'صلاحية تعديل المعاملات',
      description: 'Edit access to Transactions',
    },
    transactionViewer: {
      label: 'صلاحية مشاهدة المعاملات',
      description: 'View access to Transactions',
    },
    cycleEditor: {
      label: 'صلاحية تعديل الدوارات',
      description: 'Edit access to Cycles',
    },
    cycleViewer: {
      label: 'صلاحية مشاهدة الدوارات',
      description: 'View access to Cycles',
    },
    walletEditor: {
      label: 'صلاحية تعديل المحفظة',
      description: 'Edit access to Wallets',
    },
    walletViewer: {
      label: 'صلاحية عرض المحفظة',
      description: 'View access to Wallets',
    },
    offerEditor: {
      label: 'صلاحية تعديل العروض',
      description: 'Edit access to Offers',
    },
    offerViewer: {
      label: 'صلاحية عرض العروض',
      description: 'View access to Offers',
    },
  },

  iam: {
    title: 'إدارة الهوية والوصول',
    menu: 'IAM',
    disable: 'تعطيل',
    disabled: 'عطًل',
    enabled: 'فعًل',
    enable: 'تفعيل',
    doEnableSuccess: 'تم تفعيل المستخدم بنجاح',
    doDisableSuccess: 'تم تعطيل المستخدم بنجاح',
    doDisableAllSuccess: 'تم تعطيل المستخدمين بنجاح',
    doEnableAllSuccess: 'تم تفعيل المستخدمين بنجاح',
    doAddSuccess: 'تم حفظ المستخدم (المستخدمين) بنجاح',
    doUpdateSuccess: 'تم حفظ المستخدم بنجاح',
    viewBy: 'عرض بواسطة',
    users: {
      name: 'users',
      label: 'المستخدمين',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح',
    },
    roles: {
      label: 'الأدوار',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح',
    },
    edit: {
      title: 'تعديل المستخدم',
    },
    new: {
      title: 'مشرف جديد',
      emailsHint: 'إفصل بين عناوين البريد الإلكتروني المتعددة باستخدام الحرف الفاصلة',
    },
    view: {
      title: 'عرض ملف المستخدم',
      activity: 'نشاطات',
    },
    importer: {
      title: 'استيراد المستخدمين',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'لمستخدم بهذا البريد الإلكتروني موجود بالفعل',
      userNotFound: 'المستخدم ليس موجود',
      disablingHimself: `لا يمكنك تعطيل نفسك`,
      revokingOwnPermission: `لا يمكنك إلغاء إذن مالكك الخاص`,
    },
  },

  user: {
    fields: {
      id: 'رقم المستخدم',
      authenticationUid: 'مصادقة Uid',
      avatars: 'الصورة',
      email: 'البريد الإلكتروني',
      emails: 'البريد الإلكتروني',
      fullName: 'الإسم كامل',
      firstName: 'الإسم الاول',
      lastName: 'الإسم الاخير',
      status: 'الحالة',
      disabled: 'معطل',
      phoneNumber: 'رقم الجوال',
      countryCode: 'كود البلد',
      lang: 'اللغة',
      role: 'الدور',
      createdAt: 'انشاء فى ',
      updatedAt: 'تعديل فى ',
      roleUser: 'دور / المستخدم',
      roles: 'الصلاحيات',
      accountType: 'نوع المستخدم',
      createdAtRange: 'أنشئت في',
      password: 'كلمة المرور',
      rememberMe: 'تذكرني',
    },
    enabled: 'مفعل',
    disabled: 'معطل',
    validations: {
      // eslint-disable-next-line
      email: 'البريد الإلكتروني $ {value} غير صالح',
    },
  },

  auditLog: {
    menu: 'سجلات التدقيق',
    title: 'سجلات التدقيق',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'افصل بين الكيانات المتعددة باستخدام الحرف الفاصلة',
    fields: {
      id: 'رقم السجل',
      timestampRange: 'فترة',
      entityName: 'كيان',
      entityNames: 'كيانات',
      entityId: 'Entity ID',
      action: ' الأوامر',
      values: 'قيم',
      timestamp: 'تاريخ',
      createdByEmail: 'البريد الإلكتروني للمستخدم',
    },
  },
  settings: {
    title: 'الإعدادات',
    menu: 'الإعدادات',
    save: {
      success: 'تم حفظ الإعدادات بنجاح. ستتم إعادة تحميل الصفحة في {0} ثانية لتصبح التغييرات نافذة المفعول.',
      success2: 'تم حفظ الإعدادات بنجاح',
    },
    companyFeesNote: 'الرجاء إدخال رسوم الشركات بالنسبة المئوية أو المال لكل رحلة',
    guideFeesNote: 'الرجاء إدخال رسوم المرشدين السياحيين بالنسبة المئوية أو المال لكل رحلة',
    fields: {
      theme: 'Theme',
      defaultLanguage: 'اللغة الافتراضية',
      notificationLanguage: 'لغات الإشعارات',
      paymentMethods: 'طرق الدفع',
      saryahFees: 'رسوم سارية',
      vatPercentage: 'ضريبة القيمة المضافة',
      percentage: 'تكلفة النسبة المئوية',
      money: 'التكلفة المحددة / الثابتة',
    },
    placeholder: {
      percentage: 'أدخل التكلفة المئوية الخاصة بك',
      money: 'أدخل التكلفة الثابتة',
    },
    paymentMethods: {
      cash: 'نقدى',
      creditCard: 'بطاقة إئتمان',
      applePay: 'آبل باي',
      googlePay: 'جوجل باي',
      stcPay: 'Stc Pay',
      paypal: 'باي بال',
      sadad: 'سداد',
      mada: 'مدى',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },

  home: {
    menu: 'الرئيسية',
    inprogressTrips: 'الرحلات الحالية',
    currentOffers: 'العروض الحالية',
    activeTourGuides: 'المرشدين النشطين',
    transactionHistory: 'سجل المعاملات',
    fields: {
      trip: 'الرحلة',
      plan: 'إسم الخطة',
      city: 'المدينة',
      company: 'الشركة',
      guide: 'المرشد السياحي',
      duration: 'مدة الرحلة',
      paymentMethod: 'طريقة الدفع',
      date: 'التاريخ',
      amount: 'التكلفة',
      rate: 'التقييم',
      offer: 'العرض',
    },
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    itemCannotBeDeleted: 'لا يمكن إتمام عملية الحذف لان هذا العنصر قيد الإستخدام',
    incorrectPinCode: 'الرقم السري غير صحيح',
    required: 'هذا الحقل مطلوب',
    minValue: 'الحد الأدنى للقيمة هو {0}',
    maxValue: 'القيمة القصوى هي {0}',
    maxCharCount: 'الرجاء استخدام الحد الأقصى لعدد الأحرف {0}',
    typeRequired: 'النوع مطلوب',
    languageRequired: 'اللغة مطلوبة',
    destinationRequired: 'الجهة مطلوبة',
    purposeRequired: 'هذا الحقل مطلوب',
    workAtRequired: 'هذا الحقل مطلوب',
    wifeNameRequired: 'الإسم مطلوب',
    dateRequired: 'التاريخ مطلوب',
    timeRequired: 'الوقت مطلوب',
    hospitalNameRequired: 'إسم المستشفي مطلوب',
    nameRequired: 'الإسم مطلوب',
    workPositionRequired: 'هذا الحقل مطلوب',
    signatureRequired: 'التوقيع مطلوب',
    birthDateRequired: 'تاريخ الميلاد مطلوب',
    relativeRelationRequired: 'صلة القرابة مطلوبة',
    placeOfBusinessRequired: 'مكان العمل مطلوب',
    placeOfResidenceRequired: 'مكان الإقامة مطلوب',
    buildingRequired: 'المبني مطلوب',
    floorRequired: 'الدور مطلوب',
    accountTypeRequired: 'نوع الحساب مطلوب',
    managerTypeRequired: 'الإدارة مطلوبة',
    invalidPhoneNumber: 'رقم الهاتف غير صالح',
    invalidNoteField: 'حقل ملاحظة غير صالح',
    invalidReasonOfPermission: 'سبب غير صالح لحقل الإذن',
    departmentRequired: 'القسم مطلوب',
    subDepartmentRequired: 'القسم الفرعي مطلوب',
    jobTitleRequired: 'المسمي الوظيفي مطلوب',
    employeeRequired: 'الموظف مطلوب',
    employeesRequired: 'الموظف مطلوب',

    recordIsFound: 'This record is already exist with the same barcode',

    backToHome: 'العودة إلى الرئيسية',
    403: `آسف ، لا يمكنك الوصول إلى هذه الصفحة`,
    404: 'عفوًا ، الصفحة التي قمت بزيارتها غير موجودة',
    500: 'عذرًا ، يبلغ الخادم عن خطأ',
    forbidden: {
      message: 'ممنوع',
    },
    validation: {
      message: 'حدث خطأ',
      placeNameRequired: 'إسم المكان مطلوب',
      placeTypeRequired: 'نوع المكان مطلوب',
      cantAdd: 'لا يمكن إضافة هذا الموظف',
      title: 'حدث خطأ',
      required: 'هذا الحقل مطلوب',
      typeRequired: 'النوع مطلوب',
      languageRequired: 'اللغة مطلوبة',
      destinationRequired: 'الجهة مطلوبة',
      purposeRequired: 'هذا الحقل مطلوب',
      workAtRequired: 'هذا الحقل مطلوب',
      wifeNameRequired: 'الإسم مطلوب',
      dateRequired: 'التاريخ مطلوب',
      timeRequired: 'الوقت مطلوب',
      hospitalNameRequired: 'إسم المستشفي مطلوب',
      nameRequired: 'الإسم مطلوب',
      workPositionRequired: 'هذا الحقل مطلوب',
      signatureRequired: 'التوقيع مطلوب',
      birthDateRequired: 'تاريخ الميلاد مطلوب',
      relativeRelationRequired: 'صلة القرابة مطلوبة',
      placeOfBusinessRequired: 'مكان العمل مطلوب',
      placeOfResidenceRequired: 'مكان الإقامة مطلوب',
      buildingRequired: 'المبني مطلوب',
      floorRequired: 'الدور مطلوب',
      accountTypeRequired: 'نوع الحساب مطلوب',
      managerTypeRequired: 'الإدارة مطلوبة',
      invalidPhoneNumber: 'رقم الهاتف غير صالح',
      invalidNoteField: 'حقل ملاحظة غير صالح',
      invalidReasonOfPermission: 'سبب غير صالح لحقل الإذن',
      departmentRequired: 'القسم مطلوب',
      subDepartmentRequired: 'القسم الفرعي مطلوب',
      jobTitleRequired: 'المسمى الوظيفي مطلوب',
      employeeRequired: 'الموظف مطلوب',
      employeesRequired: 'الموظف مطلوب',
    },
    defaultErrorMessage: 'معذرة حدث خطأ',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '$ {path} غير صالح',
      required: '$ {path} مطلوب',
      oneOf: 'يجب أن يكون $ {path} إحدى القيم التالية: $ {values}',
      notOneOf: 'يجب ألا يكون $ {path} إحدى القيم التالية: $ {values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `يجب أن يكون $ {path} $ {type}`;
      },
    },
    string: {
      length: 'يجب أن يكون $ {path} أحرف $ {length} تمامًا',
      min: 'يجب أن يكون $ {path} على الأقل $ {min} حرفًا',
      max: 'يجب أن يكون $ {path} بحد أقصى $ {max} حرفًا',
      matches: 'يجب أن يتطابق $ {path} مع ما يلي: "$ {regex}"',
      email: 'يجب أن يكون $ {path} بريدًا إلكترونيًا صالحًا',
      url: 'يجب أن يكون $ {path} عنوان URL صالحًا',
      trim: 'يجب أن يكون $ {path} عبارة عن جملة مقطوعة',
      lowercase: 'يجب أن يكون $ {path} سلسلة أحرف صغيرة',
      uppercase: 'يجب أن يكون $ {path} سلسلة أحرف كبيرة',
      selected: 'يجب تحديد $ {path}',
    },
    number: {
      min: 'يجب أن يكون $ {path} أكبر من أو يساوي $ {min}',
      max: 'يجب أن يكون $ {path} أقل من أو يساوي $ {max}',
      lessThan: 'يجب أن يكون $ {path} أقل من $ {less}',
      moreThan: 'يجب أن يكون $ {path} أكبر من $ {more}',
      notEqual: 'يجب أن لا يكون $ {path} مساويًا لـ $ {notEqual}',
      positive: 'يجب أن يكون $ {path} رقمًا موجبًا',
      negative: 'يجب أن يكون $ {path} رقمًا سالبًا',
      integer: 'يجب أن يكون $ {path} عددًا صحيحًا',
      invalid: 'يجب أن يكون $ {path} رقمًا',
    },
    date: {
      min: 'يجب أن يكون حقل $ {path} أكبر من $ {min}',
      max: 'يجب أن يكون حقل $ {path} في وقت سابق عن $ {max}',
    },
    boolean: {},
    object: {
      noUnknown: 'لا يمكن أن يحتوي حقل $ {path} على مفاتيح غير محددة في شكل الكائن',
    },
    array: {
      min: 'يجب أن يحتوي حقل $ {path} على عناصر $ {min} على الأقل',
      max: 'يجب أن يحتوي حقل $ {path} على أقل من أو يساوي عناصر $ {max}',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'رفع',
    image: 'يجب عليك تحميل صورة',
    size: 'الملف كبير جدا. الحجم الأقصى المسموح به هو {0',
    formats: `تنسيق غير صالح. يجب أن يكون '{0}'.`,
  },
  importer: {
    line: 'خط',
    status: 'الحالة',
    pending: 'قيد الانتظار',
    imported: 'مستورد',
    error: 'خطأ',
    total: `{0} مستورد ، {1} معلق و {2} مع وجود خطأ`,
    importedMessage: `تمت المعالجة {0} من {1}.`,
    noNavigateAwayMessage: 'لا تنتقل بعيدًا عن هذه الصفحة أو سيتم إيقاف الاستيراد.',
    completed: {
      success: 'اكتمل الاستيراد. تم استيراد جميع الصفوف بنجاح.',
      someErrors: 'اكتملت المعالجة ، لكن تعذر استيراد بعض الصفوف.',
      allErrors: 'فشل الاستيراد. لا توجد صفوف صالحة.',
    },
    form: {
      downloadTemplate: 'قم بتنزيل القالب',
      hint: 'أنقر أو إسحب الملف إلى هذه المنطقة للمتابعة',
    },
    list: {
      discardConfirm: 'هل أنت واثق؟ سيتم فقدان البيانات غير المستوردة.',
    },
    errors: {
      invalidFileEmpty: 'الملف فارغ',
      invalidFileExcel: 'يُسمح فقط بملفات excel (.xlsx)',
      invalidFileUpload: 'ملف غير صالح. تأكد من أنك تستخدم الإصدار الأخير من القالب.',
      importHashRequired: 'استيراد التجزئة هو مطلوب',
      importHashExistent: 'تم بالفعل استيراد البيانات',
    },
  },

  autocomplete: {
    loading: 'جار التحميل..',
  },

  imagesViewer: {
    noImage: 'لا توجد صورة',
  },

  firebaseErrors: {
    'auth/user-disabled': 'حسابك معطل',
    'auth/user-not-found': `آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
    'auth/wrong-password': `آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
    'auth/weak-password': 'كلمة المرور هذه ضعيفة جدًا',
    'auth/email-already-in-use': 'البريد الإلكتروني قيد الاستخدام بالفعل',
    'auth/invalid-email': 'يرجى تقديم عنوان بريد إلكتروني صالح',
    'auth/account-exists-with-different-credential': 'البريد الإلكتروني مستخدم بالفعل لطريقة مصادقة مختلفة.',
    'auth/credential-already-in-use': 'بيانات الاعتماد قيد الاستخدام بالفعل',
  },
};

export default ar;