import { WalletService } from '@/modules/wallet/wallet-service';
import { TransactionService } from '@/modules/transaction/transaction-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,

    loadingTransactions: false,
    transactions: [],
    count: 0,
    pagination: {
      page: 1,
      limit: 10,
      sortBy: 'desc',
      orderBy: 'createdAt',
      isFirstPage: true,
      isLastPage: true,
    },
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,

    loadingTransactions: (state) => !!state.loadingTransactions,
    transactions: (state) => state.transactions || [],
    count: (state) => state.count,
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },
  },

  mutations: {
    FIND_STARTED(state) {
      state.loading = true;
      state.record = null;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    FETCH_TRANSACTION_STARTED(state, payload) {
      state.loadingTransactions = true;
      state.transactions = [];

      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_TRANSACTION_SUCCESS(state, payload) {
      state.transactions = payload.rows;
      state.count = payload.count;
      state.loadingTransactions = false;

      if (payload.pagination) {
        let currentPage = state.pagination.page
        const action = payload.pagination.action
        switch (action) {
          case 'next':
            currentPage += 1
            break;
          case 'prev':
            currentPage -= 1
            break;
        }

        state.pagination = {
          page: currentPage,
          limit: payload.pagination.limit || INITIAL_PAGE_SIZE,
          sortBy: payload.pagination.sortBy || 'desc',
          orderBy: payload.pagination.orderBy || 'createdAt',
          isFirstPage: payload.pagination.isFirstPage,
          isLastPage: payload.pagination.isLastPage,
        }
      }
    },

    FETCH_TRANSACTION_ERROR(state) {
      state.transactions = [];
      state.count = 0;
      state.loadingTransactions = false;
    },
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await WalletService.find(id, true);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/wallet');
      }
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetchTransactions', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetchTransactions', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetchTransactions', { filter, keepPagination: true });
    },

    async doFetchWalletTransactions({ commit }, { filter, orderBy, pagination } = {}) {
      try {
        const PAGINATION = pagination || { sortBy: "desc", limit: INITIAL_PAGE_SIZE }
        const ORDER_BY = orderBy || 'createdAt'

        commit('FETCH_TRANSACTION_STARTED', { filter, keepPagination: false });
        const response = await TransactionService.listWalletTransactions(filter, ORDER_BY, PAGINATION);

        commit('FETCH_TRANSACTION_SUCCESS', {
          rows: response.rows,
          count: response.count,
          pagination: { 
            ...PAGINATION, 
            orderBy: ORDER_BY,
            isFirstPage: response.pagination ? response.pagination.isFirstPage : true,
            isLastPage: response.pagination ? response.pagination.isLastPage : true,
          },
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_TRANSACTION_ERROR');
      }
    },
  },
};

