import { i18n } from '@/i18n';
import { Notify } from 'quasar'
export default class Message {
  static success(payload) {
   const not =  Notify.create({
      message: payload,
      color: 'white',
      textColor: 'green',
      timeout: 3000,
      position:'top-right',
      icon:'fas fa-thumbs-up', 
      actions:[
        { icon:'far fa-times-circle', color: 'Light_Grey', handler: () => {} }
      ]
    })
    return not
  }

  static error(payload) {
    let message = payload;

    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }
    Notify.create({
      message: payload,
      color: 'white',
      textColor: 'red',
      timeout: 3000,
      position:'top-right',
      icon:'fas fa-thumbs-down', 
      actions:[
        { icon:'far fa-times-circle', color: 'Light_Grey', handler: () => {} }
      ]
    })
  }

  static warning(payload) {
    let message = payload;

    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }
    Notify.create({
      message: payload,
      type:"warning",
      color: 'white',
      textColor: 'warning',
      timeout: 3000,
      position:'center',
      // progress: true,
      // icon:'fas fa-exclamation-triangle',
      multiLine: true,
      actions:[
        { label: 'Dismiss', color: 'cyan', handler: () => {} }
      ]
    })
  }
}
