import clientListStore from '@/modules/client/client-list-store';
import clientViewStore from '@/modules/client/client-view-store';
import clientImporterStore from '@/modules/client/client-importer-store';
import clientFormStore from '@/modules/client/client-form-store';
import clientDestroyStore from '@/modules/client/client-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: clientDestroyStore,
    form: clientFormStore,
    list: clientListStore,
    view: clientViewStore,
    importer: clientImporterStore,
  },
};
