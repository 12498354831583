// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const RequestListPage = () =>
  import('@/modules/request/components/request-list-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'request',
        path: '/request',
        component: RequestListPage,
        meta: {
          auth: true,
          permission: Permissions.values.requestView,
          title: i18n('metaTitle.request')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      }
    ],
  },
];
