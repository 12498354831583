// import { complaintService } from '@/modules/complaint/complaint-service';
// import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { firestoreAction } from 'vuexfire';

import Errors from '@/shared/error/errors';

export default {
  namespaced: true,
  state: {
    complaintsList: {},
    complaintsArr: [],
    messages: [],
    rows: [],
    selectedId: null,
    selectedMessages: [],
    complaintUser: {},
    loading: false,
    newComplaintCount: 0,
  },
  getters: {
    newComplaintCount: (state) => state.newComplaintCount,
    complaintsList: (state) => state.complaintsList,
    complaintsArr: (state) => state.complaintsArr,
    messages: (state) => state.messages,
    rows: (state) => state.rows,
    test: (state) => state.test,
    selectedMessages: (state) => state.selectedMessages,
    complaintUser: (state) => state.complaintUser,
    loading: (state) => state.loading,
  },
  mutations: {
    CLEAR_COMPLAINT_LIST(state) {
      state.complaintsList = {};
    },
    SET_ID(state, payload) {
      state.selectedId = payload;
    },
    ADD_USER(state, index) {
      state.complaintsArr[index].createdBy =
        state.complaintUser;
    },
    ADD_MESSAGE_USER(state, index) {
      state.messagesNEW[index].senderId = state.messageUser;
    },
    RESET_MESSAGES_AND_COMPLAINTS(state) {
      state.messagesNEW = [];
      state.complaintsArr = [];
    },
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
    FETCH_COUNT_OF_NEW_COMPLAINT_SUCCESS(state, payload) {
      state.newComplaintCount = payload;
    },
    FETCH_COUNT_OF_NEW_COMPLAINT_ERROR(state) {
      state.newComplaintCount = 0;
    },
  },
  actions: {
    async close({ commit }, { id, value }) {
      console.log(commit);
      var status;
      value ? (status = 'closed') : (status = 'opened');
      console.log(status);
      console.log(id, value);
      firebase
        .firestore()
        .collection('complaint')
        .doc(id)
        .update({
          // isClosed: value,
          status: status,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
    async doFetchCountOfNewComplaint({ commit }) {
      try {
        const db = firebase.firestore()
        const query = db.collection('complaint')
          .where("status", "==", "pending")
          // .limit(10)

        query.onSnapshot(querySnapshot => {
          // console.log(`Received query snapshot of size ${querySnapshot.size}`);
          commit('FETCH_COUNT_OF_NEW_COMPLAINT_SUCCESS', querySnapshot.size)  
        }, err => {
          console.log(`Encountered error: ${err}`);
          throw err
        });

        // commit('FETCH_COUNT_TO_HEADER_SUCCESS', count)  
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_COUNT_OF_NEW_COMPLAINT_ERROR');
      }
    },
    setComplaintListener: firestoreAction(
      async ({ bindFirestoreRef, commit,dispatch}, status) => {
        commit('RESET_MESSAGES_AND_COMPLAINTS');
        commit('START_LOADING');
        const queryComplaintsArray = firebase
          .firestore()
          .collection('complaint')
          .orderBy('createdAt', 'desc')
          .where('status', '==', status);
        await bindFirestoreRef(
          'complaintsArr',
          queryComplaintsArray,
        );
        dispatch('doFetchComplaintUser')
        commit('FINISH_LOADING');
      },
    ),

    doFetchComplaintUser: firestoreAction( async ({ getters }) => {
        for (const complaint of getters.complaintsArr) {
          const createdBy = await firebase
            .firestore()
            .collection('user')
            .doc(complaint.createdBy)
            .get();
          complaint['createdBy'] = createdBy.data();
        }
      },
    ),

    setMessagesListener: firestoreAction( async (
        { bindFirestoreRef, getters },
        complaintId,
      ) => {
        const customSerializer = (doc) => {
          const data = doc.data()
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }
        // commit('START_LOADING');
        const queryComplaintMessage = firebase
          .firestore()
          .collection('complaint')
          .doc(complaintId)
          .collection('message')
          .orderBy('sentAt','asc')

        await bindFirestoreRef(
          'messages',
          queryComplaintMessage,
            {serialize: customSerializer }
        );
        // commit('FINISH_LOADING');
        console.log(getters.messages);
      },
    ),
    doReply: firestoreAction( async ({ rootGetters }, { data }) => {
        const currentUser = rootGetters['auth/currentUser'];
        var id = firebase
          .firestore()
          .collection('ids')
          .doc().id;
        console.log(data);
        var obj = {
          id: data.id,
          message: {
            senderId: currentUser.id,
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...data,
          },
        };
        await firebase
          .firestore()
          .collection('complaint')
          .doc(data.id)
          .update({
            status: 'opened',
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        firebase
          .firestore()
          .collection('complaint')
          .doc(data.id)
          .collection('message')
          .doc(id)
          .set({
            ...obj.message,
          });
        console.log(obj);
      },
    ),
    setId({ commit }, id) {
      commit('SET_ID', id);
    },
  },
};
