import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const GuideListPage = () =>
  import('@/modules/guide/components/guide-list-page.vue');
const GuideViewPage = () =>
  import('@/modules/guide/components/guide-view-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'guide',
        path: '/guide',
        component: GuideListPage,
        meta: {
          auth: true,
          permission: Permissions.values.guideView,
          title: i18n('metaTitle.guide')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'guideView',
        path: '/guide/:id',
        component: GuideViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.guideView,
          title: i18n('metaTitle.viewGuide') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
