import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const CycleListPage = () =>
  import('@/modules/cycle/components/cycle-list-page.vue');
const CycleViewPage = () =>
  import('@/modules/cycle/components/cycle-view-page.vue');
  
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'cycle',
        path: '/cycle',
        component: CycleListPage,
        meta: {
          auth: true,
          permission: Permissions.values.cycleView,
          title: i18n('metaTitle.cycle')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'cycleView',
        path: '/cycle/:id',
        component: CycleViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.cycleView,
          title: i18n('metaTitle.viewCycle') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
