import companyListStore from '@/modules/company/company-list-store';
import companyViewStore from '@/modules/company/company-view-store';
import companyImporterStore from '@/modules/company/company-importer-store';
import companyFormStore from '@/modules/company/company-form-store';
import companyDestroyStore from '@/modules/company/company-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: companyDestroyStore,
    form: companyFormStore,
    list: companyListStore,
    view: companyViewStore,
    importer: companyImporterStore,
  },
};
