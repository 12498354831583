import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-wallet';

export class WalletService {
//#region [ CRED Operations ]  
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation WALLET_CREATE($data: WalletInput!) {
          walletCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.walletCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation WALLET_UPDATE(
          $id: String!
          $data: WalletInput!
        ) {
          walletUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.walletUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation WALLET_DESTROY($ids: [String!]!) {
          walletDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.walletDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation WALLET_IMPORT(
          $data: WalletInput!
          $importHash: String!
        ) {
          walletImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.walletImport;
  }
//#endregion

  static async find(id, includeOwner) {
    const response = await graphqlClient.query({
      query: gql`
        query WALLET_FIND($id: String!, $includeOwner: Boolean) {
          walletFind(id: $id, includeOwner: $includeOwner) {
            id
            userId 
            balance
            holdedBalance
            actualBalance
            type
            owner
            createdAt
          }
        }
      `,

      variables: {
        id,
        includeOwner,
      },
    });

    return response.data.walletFind;
  }

  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query WALLET_LIST(
          $filter: WalletFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          walletList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              userId 
              balance
              holdedBalance
              actualBalance
              type
              owner
              createdAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination
      },
    });

    return response.data.walletList;
  }
 
  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query WALLET_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          walletAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.walletAutocomplete;
  }
}
