import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-reservation';

export class ReservationService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_CREATE($data: ReservationInput!) {
          reservationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.reservationCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_UPDATE(
          $id: String!
          $data: ReservationInput!
        ) {
          reservationUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.reservationUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_DESTROY($ids: [String!]!) {
          reservationDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.reservationDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_IMPORT(
          $data: ReservationInput!
          $importHash: String!
        ) {
          reservationImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.reservationImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_FIND($id: String!) {
          reservationFind(id: $id) {
            id
            clientId
            tourGuideId
            tripId
            optionIndex
            qrCode
            isArrived
            numberOfPeople
            status
            paymentMethod
            members {
              name
              phoneNumber
            }
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.reservationFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: ReservationFilterInput
          $orderBy: ReservationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          reservationList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              clientId
              tourGuideId
              tripId
              optionIndex
              qrCode
              isArrived
              numberOfPeople
              status
              paymentMethod
              members {
                name
                phoneNumber
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.reservationList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          reservationAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.reservationAutocomplete;
  }
}
