import invoiceListStore from '@/modules/invoice/invoice-list-store';
import invoiceViewStore from '@/modules/invoice/invoice-view-store';

export default {
  namespaced: true,

  modules: {
    list: invoiceListStore,
    view: invoiceViewStore,
  },
};
