import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-guide';

export class GuideService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation GUIDE_CREATE($data: GuideInput!) {
          guideCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.guideCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation GUIDE_UPDATE(
          $id: String!
          $data: GuideInput!
        ) {
          guideUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.guideUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation GUIDE_DESTROY($ids: [String!]!) {
          guideDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.guideDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation GUIDE_IMPORT(
          $data: GuideInput!
          $importHash: String!
        ) {
          guideImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.guideImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query GUIDE_FIND($id: String!) {
          guideFind(id: $id) {
            id
            authenticationUid
            disabled
            status
            rejectionReason
            email
            avatar {
              name
              publicUrl
            }
            fullName
            phoneNumber
            accountType
            rate
            pricePerHour
            isMale
            availableNow
            isOnline
            languages
            availableDays
            reviews {
              id
              rate
              description
              reviewer {
                avatar {
                  name
                  publicUrl
                }
                fullName
                email
                phoneNumber
              }
              createdBy
              createdAt
            }
            location {
              latitude
              longitude
            }
            distanceToClient
            currency
            capacity
            deviceTokens

            companyId
            branchId
            cityId
            company {
              id
            }
            branch {
              id
            }
            wallet 

            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.guideFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query GUIDE_LIST(
          $filter: GuideFilterInput
          $orderBy: GuideOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          guideList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              authenticationUid
              disabled
              status
              rejectionReason
              email
              avatar {
                name
                publicUrl
              }
              fullName
              phoneNumber
              accountType
              rate
              pricePerHour
              isMale
              availableNow
              isOnline
              languages
              availableDays
              reviews {
                id
                rate
                description
                reviewer {
                  avatar {
                    name
                    publicUrl
                  }
                  fullName
                  email
                  phoneNumber
                }
                createdBy
                createdAt
              }
              location {
                latitude
                longitude
              }
              distanceToClient
              currency
              capacity
              deviceTokens

              companyId
              branchId
              cityId
              company {
                id
              }
              branch {
                id
              }
              wallet

              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.guideList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query GUIDE_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          guideAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.guideAutocomplete;
  }
  static async findNumberOfGuides() {
    const response = await graphqlClient.query({
      query: gql`
        query findNumberOfGuides {
          findNumberOfGuides {
            freelanceGuides
            companyGuides
        }
        }
      `,

    });

    return response.data.findNumberOfGuides;
  }
}
