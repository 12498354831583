//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-view-item-image',
  props: ['label', 'src'],

  data () {
    return {
      dialog: false,
      maximizedToggle: true,
    }
  },
  computed: {
    isBlank() {
      return !this.src 
    },
    value() {
      return this.src ? this.src : 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fdefault-image.svg?alt=media&token=3424e07f-52c7-404a-881d-545c22d4c3a3'
    }
  },
};
