// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import DecimalRangeField from '@/shared/fields/decimal-range-field';
import DecimalField from '@/shared/fields/decimal-field';
// import ImageField from '@/shared/fields/image-field';
// import ImagesField from '@/shared/fields/images-field';
// import IntegerField from '@/shared/fields/integer-field';
// import StringArrayField from '@/shared/fields/string-array-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
import ImagesField from '@/shared/fields/images-field';


function label(name) {
  return i18n(`entities.offer.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.offer.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  title: new JsonField('title', label('title'), {}),
  description: new JsonField('description', label('description'), {}),
  image: new ImagesField('image', label('image'),
    `offer/images`,
    { max: 1 },
  ),
  price: new DecimalField('price', label('price'), {
    "required": true,
    "scale": 2,
    "min": 0.01,
    "max": 99999999
  }),
  // price: new StringField('price', label('price'), {}),
  percentage: new DecimalField('percentage', label('percentage'), {
    "required": true,
    "scale": 2,
    "min": 0.01,
    "max": 99999999
  }),
  oldPrice: new DecimalField('oldPrice', label('oldPrice'), {
    "required": true,
    "scale": 2,
    "min": 0.01,
    "max": 99999999
  }),
  type: new EnumeratorField('type', label('type'), [
    { value: 'saryah', label: enumeratorLabel('type', 'saryah') },
    { value: 'store', label: enumeratorLabel('type', 'store') },
  ],{}),
    
  //#region [ This field exist in store offer only ]
  rejectionReason: new StringField('rejectionReason', label('rejectionReason'), {}),
  location: new JsonField('location', label('location'), {}),
  range: new DecimalField('range', label('range'), {
    "required": true,
    "scale": 2,
    "min": 5,
    "max": 10
  }),
  isActive: new BooleanField('isActive', label('isActive'), {
    yesLabel: 'Active',
    noLabel: 'Inactive',
  }),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending') },
    { value: 'accepted', label: enumeratorLabel('status', 'accepted') },
    { value: 'rejected', label: enumeratorLabel('status', 'rejected') },
    { value: 'deleted', label: enumeratorLabel('status', 'deleted') },
  ],{}),
  //#endregion

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')), 
  createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),
};

export class OfferModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
