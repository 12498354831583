import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-city';

export class CityService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_CREATE($data: CityInput!) {
          cityCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.cityCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_UPDATE(
          $id: Int!
          $data: CityInput!
        ) {
          cityUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.cityUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_DESTROY($ids: [String!]!) {
          cityDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.cityDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_IMPORT(
          $data: CityInput!
          $importHash: String!
        ) {
          cityImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.cityImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_FIND($id: String!) {
          cityFind(id: $id) {
            id
            country_id
            city_ascii
            name {
              en
              ar
            }
            normalized {
              en
              ar
            }
            center {
              latitude
              longitude
            }
            country {
              id
              name {
                en
                ar
              }
              normalized {
                en
                ar
              }
              center {
                latitude
                longitude
              }
              iso2
              iso3
              nameEn
            }
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.cityFind;
  }

  static async list(filter, withRelation, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_LIST_WITH_PAGINATION(
          $filter: CityFilterInput, 
          $withRelation: [CityRelationEnum], 
          $orderBy: String, 
          $pagination: PaginationInput
        ) {
          listCitiesWithPagination(filter: $filter, withRelation: $withRelation, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              country_id
              city_ascii
              name {
                en
                ar
              }
              normalized {
                en
                ar
              }
              center {
                latitude
                longitude
              }
              country {
                id
                name {
                  en
                  ar
                }
                normalized {
                  en
                  ar
                }
                center {
                  latitude
                  longitude
                }
                iso2
                iso3
                nameEn
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        withRelation,
        orderBy,
        pagination,
      },
    });

    return response.data.listCitiesWithPagination;
  }

  static async listAutocomplete(query, limit, lang, countryId) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_AUTOCOMPLETE(
          $query: String
          $limit: Int
          $lang: LanguageEnum
          $countryId: String
        ) {
          cityAutocomplete(query: $query, limit: $limit, lang: $lang, countryId: $countryId) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
        lang,
        countryId,
      },
    });

    return response.data.cityAutocomplete;
  }
}
