// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo",
  authDomain: "rahal-7e911.firebaseapp.com",
  databaseURL: "https://rahal-7e911-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rahal-7e911",
  storageBucket: "rahal-7e911.appspot.com",
  messagingSenderId: "288521883799",
  appId: "1:288521883799:web:73ce74e2d781965ed319d2",
  measurementId: "G-7ZN6XV11P7"
};

const REGION = 'europe-west1'
const PRODUCTION_BASE_URL = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net`

// Cloud Functions
const backendUrl = `${PRODUCTION_BASE_URL}/api`;
// const backendUrlAuditLog = `${PRODUCTION_BASE_URL}/auditLog`;
const backendUrlAuth = `${PRODUCTION_BASE_URL}/auth`;
const backendUrlIam = `${PRODUCTION_BASE_URL}/iam`;
const backendUrlBranch = `${PRODUCTION_BASE_URL}/branch`;
const backendUrlCity = `${PRODUCTION_BASE_URL}/city`;
const backendUrlClient = `${PRODUCTION_BASE_URL}/client`;
const backendUrlCompany = `${PRODUCTION_BASE_URL}/company`;
const backendUrlGuide = `${PRODUCTION_BASE_URL}/guide`;
const backendUrlNotification = `${PRODUCTION_BASE_URL}/notification`;
const backendUrlPlace = `${PRODUCTION_BASE_URL}/place`;
const backendUrlPlan = `${PRODUCTION_BASE_URL}/plan`;
const backendUrlCountry = `${PRODUCTION_BASE_URL}/country`;
const backendUrlSettings = `${PRODUCTION_BASE_URL}/settings`;
const backendUrlTransaction = `${PRODUCTION_BASE_URL}/transaction`;
const backendUrlTrip = `${PRODUCTION_BASE_URL}/trip`;
const backendUrlOffer = `${PRODUCTION_BASE_URL}/offer`;
const backendUrlStore = `${PRODUCTION_BASE_URL}/store`;
const backendUrlReservation = `${PRODUCTION_BASE_URL}/reservation`;
const backendUrlInvoice = `${PRODUCTION_BASE_URL}/invoice`;
const backendUrlWallet = `${PRODUCTION_BASE_URL}/wallet`;
const backendUrlRequest = `${PRODUCTION_BASE_URL}/request`;

// App Engine
// const backendUrl = `<insert app engine url here>`;
// const backendUrl = `https://${REGION}-saryah-31e36.cloudfunctions.net/api`;

export default {
  firebaseConfig,
  backendUrl,
  // backendUrlAuditLog,
  backendUrlAuth,
  backendUrlIam,
  backendUrlBranch,
  backendUrlCity,
  backendUrlClient,
  backendUrlCompany,
  backendUrlGuide,
  backendUrlNotification,
  backendUrlPlace,
  backendUrlPlan,
  backendUrlCountry,
  backendUrlSettings,
  backendUrlTransaction,
  backendUrlTrip,
  backendUrlOffer,
  backendUrlStore,
  backendUrlReservation,
  backendUrlWallet,
  backendUrlInvoice,
  backendUrlRequest,
};
