import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const tripListPage = () =>
  import('@/modules/trip/components/trip-list-page.vue');
const tripViewPage = () =>
  import('@/modules/trip/components/trip-view-page.vue');


export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'trip',
        path: '/trip',
        component: tripListPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.trip')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'tripView',
        path: '/trip/:id',
        component: tripViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.viewtrip') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
