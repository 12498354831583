import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';

export class ReviewService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_CREATE($data: ReviewInput!) {
          reviewCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.reviewCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_UPDATE(
          $id: String!
          $data: ReviewInput!
        ) {
          reviewUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.reviewUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_DESTROY($ids: [String!]!) {
          reviewDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.reviewDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_IMPORT(
          $data: ReviewInput!
          $importHash: String!
        ) {
          reviewImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.reviewImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_FIND($id: String!) {
          reviewFind(id: $id) {
            id
            payerId
            payeeId
            payerType
            payeeType
            amount
            payMethod
            date
            description {
              en
              ar
            }

            paymentId
            paymentType
            paymentBrand
            currency
            
            createdBy
            updatedBy
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.reviewFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: ReviewFilterInput
          $orderBy: ReviewOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          reviewList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              payerId
              payeeId
              payerType
              payeeType
              amount
              payMethod
              date
              description {
                en
                ar
              }

              paymentId
              paymentType
              paymentBrand
              currency
              
              createdBy
              updatedBy
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.reviewList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          reviewAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.reviewAutocomplete;
  }
}
