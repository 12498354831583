import cycleListStore from '@/modules/cycle/cycle-list-store';
import cycleViewStore from '@/modules/cycle/cycle-view-store';

export default {
  namespaced: true,

  modules: {
    list: cycleListStore,
    view: cycleViewStore,
  },
};
