import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-transaction';

export class TransactionService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_CREATE($data: TransactionInput!) {
          transactionCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.transactionCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_UPDATE(
          $id: String!
          $data: TransactionInput!
        ) {
          transactionUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.transactionUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_DESTROY($ids: [String!]!) {
          transactionDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.transactionDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation TRANSACTION_IMPORT(
          $data: TransactionInput!
          $importHash: String!
        ) {
          transactionImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.transactionImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_FIND($id: String!) {
          transactionFind(id: $id) {
            id
            tranNum
            userId 
            status
            createdAt
            trip {
              name {
                en
                ar
              }
            }
            guide {
              id
              fullName
              avatar {
                name
                publicUrl
              }
            }
            payer {
              avatar
              name {
                en
                ar
              }
              email
              phoneNumber
              type
            }
            amount
            paymentId
            paymentPageUrl
            currencyCode
            trackId
            transId
            
            companyId
            payerId
            payeeId
            payerType
            payeeType

            paymentMethod
            status
            type
            operation
            operationId

            description {
              en
              ar
            }
            saryahPercentage
            vatPercentage
            dueDate
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.transactionFind;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          transactionAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.transactionAutocomplete;
  }

  static async listOwnTransactions(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query TRANSACTION_LIST(
          $filter: OwnTransactionFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          listOwnTransactions(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              tranNum
              userId 
              status
              createdAt
              trip {
                name {
                  en
                  ar
                }
              }
              guide {
                id
                fullName
                avatar {
                  name
                  publicUrl
                }
              }
              payer {
                avatar
                name {
                  en
                  ar
                }
                email
                phoneNumber
                type
              }
              amount
              paymentId
              paymentPageUrl
              currencyCode
              trackId
              transId
              
              companyId
              payerId
              payeeId
              payerType
              payeeType

              paymentMethod
              status
              type
              operation
              operationId

              description {
                en
                ar
              }
              saryahPercentage
              vatPercentage
              dueDate
              createdBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });

    return response.data.listOwnTransactions;
  }

  static async listWalletTransactions(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_WALLET_TRANSACTION(
          $filter: WalletTransactionFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          listWalletTransactions(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              userId 
              status
              createdAt
              trip {
                name {
                  en
                  ar
                }
              }
              amount
              paymentId
              paymentPageUrl
              currencyCode
              trackId
              transId

              companyId
              payerId
              payeeId
              payerType
              payeeType

              paymentMethod
              status
              type
              operation
              operationId
              description {
                en
                ar
              }
              saryahPercentage
              vatPercentage
              dueDate
              
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination,
      },
    });
    return response.data.listWalletTransactions;
  }

  static async listMyRecentTransactions() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_MY_RECENT_TRANSACTIONS {
          listMyRecentTransactions {
            id
            tranNum
            amount
            userId 
            createdAt
            operation
            type
            payee {
              avatar
              name {
                  en
                  ar
              }
              email
              phoneNumber
              type
            }
          }
        }
      `,
    });

    return response.data.listMyRecentTransactions;
  }
}
