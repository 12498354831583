//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';
import i18n from './vueI18n';
import AOS from 'aos';

export default {
  name: 'app',

  created() {
    AOS.init({
      once: false,
    });
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then(
      console.log('doInitFirebase Done & doInit Done'),
    );
    // await this.doInit();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    // this.findCurrentLocation()
    i18n.locale = localStorage.getItem('language') || 'en';

    this.$watch('$sidebar.showSidebar', this.toggleNavOpen);

    // switch language
    if (localStorage.getItem('language') == 'ar') {
      //  this.$rtl.enableRTL() ;
      import('quasar/lang/ar').then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }

    if (localStorage.getItem('language') == 'en') {
      // this.$rtl.disableRTL() ;
      import('quasar/lang/en-us').then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      currentLanguageCode: 'layout/currentLanguageCode',
      currentLocation: 'layout/currentLocation'
    }),

    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
      findCurrentLocation: 'layout/findCurrentLocation'
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    },
  },
};
