import reviewListStore from '@/modules/review/review-list-store';
import reviewViewStore from '@/modules/review/review-view-store';
import reviewImporterStore from '@/modules/review/review-importer-store';
import reviewFormStore from '@/modules/review/review-form-store';
import reviewDestroyStore from '@/modules/review/review-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: reviewDestroyStore,
    form: reviewFormStore,
    list: reviewListStore,
    view: reviewViewStore,
    importer: reviewImporterStore,
  },
};
