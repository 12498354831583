import { ReservationModel } from '@/modules/reservation/reservation-model';

const { fields } = ReservationModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.reservationName,
  fields.city,
  fields.country,
  fields.description,
  fields.reservationPictures,
  fields.reservationType,
];
